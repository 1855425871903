import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { kAppBarColor, kAppBarDark, kDarkText, kLightText, kNavbarHeight, kNavbarHeightMobile, kPrimaryColor, kPrimaryLight, kSecondary } from '../../const/const';
import px2vw from '../../utils/px2v';
import { desktop, mobile, tablet } from '../../utils/responsive';
import {TiThMenu} from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { uiSliceAction } from '../../redux_store/slices/ui_slice';
import OutsideClickHandler from 'react-outside-click-handler';
import { DashboardMenuComponent } from '../pages/DashboardLayout';
import Logo from './Logo';
import { Link } from 'react-router-dom';


import MenuIcon from '@mui/icons-material/Menu'
import {IconButton,Toolbar,Container,AppBar,Box,Menu,Tooltip,Avatar } from '@mui/material';

const LeftItems = styled.div`
    height:100%;
    padding-left:5px;
    padding-right:5px;
    flex:1;
    display:flex;
    flex-direction:row;

    ${mobile({
    })};
    ${desktop({
    })};
    ${tablet({
    })};
    
`;

const CenterItems = styled.div`
    height:100%;
    padding-left:5px;
    padding-right:5px;
    flex:1;

    ${mobile({
    })};
    ${desktop({
        display:'none'
    })};
    ${tablet({
        display:'none'
    })};
    
`;

const RightItems = styled.div`
    height:100%;
    padding-left:5px;
    padding-right:5px;
    flex:1;
    display:flex;
    flex-direction:row;
    justify-content:right;
    align-content:right;
    text-align:right;
    ${mobile({
        'justify-content':'right',
        'align-content':'right',
        'text-align':'right',
    })};
    ${desktop({
    })};
    ${tablet({
    })};
    
`;

const AppBarButton = styled.div`
    color:${kPrimaryLight};
    display:flex;
    flex-direction:column;
    font-size:35px;
    padding-left:5px;
    padding-right:5px;
    height:100%;
    justify-content:center;
    justify-items:center;
    width:min-content;
    ${desktop({
        display:'none'
    })};
    ${tablet({
        display:'none'
    })};

    
`;

const LogoHolder = styled.div`
    color:${kLightText};
    display:flex;
    flex-direction:column;
    font-size:25px;
    padding-left:5px;
    padding-right:5px;
    height:100%;
    justify-content:center;
    justify-items:center;
    width:min-content;
    ${mobile({
        display:preps=>preps.type==="desktop"?'none':''
    })};
    ${desktop({
        display:preps=>preps.type==="mobile"?'none':''
    })};
    ${tablet({
        display:preps=>preps.type==="mobile"?'none':''
    })};

    
`;

const NavBar = styled.nav`
    box-shadow: 0 0 5px 0.4px #000000e2;
    z-index:2;
         
    height:100%;
    background-color:${kAppBarColor};
    display:flex;
    flex-direction:row;
    width:100%;
    ${mobile({
        height:kNavbarHeightMobile,
    })};
    ${desktop({
        height:kNavbarHeight,
    })};
    ${tablet({
        height:kNavbarHeight,
    })};
    
`;

const AppBarContainer = styled.div`
    z-index:2;
    position:absolute;
    display:flex;
    flex-direction: column;
    height:100%;
    width:60%;
    max-width:400px;
    text-align:end;
    ${desktop({
        display:'none'
    })};
    ${tablet({
        display:'none'
    })};
`;

const UserNameHolder = styled.div`
    height:80%;
    display:flex;
    flex-direction:column;
    align-content:flex-end;
    justify-content:flex-end;  
    margin-right:20px;
`;
const UserNameText = styled.div`
    font-weight:500;
    ${mobile({
        display:'none'
    })};
    
`;

const UserProfileHolder = styled.div`
    height:100%;
    width:min-content;
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:center;
     
`;

const UserProfile = styled.img`
    border-radius: 100%;
    overflow:hidden;
    cursor: pointer;
    object-fit: cover;
    border:1px solid;
    ${desktop({
        width: '40px',
        height: '40px',
    })};
    ${tablet({
        width: '40px',
        height: '40px',
    })};
     ${mobile({
        width: '35px',
        height: '35px',
    })};
    
    
`;




const Nav = () => {
    const user = useSelector(store=>store.user)
    const [isOpen,setAppBar] = useState(false);
    const handleClick = ()=>setAppBar(!isOpen);
    const closeAppBarMenu = ()=>setAppBar(false);
    useEffect(()=>{
        window.addEventListener("resize", (size) => {
            if(window.innerWidth<=480){
                setAppBar(false)
            }
        });
        return window.removeEventListener('resize',()=>{})
    },[]);

  return (
      <>
        <AppBar position="static" style={{backgroundColor:kAppBarColor}}>
            <OutsideClickHandler onOutsideClick={closeAppBarMenu}>
                {isOpen && (
                <AppBarContainer>
                    <DashboardMenuComponent onClickButton={closeAppBarMenu}/>
                </AppBarContainer>
                )}
            </OutsideClickHandler>

            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <LogoHolder type={'desktop'}>
                      <Logo/>
                  </LogoHolder>
                </Link>

                  
                  <LogoHolder type={'mobile'}>
                      <Box sx={{ flexGrow: 1 }}>
                      <IconButton
                      size="large"
                      style={{color:'black',fontSize:'100px'}}
                      onClick={handleClick}
                      >
                          <MenuIcon style={{fontSize:'40px'}}/>
                          </IconButton>
                          </Box>
                  </LogoHolder>

                  <Box sx={{flexGrow: 1,}}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <LogoHolder type={'mobile'}>
                            <Logo/>
                        </LogoHolder>
                    </Link>
                  </Box>
                  <Box sx={{ flexGrow: 0,display:'flex' ,alignItems:'center'}}>
                  <h3 style={{color:'black',justifySelf:'center'}}>Welcome,  {user.user.responseData.fullName.split(' ').slice(0, -1).join(' ')
                                                                            ?user.user.responseData.fullName.split(' ').slice(0, -1).join(' ')
                                                                            :user.user.responseData.fullName??'Loading...'}</h3>
                    <Tooltip title="Profil">
                        <IconButton  sx={{ p: 0 ,ml:2}}>
                            <Avatar alt={user.user.responseData.fullName.split(' ').slice(0, -1).join(' ')??'Loading...'} src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80" />
                        </IconButton>
                    </Tooltip>
                  </Box>
                </Toolbar>
            </Container>
        </AppBar>
   
   </>
  )
}

export default Nav



// <NavBar >
//        <OutsideClickHandler onOutsideClick={closeAppBarMenu}>
//        {isOpen && 
//         (
//             <AppBarContainer>
//                 <DashboardMenuComponent onClickButton={closeAppBarMenu}/>
//             </AppBarContainer>
//         )}
//         </OutsideClickHandler>
//        <LeftItems>
//             <Link to='/' style={{ textDecoration: 'none' }}>
//                 <LogoHolder type={'desktop'}>
//                     <Logo />
//                 </LogoHolder>
//             </Link>

//            <AppBarButton>
//                 <TiThMenu onClick={handleClick} style={{
//                     border:'1px solid',
//                     borderRadius:'8px',
//                     padding:'2px',
//                 }}/>
//            </AppBarButton>

//        </LeftItems>
//        <CenterItems>
//             <Link to='/' style={{ textDecoration: 'none' }}>
//                 <LogoHolder>
//                         <Logo />
//                 </LogoHolder>
//             </Link>
//        </CenterItems>
//        <RightItems>
//            <UserNameHolder>
//                <UserNameText>
//                    {/* Welcome, {userName.split(' ').slice(0, -1).join(' ')??'Loading...'} */}
//                </UserNameText>
//            </UserNameHolder>
//            <Link to='/profile' style={{ textDecoration: 'none' }}>
//                 <UserProfileHolder>
//                     <UserProfile src='https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80'/>
//                 </UserProfileHolder>
//            </Link>
//        </RightItems>
//    </NavBar>