
export const messages = {
    auth:{
        invalidEmail:{message:'Please enter valid Email',color:'danger'},
        invalidPassword:{message:'Password should contain minimun 6 characters',color:'danger'},
        invalidPhone:{message:'Please enter valid phone number',color:'danger'},
        invalidSecret:{message:'Please enter valid secret',color:'danger'},
        invalidname:{message:'Please enter valid name',color:'danger'},
        successLogin:{message:'Successfully Logged in',color:'danger'},
        successRegister:{message:'User Registered Successfully',color:'danger'},
        successLogout:{message:'Successfully Logged out',color:'danger'}
    }
}
