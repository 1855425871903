import './App.css';
import Global from "./styles/global";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import DashboardLayout from './components/pages/DashboardLayout';
import { DashboardMenuItemsData } from './components/pages_data/dashboard.data';

///PAGES///
import Dashboard from "../src/components/pages/dashboard/Dashboard";
import Colleges from "../src/components/pages/colleges/Colleges";
import Schools from "../src/components/pages/schools/Schools";
import Exam from "../src/components/pages/exam/Exam";
import MockTest from "../src/components/pages/mock_test/MockTest";
import StaticData from "../src/components/pages/static_data/StaticData";
import Mail from "../src/components/pages/mail/Mail";
import ManageUser from "../src/components/pages/manage_users/ManageUser";
import Documentation from "../src/components/pages/documentation/Documentation";
import ManageAdminUser from "../src/components/pages/manage_admin_users/ManageAdminUser";
import Settings from "../src/components/pages/settings/Settings";
import SignIn from "../src/components/pages/sign_in/SignIn";
import SignUp from "../src/components/pages/sign_up/SignUp";
import Verify from "../src/components/pages/verify/Verify";
import Profile from "../src/components/pages/profile/Profile";
import { useDispatch, useSelector } from 'react-redux';
import { uiSliceAction } from './redux_store/slices/ui_slice';
import { useEffect } from 'react';
import { checkAuth } from './redux_store/slices/auth_slice';
import Loading from './components/pages/loading/Loading';
import IsAuth from './middlewares/IsAuth';
import store from './redux_store/store';
import { matchPath, useLocation } from 'react-router';
import PrivateRoute from './middlewares/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import DataEdit from './components/comp/DataEdit';
import CollegeView from './components/comp/CollegeView';
import SchoolView from './components/comp/SchoolView';
import CollegeAdd from './components/comp/CollegeAdd';
import SchoolAdd from './components/comp/SchoolAdd';
import ExamView from './components/comp/ExamView';
import ExamAdd from './components/comp/ExamAdd';
import Tokens from './components/pages/tokens/Tokens';


function App() {
  //const dispatch = useDispatch();
  
  
  // dispatch(checkAuth())
  // var data=IsAuth();
  // const isAuth = useSelector(state=>state.user.isAuthenticated);
  // const location = useLocation()


  // console.log(location)

  // useEffect(()=>{
  //   if(isAuth===true){
  //     if
  //   }
  // })


  
  return (
    // <div className="App">
      <>
    {/* <div className="App"> */}
      <Global/>
      <ToastContainer style={{width:'auto',maxWidth:'70%'}}/>
      {/* <QuickAccessBar></QuickAccessBar>
      <NavBar></NavBar> */}
      
      <Routes>
      
        <Route path='/sign-in' element={<SignIn/>}/>
        <Route path='/sign-up' element={<SignUp/>}/>

        <Route path='*' element={<PrivateRoute
        child={
        <Routes>
          <Route path='/verify' element={<DashboardLayout page={<Verify/>}/>}/>
        <Route path='/profile' element={<DashboardLayout page={<Profile/>}/>}/>
        <Route path='/' element={<DashboardLayout page={<Dashboard/>}/>}/>
        
        <Route path='/colleges'   element={<DashboardLayout page={<Colleges />}/>} />
          <Route path="/colleges/add"  element={<DashboardLayout page={<CollegeAdd/>}/>}/>
          <Route path="/colleges/edit/:id"  element={<DashboardLayout page={<CollegeAdd props={'edit'}/>}/>}/>
          <Route path="/colleges/view/:id"  element={<DashboardLayout page={<CollegeView/>}/>}/>
        

        
  
        <Route path='/schools' element={<DashboardLayout page={<Schools/>}/>}/>
          <Route path='/schools/add' element={<DashboardLayout page={<SchoolAdd/>}/>}/>
          <Route path='/schools/edit/:id' element={<DashboardLayout page={<SchoolAdd props={'edit'}/>}/>}/>
          <Route path='/schools/view/:id' element={<DashboardLayout page={<SchoolView/>}/>}/>
        <Route path='/exam' element={<DashboardLayout page={<Exam/>}/>}/>
          <Route path='/exam/add' element={<DashboardLayout page={<ExamAdd/>}/>}/>
          <Route path='/exam/edit/:id' element={<DashboardLayout page={<ExamAdd props={'edit'}/>}/>}/>
          <Route path='/exam/view/:id' element={<DashboardLayout page={<ExamView/>}/>}/>
        {/* <Route path='/mock-test' element={<DashboardLayout page={<MockTest/>}/>}/> */}
        <Route path='/static-data' element={<DashboardLayout page={<StaticData/>}/>}/>
        <Route path='/mail' element={<DashboardLayout page={<Mail/>}/>}/>
        <Route path='/manage-user' element={<DashboardLayout page={<ManageUser/>}/>}/>
          <Route path='/manage-user/view/:id' element={<DashboardLayout page={<Profile/>}/>}/>
          
        <Route path='/documentation' element={<DashboardLayout page={<Documentation/>}/>}/>
        <Route path='/manage-admin-user' element={<DashboardLayout page={<ManageAdminUser/>}/>}/>
          <Route path='/manage-admin-user/view/:id' element={<DashboardLayout page={<Profile props={'admin'}/>}/>}/>  
          <Route path='/manage-admin-user/tokens' element={<DashboardLayout page={<Tokens/>}/>}/>
        <Route path='/settings' element={<DashboardLayout page={<Settings/>}/>}/>
        <Route path='*' element={<h1>404 NOT FOUND</h1>}/>
        </Routes>
        }/>}/>
        
        
      </Routes>
      {/* <Footer></Footer> */}
      
    {/* </div> */}
    </>
    // </div>
  );
}

export default App;
