import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {kAppBarColor, kAppBarDark, kBackgroundColor, kBackgroundDark, kDarkText, kPrimaryColor, kPrimaryLight, kSecondary, } from '../../const/const';
import { uiSliceAction } from '../../redux_store/slices/ui_slice';
import {mobile,desktop,tablet} from '../../utils/responsive';
import { PrimaryButtonComponent } from '../components';
import Header from '../header/Header';
import { TiArrowLeftThick, TiArrowRightThick } from 'react-icons/ti';
import { DashboardMenuItemsData } from '../pages_data/dashboard.data';
import { Link,useLocation } from 'react-router-dom';
import { logoutUser } from '../../redux_store/slices/auth_slice';
import Loading from './loading/Loading';





const Container = styled.div`
    
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;

    
`;

const Body = styled.div`
    background-color:'#fff';
    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;

    
`;

const DashboardLayout = (page) => {
  return (
      <Container>
          <Header></Header>
        <Body>
            <DashboardMenu type={'desktop'}></DashboardMenu>
            {page.page}
            
        </Body>
    </Container>
  )
}

export default DashboardLayout




const ButtonContainer = styled.div`
    height:50px;
    display:flex;
    flex-direction:row;
    justify-content:right;
    text-align:right;
    padding-right:10px;
    padding-left:10px;
    padding-top:10px;
    width:100%;
    border-radius:30px;

    
`;

const DashboardMenuContainer= styled.div`
    box-shadow: 0 0 5px 0.4px #000000e2;
    z-index:3;
    display:inline-block;
    flex-direction:column;
    height:100vh-80px;
    min-height:99vh;
    max-height:100vh;

    margin-top:${preps=>preps.type==='desktop'?'1.5px':'0'};
    border-top-right-radius:8px;
    border-bottom-right-radius:8px;
    background-color:${kAppBarDark};
    transition:0.4s;

    display:inline-block;
    ${mobile({
        width:'60%',
        display:preps=>preps.type==='desktop'?'none':'',
        position:'fixed'
    })};
    ${desktop({
        width:preps=>preps.isOpen?'40%':'80px',
        'max-width':preps=>preps.isOpen?'350px':'100px'
    })};
    ${tablet({
        width:preps=>preps.isOpen?'40%':'80px',
        'max-width':preps=>preps.isOpen?'300px':'100px'
    })}; 
`;

const MenuContainer = styled.div`
    display:flex;
    flex-direction:column;
    overflow:scroll;
    height:90%;
    width:100%;
    ${mobile({
    })};
    ${desktop({
    })};
    ${tablet({
    })};
    
`;

const MenuItems = styled.li`
    background-color:${preps=>preps.visibility === 'true'?'white':kPrimaryColor}; 
    display:flex;
    flex-direction:row;
    height:60px;
    width:100%-2px;  
    justify-content:center;
    align-items:center;
    color:white;
    margin:2px 5px;
    border-radius:5px;
    overflow:hidden;
    cursor: pointer;
    transition: transform 0.2s;
    :hover{
        transform: scale(1.025);
        opacity:0.9;
    }

    ${mobile({
        height:'40px;'
    })};
    ${desktop({
    })};
    ${tablet({
        height:'45px;'
        
    })};
`;
const MenuIcon = styled.div`
    background-color:${preps=>preps.visibility === 'true'?kPrimaryColor:'none'};
    width:20%;
    max-width:70px;
    min-width:70px;
    height:60px;
    font-size:50px;
    align-items:center;
    justify-content:center;
    text-align:center;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    ${mobile({
        height:'40px;',
        'font-size':'38px'
    })};
    ${desktop({
    })};
    ${tablet({
        height:'45px;',
        'font-size':'40px'
        
    })};


`;

const MenuText = styled.h4`
    display:${preps=>preps.visibility === 'true'?'flex':'none'};
    color:${kDarkText};
    flex-direction:row;
    
    width:100%;
    padding-left:10px;
    ${mobile({
        'font-size':'1.5rem',
    })};
    ${desktop({
        'font-size':'1rem',
    })};
    ${tablet({
        'font-size':'1.2rem',
    })};

`;




const getInnerWidth = ()=>{
    if(window.innerWidth<=1000){
        return false;
    }else{
        return true;
    }
}


const DashboardMenu = (args) => {
    const dispatch = useDispatch();
    const [isOpen,changeMenu] = useState(getInnerWidth());
    const location = useLocation();
    const id = location.pathname.split("/")[1];
    console.log(id===""?"dashboard":id);
    const handleDashboardMenu = () =>{
        changeMenu(!isOpen)
    }
    useEffect(()=>{
        window.addEventListener("resize", () => {
            changeMenu(getInnerWidth());
        });
        return window.removeEventListener('resize',()=>{})
    },[]);

    const logOut = ()=>{
        dispatch(logoutUser())
    }
  
    
    return (
        
        
        <DashboardMenuContainer isOpen={isOpen} type={args.type}>
            <ButtonContainer>
                <PrimaryButtonComponent 
                onClick={args.type==="desktop"?handleDashboardMenu:args.onClickButton}
                height={'35px'}
                width={'80px'}
                borderRadius={'5'}
                fontSize={'28px'}
                >{args.type==="desktop"?(isOpen?<TiArrowLeftThick/>:<TiArrowRightThick/>):<TiArrowLeftThick/>} </PrimaryButtonComponent>
            </ButtonContainer>
            <MenuContainer>
            {DashboardMenuItemsData.map(item=>
            (
                <Link key={item.title} to={item.onclick} style={{ textDecoration: 'none' }} onClick={item.onclick ==='logout'?logOut:null}>
                <MenuItems style={{backgroundColor:id===item.onclick.split("/")[1]?'#92ccfc':null}} visibility={args.type==='desktop'?isOpen.toString():'true'}>
                <MenuIcon style={{backgroundColor:id===item.onclick.split("/")[1]?kPrimaryLight:null}} visibility={args.type==='desktop'?isOpen.toString():'true'}>{item.icon}</MenuIcon>
                <MenuText visibility={args.type==='desktop'?isOpen.toString():'true'}>{item.title}</MenuText>
                </MenuItems>
                </Link>
            )
            )}
            </MenuContainer>

            {/* <MenuContainer>
                <MenuItems visibility={isOpen}>
                <MenuIcon visibility={isOpen}><MdDashboard size={'50px'}/></MenuIcon>
                <MenuText visibility={isOpen}>Dashboard</MenuText>
            </MenuItems>

            </MenuContainer> */}
            
            

        </DashboardMenuContainer>

    )
}

export const DashboardMenuComponent = DashboardMenu;