import { createSlice,createAsyncThunk, isRejectedWithValue, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";
import { uiSliceAction } from "./ui_slice";
import { ToastContainer, toast } from 'react-toastify';
import { messages } from "../../components/pages_data/message.data";

//const API = 'http://localhost:3000/user/'
const API = 'https://api.learnplusplus.com/user/'

const USER_LOGIN = `${API}login/admin`;
const USER_LOGOUT = `${API}logout`;
const USER_REGISTER = `${API}register/admin`;
const USER_GET = `${API}get`;
const USER_AuthCheck = `${API}check-auth`;
const USER_MODIFY_PERMISSION = `${API}user/admin/`;
const URL_LOGIN = 'https://learnplusplus.com/user/login';



const initialState={
    user:'none',
    status:'idle',//'idle | 'loading' | 'failed' | 'succeeded'
    error:'none',
    isError:false,
    isLoading:false,
    isSuccess:false,
    isAuthenticated:false
};

axios.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',

}
axios.defaults.withCredentials = 'include';


export const checkAuth = createAsyncThunk('user/checkauth',async()=>{
    var response;
    try{
         response = await axios.get(USER_AuthCheck);
            if(response.data){
                return response.data;
            }
    }catch(err){
        return err.message;
    }
});

export const getUser = createAsyncThunk('user/get',async()=>{
    var response;
    try{
         response = await axios.get(USER_GET);
            if(response.data){
                return response.data;
            }
    }catch(err){
        return err.message;
    }
});

export const logoutUser = createAsyncThunk('user/logout',async()=>{
    // try{
    //     const response = await axios.get(USER_LOGOUT,
    //         {
    //             headers: {
    //               Accept: 'application/json',
    //               'Content-Type': 'application/json',
    //             },
                
    //             withCredentials: 'include',}
    //         );
    //         if(response.data){
    //             return response.data;
    //         }
    // }catch(err){
    //     return err.message;
    // }
    try{
        return await (await axios.get(USER_LOGOUT,{
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            
            withCredentials: 'include',})).data
    }catch(e){
        return e.response.data.responseData??"Something Went Wrong";
        
    }
});

// export const fetchUserData = createAsyncThunk('user/get',async()=>{
//     try{
//         const response = await axios.get(USER_GET,
//             {
//                 headers: {
//                   Accept: 'application/json',
//                   'Content-Type': 'application/json',
//                 },
                
//                 withCredentials: 'include',}
//             );
//             if(response.data){
//                 return response.data;
//             }
//     }catch(err){
//         return err.message;
//     }
// });


export const loginUser = createAsyncThunk('user/login',async(data)=>{
    try{
        return await (await axios.post(USER_LOGIN,data)).data
    }catch(e){
        console.log("catch",e.response.data.responseData??"Something Went Wrong");
        return e.response.data.responseData??"Something Went Wrong";
        
    }
    
});

export const registerUser = createAsyncThunk('user/register',async(data)=>{
    try{
        return await (await axios.post(USER_REGISTER,data)).data
    }catch(e){
        console.log("catch",e.response.data.responseData??"Something Went Wrong");
        return e.response.data.responseData??"Something Went Wrong";   
    }
});





const AuthSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        
    },
    extraReducers(builder){
        builder
                .addCase(checkAuth.pending,(state,action)=>{
                    state.status = 'loading'
                    state.isLoading = true;
                })
                .addCase(checkAuth.fulfilled,(state,action)=>{
                    state.status = 'success'
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.user = action.payload; 
                    if(action.payload.responseCode === 200 && action.payload.responseData !== false && action.payload.responseData.adminType ){
                        state.isAuthenticated = true;
                    }                
                })
                .addCase(checkAuth.rejected,(state,action)=>{
                    state.status = 'error'
                    state.error = action.error.message
                })

                .addCase(loginUser.pending,(state,action)=>{
                    console.log("loading")
                    state.status = 'loading';
                    state.isLoading = true;
                })
                .addCase(loginUser.fulfilled,(state,action)=>{
                    console.log("fulfilled",action)
                    state.isLoading = false;
                    state.user = action.payload; 
                    if(action.payload.responseMessage === 'SUCCESS'){
                        state.isAuthenticated = true;
                        state.isSuccess = true;
                        state.status = 'success'
                        toast.success(messages.auth.successLogin.message,{position:"top-center",autoClose:2000})
                    }else{
                        state.isError = true;
                        state.error = action.payload
                        state.status = 'error'
                        toast.error(action.payload,{position:"top-center",autoClose:1000})
                        
                    }             
                })
                .addCase(loginUser.rejected,(state,action)=>{
                    state.status = 'error'
                    state.error = action.payload
                    state.isError = true;
                    toast.error("Something went wrong",{position:"top-center",autoClose:2000})
                        
                })


                .addCase(registerUser.pending,(state,action)=>{
                    console.log("loading")
                    state.status = 'loading';
                    state.isLoading = true;
                })
                .addCase(registerUser.fulfilled,(state,action)=>{
                    state.isLoading = false;
                    state.user = action.payload; 
                    if(action.payload.responseMessage === 'SUCCESS'){
                        state.isAuthenticated = true;
                        state.isSuccess = true;
                        state.status = 'success'
                        toast.success(messages.auth.successRegister.message,{position:"top-center",autoClose:2000})
                    }else{
                        state.isError = true;
                        state.error = action.payload
                        state.status = 'error'
                        toast.error(action.payload,{position:"top-center",autoClose:1000})
                    }             
                })
                .addCase(registerUser.rejected,(state,action)=>{
                    state.status = 'error'
                    state.error = action.payload
                    state.isError = true;
                    toast.error("Something went wrong",{position:"top-center",autoClose:2000})
                        
                })



                .addCase(getUser.pending,(state,action)=>{
                    state.status = 'loading'
                })
                .addCase(getUser.fulfilled,(state,action)=>{
                    state.status = 'success'
                    state.user = action.payload;
                    if(action.payload.responseMessage === 'SUCCESS'&& action.payload.responseData !== false){
                        state.isAuthenticated = true;
                    } 
                    
                })
                .addCase(getUser.rejected,(state,action)=>{
                    console.log(action)
                    state.status = 'error'
                    state.error = action.error.message;
                })


                


                .addCase(logoutUser.pending,(state,action)=>{
                    state.isLoading = true;
                    state.status = 'loading'
                })
                .addCase(logoutUser.fulfilled,(state,action)=>{
                    state.isLoading = false;
                    state.user = action.payload; 
                    if(action.payload.responseMessage === 'SUCCESS' ){
                        state.isAuthenticated = false;
                        state.isSuccess = true;
                        state.status = 'success'
                        toast.success(messages.auth.successLogout.message,{position:"top-center",autoClose:2000})
                    }else{
                        state.isError = true;
                        state.error = action.payload
                        state.status = 'error'
                        toast.error(action.payload,{position:"top-center",autoClose:1000})
                        
                    } 

                })
                .addCase(logoutUser.rejected,(state,action)=>{
                    state.status = 'error'
                    state.error = action.payload
                    state.isError = true;
                    toast.error("Something went wrong",{position:"top-center",autoClose:2000})
                      
                })
    }

});






export const AuthSliceActions = AuthSlice.actions;

export default AuthSlice;



