import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { kAppBarColor } from '../../../const/const'
import { desktop, mobile, tablet } from '../../../utils/responsive';


import {Badge, Box, Button,Grid,IconButton,TextField,Tooltip} from '@mui/material';
import { BugReport, Delete, Edit, Email, Visibility } from '@mui/icons-material';
import { useDeleteExamMutation, useDeleteUserMutation, useGetExamsQuery, useGetUsersQuery} from '../../../redux_store/service/fetchData';
import { Link } from 'react-router-dom';


import { DataGrid, } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{
      .userGrid{
        justify-items:center;
        padding:20px;
        width:100%;
        height:55vh;
        min-height:500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        ${mobile({
        'grid-template-columns': 'repeat(1, 1fr)',
        'grid-template-rows': 'repeat(2, 1fr)',
        'grid-row-gap': '10px',
        })};
        .dataInnerGrid{
          width:100%;
          height:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;

        }


        .userInnerGrid{
          width:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
        }
      }

    }
    
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;


const QuickAccess = styled(Box)`
  background-color:${kAppBarColor};
  border-radius:5px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  padding:10px;
`;





const columns = [
  { 
    field: 'username',
    headerName: 'User Name',
    overflow:'scroll',
    //flex: 3,
    width:350,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.username}</p>
      )

    }
  },
  {
    field: 'fullName',
    headerName: 'Full Name',
    //flex: 1,
    width:350,
    editable: false,
  },
  {
    field: 'city',
    headerName: 'City',
    //flex: 1.3,
    width:160,
    editable: false,
  },
  {
    field: 'district',
    headerName: 'District',
    //flex: 1,
    //hide:true,
    //type:'number',
    width:150,
    editable: false,
  },
  {
    field: 'state',
    headerName: 'State',
    //flex: 1.5,
    width:200,
    editable: false,
  },
  {
    field: 'zipCode',
    headerName: 'Pin',
    width:160,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    type:'number',
    width:200,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width:300,
    //flex: 1,
    editable: false,
  },

];

 

const setRows = (exam,val) => {
  var tempRows = [];
   if(exam.isSuccess && exam.data.responseMessage === "SUCCESS" ){
     if(typeof(exam.data.responseData) === "object"){
    for(const element of exam.data.responseData){
      if(element.username){
        tempRows.push({
        id:element._id,
        username:element.username,
        fullName:element.fullName,
        city:element.locationDetails?element.locationDetails.city??'null':'null',
        district:element.locationDetails?element.locationDetails.district??'null':'null',
        state:element.locationDetails?element.locationDetails.state??'null':'null',
        zipCode:element.locationDetails?element.locationDetails.zipCode??'null':'null',
        phone:element.contactDetails?element.contactDetails.phone??'null':'null',
      })
    }
    

    }
  }
  }
  return tempRows;
}








const ManageUser = (props) => {
  const [uri,changeUri] = useState('all-exam');
  const users = useGetUsersQuery(uri,{refetchOnMountOrArgChange:true});
  const [useDelete,deletedData] = useDeleteUserMutation()

  

 const rows = useMemo(()=>{
   if(users.isSuccess){
   return setRows(users,'');
   }
 },[users]);
 useEffect(()=>{
  if(deletedData.isSuccess){
    toast.success(deletedData.data.responseData,{position:"top-center",autoClose:1000})
    users.refetch();
  }
  if(deletedData.isError){
  toast.error(deletedData.error.data.responseData,{position:"top-center",autoClose:1000})
  }
},[deletedData])

 const requestSearch = (searchedVal) => {
  var quary = searchedVal.target.value.length > 0?searchedVal.target.value:'all-exam';
  changeUri(quary)
};

  const DeleteItem = async(item) => {
    await useDelete(item);
  };
  const actions = [
    { 
      field: 'action',
      headerName: 'ACTION',
      editable: false,
      sortable: false,
      width:200,
      justifyContent:'center',
      alignContent:'center',
      //flex: 2.2,
      renderCell: (params)=>{
        return (
        <div style={{width:'100%',display:'felx',flexDirection:'row',overflow:'scroll',textAlign:'center'}}>
          <Tooltip title='View'>
            <Link to={`/manage-user/view/${params.row.id}`}>
          <IconButton size="small" style={{color:'green',marginRight:'10px'}} >{<Visibility/>}</IconButton>
          </Link>
          </Tooltip>
          <Tooltip title='Edit'>
          {/* <Link to={`/manage-user/edit/${params.row.id}`}> */}
          <IconButton disabled size="small" style={{color:'blue',marginRight:'10px'}} >{<Edit/>}</IconButton>
          {/* </Link> */}
          </Tooltip>
          <Tooltip title='Delete'>
          <IconButton size="small" style={{color:'red'}} onClick={()=>DeleteItem(params.row.id)} >{<Delete/>}</IconButton>
          </Tooltip>
  
        </div>)
  
      }
    },
  ]

  


  
 

 
 


  //const user = useSelector(store=>store.user.user.responseData)
  
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll'}}>
      <QuickAccess  sx={{m:2,pr:2,pl:2,display:'flex',alignContent:'center'}} >

        <Box sx={{ flexGrow: 1,display:'flex' ,alignItems:'center'}}>
          <Tooltip title="Add Exam">
            {/* <Link to='/manage-user/add' style={{textDecoration:'none'}}> */}
            <Button variant="contained">Add New</Button>
            {/* </Link> */}
          </Tooltip>
        </Box>

        

        <Box sx={{ flexGrow: 0,display:'flex' ,alignItems:'center'}}>
        <Tooltip title="Bug Report" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained" >{<BugReport />}</Button>
              </Badge>
          </Tooltip>
          
          <Tooltip title="Email" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained">{<Email />}</Button>
              </Badge>
          </Tooltip>
        </Box>

      </QuickAccess>


          <TextField
          style={{margin:'10px',marginBottom:0,display:'flex'}}
          
            label="Search Exams"
            onChange={(searchVal) => requestSearch(searchVal)}
            InputProps={{
              type: 'search',
            }}
          />
      <div style={{width:'100%',padding:'10px',paddingTop:0}}>
      {
        <DataGrid
        autoHeight
        style={{}}
        rows={rows??[]}
        columns={columns.concat(actions)}
        pageSize={20}
        loading={users.isLoading}
        rowsPerPageOptions={[20]}
        disableSelectionOnClick
      />}
      </div>
    </div>
      </Container>
  )
}

export default ManageUser