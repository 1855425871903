import React, {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, Link, } from 'react-router-dom';
import styled from 'styled-components'
import { kAppBarColor, kPrimaryColor } from '../../../const/const'
import {registerUser } from '../../../redux_store/slices/auth_slice';
import { uiSliceAction } from '../../../redux_store/slices/ui_slice';
import { desktop, mobile, tablet } from '../../../utils/responsive';
import Logo from '../../header/Logo';
import { messages } from '../../pages_data/message.data';
import 'react-toastify/dist/ReactToastify.css';

import {
  Visibility,
  VisibilityOff,
  AccountCircle,
  Phone,
  Key,
  AlternateEmail
} from '@mui/icons-material';

import {
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

var validator = require('validator/es/lib/isEmail');



const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100vw;
    height:100vh;
    min-height:600px;
    ${mobile({
    'height':'90%',
    
    })};
    
`;

const FormArea = styled.div`
  height:80%;
  min-height:500px;
  width:70%;
  min-width:400px;
  max-width:1000px;
  max-height:800px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  box-shadow: 0 0 5px 0.4px #000000e2;
  z-index:1;
  border-radius:10px;
  ${mobile({
    'max-height':'500px',
    'min-height':'380px',
    'min-width':'300px',
    "border-radius":"10px"
    })};
    ${tablet({
      width:'90%',
      'max-height':'500px',
    })};
  
`;



const FormLeft = styled.div`
  /* background-image: linear-gradient(to bottom right,#4fddd5,${kPrimaryColor},#42ac6f); */
  height:100%;
  width:50%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  justify-items:center;
  border-top-left-radius:10px;
  border-bottom-left-radius:10px;
  ${mobile({
    width:'100%',
    height:'380px',
    "border-radius":"10px"
      
    })};
`;


const FormRight = styled.div`
  background-color:${kAppBarColor};
  height:100%;
  width:50%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  text-align:center;
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  ${mobile({
    'display':'none'
      
    })};
  
`;

const Graphic = styled.img`
  height:60%;
  width:70%;
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  z-index:2;
  ${tablet({
    height:'40%',
    'margin-top':'45px',
    'margin-bottom':'50px'
      
    })};
    ${mobile({
    height:'40%',
    'margin-top':'40px',
    'margin-bottom':'45px'
      
    })};
  
`

const Form = styled.form`
  margin: auto;
  width: 100%;
  height:80%;
  padding: 10px;
  text-align:center;
  align-items:center;
  justify-content:space-around;
  display:flex;
  flex-direction:column;
  z-index:2;

  ${tablet({
    'height':'90%'
      
    })};
    ${mobile({
    'height':'95%'
      
    })};


  
`;

const SignInText = styled.p`
  font-weight:500;
  font-size:40px;
  margin-bottom:5%;
  /* color:white; */;
  ${tablet({
    'font-size':'30px'
      
    })};
    ${mobile({
    'font-size':'25px'
      
    })};
  
`;

const BoldText = styled.p`
  font-weight:500;
  font-size:40px;
  color:black;
  ${mobile({
    'font-size':'30px'
      
    })};
    ${tablet({
    'font-size':'35px'
      
    })};
`;



const ViewPasswordLabel = styled.label`
 font-size:1rem;
 /* color:white; */;
 ${desktop({
    'font-size':'0.8rem'
      
    })};
 
`;

const ForgotPasswordText = styled.label`
 font-size:1rem;  
 margin-bottom: 20px;
 margin-top:10px;
 cursor: pointer;
 transform:0.2s;
 :hover{
   color:#0097fc
 }
 ${desktop({
    'font-size':'0.8rem'
      
    })};
`;

const PasswordRow = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  align-content:center;
  justify-content:start;
  justify-items:left;
  text-align:left;  
  height:30px;
  width:90%;
  margin-bottom:25px;
  padding:10px;
`;


const MobileLogo = styled.div`
  flex-direction:column;
  font-size:3rem;
  justify-content:center;
  justify-items:center;
  text-align:center;
  margin-bottom:20px;
  ${mobile({
    display:'flex',
    })};
  ${desktop({
    display:'none',
  })};
  ${tablet({
    display:'none',
  })};

`

const SignUp = () => {

  const [data, setData] = useState({
    name: '',
    secret: '',
    phone: '',
    email: '',
    password: '',
    showPassword: false,
    });
    const [error, setError] = useState({
      errors:{
      nameError: false,
      secretError: false,
      phoneError: false,
      emailError: false,
      passwordError: false,
      }
      
      });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
 // console.log(location)
  const user = useSelector(state=>state.user);
 
  const viewPassword = ()=>{
    setData({...data,showPassword:!data.showPassword});
  }
  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
    var currentError = error.errors;
    currentError[`${prop}Error`] = false;
    setError({...error,errors:currentError})
  };


  const Register = (data)=>{
    dispatch(registerUser(data))
  }

  const submit=(e)=>{
    e.preventDefault();
    var isError = false;
    var errors = {
      nameError:false,
      secretError:false,
      phoneError:false,
      emailError:false,
      passwordError:false,
    }
    var datas = {username:String,password:String,fullName:String,secret:String,'contactDetails.phone':String,'contactDetails.email':String};
    datas.username = data.email
    datas['contactDetails.email'] = data.email
    datas.password = data.password
    datas['contactDetails.phone'] = data.phone
    datas.fullName = data.name
    datas.secret = data.secret
    if(!validator.default(datas.username)) {
      isError = true;
      errors.emailError = true;
      //setData({ ...data, emailError:true })
    };
    if(datas.password.length < 6){
      isError = true;
      errors.passwordError = true;
      //setData({ ...data, passwordError:true })
    };
    if(datas.fullName.length < 3){
      isError = true;
      errors.nameError = true;
      //setData({ ...data, nameError:true })
    };
    if(datas.secret.length < 51){
      isError = true;
      errors.secretError = true;
      //setData({ ...data, secretError:true })
    };
    if(datas['contactDetails.phone'].length < 10){
      isError = true;
      errors.phoneError = true;
      //setData({ ...data, phoneError:true })
    };
    if(isError){
      setError({errors:errors})
      return
    }
    Register(datas);
  }



 
  
if(user.isAuthenticated === false){
  // if(user.isLoading === true){
  //   return <Loading/>
  // }
  
  
  return (
    <>
      <Container>
      <MobileLogo>
              <Logo className='logo'></Logo>
              <BoldText style={{color:'black',fontSize:'12px',marginBottom:'0px'}}>Admin Panel</BoldText>
        </MobileLogo>
        {console.log(user)}
        <FormArea>
          <FormLeft>
            <Form id={'form'}>
              <SignInText>Register</SignInText>

              <TextField 
              error={error.errors.nameError}
              helperText={error.errors.nameError?messages.auth.invalidname.message:''}
              id='name'
              style={{backgroundColor:'#fff',borderRadius:'5px', m: 1,marginBottom:10,maxWidth:'400px',width:'90%'}} 
              variant="outlined"
              size='small'
              label="Name"
              onChange={handleChange('name')}
              prefix={<h1>sas</h1>}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                  {<AccountCircle/>}
                </InputAdornment>,
              }}
              />

              <TextField 
              error={error.errors.secretError}
              helperText={error.errors.secretError?messages.auth.invalidSecret.message:''}
              id='secret'
              style={{backgroundColor:'#fff',borderRadius:'5px', m: 1,marginBottom:10,maxWidth:'400px',width:'90%'}} 
              variant="outlined"
              size='small'
              label="Secret"
              type={'password'}
              onChange={handleChange('secret')}
              prefix={<h1>sas</h1>}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                  <Key/>
                </InputAdornment>,
              }}
              />
              
              <TextField 
              error={error.errors.phoneError}
              helperText={error.errors.phoneError?messages.auth.invalidPhone.message:''}
              id='phone'
              style={{backgroundColor:'#fff',borderRadius:'5px', m: 1,marginBottom:10,maxWidth:'400px',width:'90%'}} 
              variant="outlined"
              size='small'
              label="Phone"
              onChange={handleChange('phone')}
              prefix={<h1>sas</h1>}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                  {<Phone/>}
                </InputAdornment>,
              }}
              />
              
              
              <TextField 
              error={error.errors.emailError}
              helperText={error.errors.emailError?messages.auth.invalidEmail.message:''}
              id='email'
              style={{backgroundColor:'#fff',borderRadius:'5px', m: 1,marginBottom:10,maxWidth:'400px',width:'90%'}} 
              variant="outlined"
              size='small'
              label="Email"
              onChange={handleChange('email')}
              prefix={<h1>sas</h1>}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                  {<AlternateEmail/>}
                </InputAdornment>,
              }}
              />

              <TextField 
              error={error.errors.passwordError}
              helperText={error.errors.passwordError?messages.auth.invalidPassword.message:''}
              id='password'
              style={{backgroundColor:'#fff',borderRadius:'5px', m: 1,maxWidth:'400px',width:'90%'}} 
              variant="outlined"
              size='small'
              label="Password"
              type={data.showPassword ? 'text' : 'password'}
              onChange={handleChange('password')}
              prefix={<h1>sas</h1>}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                  aria-label="toggle password visibility"
                  onClick={viewPassword}
                  edge="end"
                >
                  {Boolean(data.showPassword)?<VisibilityOff/>:<Visibility/>}
                </IconButton>
                </InputAdornment>,
              }}
              />
              <LoadingButton
                style={{borderRadius:'5px', m: 1,marginTop:20}} 
                size="small"
                loading={user.isLoading}
                loadingIndicator={<CircularProgress size={'2ch'} />}
                variant="contained"
                onClick={submit}
              >
                Register
              </LoadingButton>

              <PasswordRow style={{marginBottom:'0px',marginTop:'10px',justifyContent:'center'}}>
              <ViewPasswordLabel style={{marginRight:'5px'}}>Already registered ?</ViewPasswordLabel>
              <Link to={'/sign-in'} style={{textDecoration:'none',display:'flex',flexDirection:'column'}}>
              <ForgotPasswordText style={{margin:0}}>Sign In</ForgotPasswordText>
              </Link>
              </PasswordRow>
            </Form>
           
          </FormLeft>

          <FormRight>
            <BoldText style={{color:'black',marginBottom:'0px'}}>Welcome</BoldText>
            <Graphic  src={window.location.origin+`/images/signup.png`}/>
            <div>
            <Logo></Logo>
            <BoldText style={{color:'black',fontSize:'12px',marginBottom:'0px'}}>Admin Panel</BoldText>
            </div>
          </FormRight>
        </FormArea>
      </Container>
      </>
  )
}
else{
  return <Navigate to={(location.pathname === '/sign-in' ||  location.pathname === '/sign-up')?'/':location.pathname} replace={true} state={null} />
}
}

export default SignUp