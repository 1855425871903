import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { kAppBarColor } from '../../../const/const'
import { desktop, mobile, tablet } from '../../../utils/responsive';


import {Autocomplete, Badge, Box, Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Grid,IconButton,TextField,Tooltip} from '@mui/material';
import { BugReport, Delete, Edit, Email, TokenTwoTone, Visibility } from '@mui/icons-material';
import { useDeleteExamMutation, useDeleteUserMutation, useGenerateTokenMutation, useGenerateTokenQuery, useGetAdminsQuery, useGetExamsQuery, useGetTokensQuery, useGetUsersQuery} from '../../../redux_store/service/fetchData';
import { Link } from 'react-router-dom';


import { DataGrid, } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { BsClipboard } from 'react-icons/bs';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{
      .userGrid{
        justify-items:center;
        padding:20px;
        width:100%;
        height:55vh;
        min-height:500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        ${mobile({
        'grid-template-columns': 'repeat(1, 1fr)',
        'grid-template-rows': 'repeat(2, 1fr)',
        'grid-row-gap': '10px',
        })};
        .dataInnerGrid{
          width:100%;
          height:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;

        }


        .userInnerGrid{
          width:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
        }
      }

    }
    
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;


const QuickAccess = styled(Box)`
  background-color:${kAppBarColor};
  border-radius:5px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  padding:10px;
`;





const columns = [
  { 
    field: 'secret',
    headerName: 'Secret',
    overflow:'scroll',
    //flex: 3,
    width:600,
    editable: false,
    renderCell: (params)=>{
      return (
        <div style={{display:'flex',flexDirection:'row',overflow:'scroll',alignContent:'center',alignItems:'center'}}>
          <p key={params.row.id} >{params.row.secret}</p>
          <IconButton 
            onClick={()=>{
              navigator.clipboard.writeText(params.row.secret)
              toast.info("Copied To Clipboard",{position:"top-center",autoClose:1000})
            }}
            size='small' 
            aria-label="copy" 
            style={{marginLeft:'5px'}}>
            <BsClipboard />
          </IconButton>
        </div>
      
      )

    }
  },
  {
    field: 'adminLevel',
    headerName: 'Admin Level',
    //flex: 1,
    width:350,
    editable: false,
  },
  {
    field: 'expire_at',
    headerName: 'Expires At',
    //flex: 1,
    width:300,
    editable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    width:300,
    //flex: 1,
    editable: false,
  },

];

 

const setRows = (exam,val) => {
  var tempRows = [];
   if(exam.isSuccess && exam.data.responseMessage === "SUCCESS" ){
     if(typeof(exam.data.responseData) === "object"){
    for(const element of exam.data.responseData){
      if(element.secret){
        tempRows.push({
        id:element._id,
        secret:element.secret,
        adminLevel:element.adminLevel,
        expire_at:element.expire_at,
      })
    }
    

    }
  }
  }
  return tempRows;
}








const Tokens = (props) => {
  const tokens = useGetTokensQuery('',{refetchOnMountOrArgChange:true});
  const [openTokenForm,setOpenTokenForm] = useState(false);
  const [generateToken,token] = useGenerateTokenMutation();

  

 const rows = useMemo(()=>{
   if(tokens.isSuccess){
   return setRows(tokens,'');
   }
 },[tokens]);

  const [adminLevel,setAdminLevel] = useState(null);
  const [adminLevelError,setAdminLevelError] = useState(false);



  const handleTokenFormSubmit=()=>{
    console.log(adminLevel)
    if(!adminLevel){
      setAdminLevelError(true);
      return toast.error("Please Select Admin Level",{position:"top-center",autoClose:1000})
    }
    generateToken({adminLevel:adminLevel});
    setAdminLevel(null);
    setOpenTokenForm(false)
  }
  const adminLevelOnChange=(value)=>{
    setAdminLevelError(false);
    setAdminLevel(value)
  }

  const handleTokenFormOpen=()=>{
    setOpenTokenForm(true)
  }
  const handleTokenFormClose=()=>{
    setOpenTokenForm(false)
  }


  useEffect(()=>{
    if(token.isError){
      toast.error(token.error.data.responseData,{position:"top-center",autoClose:1000})
    }
    if(token.isSuccess){
      tokens.refetch();
      toast.success("Succesfully generated the token",{position:"top-center",autoClose:1000})
    }
  },[token.isError,token.isSuccess,token.error])



  


  
 

 

useEffect(()=>{
  if(tokens.isError){
    toast.error(tokens.error.data.responseData,{position:"top-center",autoClose:1000})
  }
  if(tokens.isSuccess){

  }
},[tokens.isError,tokens.isSuccess,tokens.error])
 


  //const user = useSelector(store=>store.user.user.responseData)
  
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll'}}>
      <QuickAccess  sx={{m:2,pr:2,pl:2,display:'flex',alignContent:'center'}} >

        <Box sx={{ flexGrow: 1,display:'flex' ,alignItems:'center'}}>
          <Tooltip title="Generate Token">
            <Button variant="contained" onClick={handleTokenFormOpen}>Generate Token</Button>
          </Tooltip>
        </Box>

        <Dialog open={openTokenForm} onClose={handleTokenFormClose}>
                <DialogTitle>Add Accreditation</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Select Parameters for Generating Secret Tokens.
                  </DialogContentText>
                  <Autocomplete
                        fullWidth
                        style={{marginBottom:'10px',}}
                        value={adminLevel}
                        onChange={(type,value)=>adminLevelOnChange(value)}
                        getOptionLabel={(option) => option} 
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        options={["A","B","C","D"]}
                        renderInput={(params) => (
                        <TextField
                            required
                            error={adminLevelError}
                            helperText={adminLevelError?'Please SelectAdmin Level':null}
                            {...params}
                            label={'Select Admin Level'}
                            InputProps={{
                            ...params.InputProps,
                            }}
                        />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleTokenFormClose}>Cancel</Button>
                  <Button onClick={handleTokenFormSubmit}>Generate</Button>
                </DialogActions>
              </Dialog>

        

        <Box sx={{ flexGrow: 0,display:'flex' ,alignItems:'center'}}>
        <Tooltip title="Bug Report" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained" >{<BugReport />}</Button>
              </Badge>
          </Tooltip>
          
          <Tooltip title="Email" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained">{<Email />}</Button>
              </Badge>
          </Tooltip>
        </Box>

      </QuickAccess>


          
      <div style={{width:'100%',padding:'10px',paddingTop:0}}>
      {
        <DataGrid
        autoHeight
        style={{}}
        rows={rows??[]}
        columns={columns}
        pageSize={20}
        loading={tokens.isLoading}
        rowsPerPageOptions={[20]}
        disableSelectionOnClick
      />}
      </div>
    </div>
      </Container>
  )
}

export default Tokens