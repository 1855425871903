import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Route,Routes} from "react-router-dom";
import { useLocation, Navigate } from 'react-router';
import Loading from '../components/pages/loading/Loading';
import { useCheckAuthQuery, userApi } from '../redux_store/service/users';
import { checkAuth, logoutUser } from '../redux_store/slices/auth_slice';



var called = false;
const PrivateRoute = ({child}) => {
    

    const user = useSelector(state=>state.user);
  
   
   
    
    const location = useLocation();
    if(user.isAuthenticated === true && user.user.responseData.adminType){
        if(user.isLoading){
            return <Loading></Loading>

        }else return (
            <Routes>
                <Route
                path='*'
                element={child}/>
            </Routes>)
    }else{
        return <Navigate to={{pathname: '/sign-in'}} state={(location.pathname === '/sign-in' || location.pathname === '/sign-up')?null:location}/>
    }

}

export default PrivateRoute