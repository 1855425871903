import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { kAppBarColor, kAppBarDark, kBackgroundColor } from '../../../const/const'
import DashboardLayout from '../DashboardLayout'
import { desktop, mobile, tablet } from '../../../utils/responsive';


import {Autocomplete, Avatar, Badge, Box, Button, ButtonBase, Card,CardActionArea,CardContent,CircularProgress,FormControl,FormControlLabel,FormLabel,Grid,IconButton,Paper,Radio,RadioGroup,Stack,TextField,Tooltip,Typography} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AdminPanelSettings, Backpack, BugReport, Delete, Edit, Email, HistoryEdu, Person, Quiz, School, Visibility } from '@mui/icons-material';
import { height, maxWidth, width } from '@mui/system';
import px2vw from '../../../utils/px2v';
import { useDeleteCollegeMutation, useGetCityNamesQuery, useGetCollegesMutation, useGetCollegesQuery, useGetMainDataCountQuery, useGetStateNamesQuery } from '../../../redux_store/service/fetchData';
import { Link } from 'react-router-dom';


import { DataGrid, } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{
      .userGrid{
        justify-items:center;
        padding:20px;
        width:100%;
        height:55vh;
        min-height:500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        ${mobile({
        'grid-template-columns': 'repeat(1, 1fr)',
        'grid-template-rows': 'repeat(2, 1fr)',
        'grid-row-gap': '10px',
        })};
        .dataInnerGrid{
          width:100%;
          height:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;

        }


        .userInnerGrid{
          width:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
        }
      }

      .filterCard{
        display:flex;
        height:80px;
        margin:10px;
        align-content:center;
        align-items:center;
        padding:10px;
        #topCollegeFilter{
          display:flex;
          align-items:center;
          align-content:center;
          margin-left:20px;
        }
      }
    }
    
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;


const QuickAccess = styled(Box)`
  background-color:${kAppBarColor};
  border-radius:5px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  padding:10px;
`;

const MainDataGrid = styled(Grid)`
  justify-items:center;
  padding:20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;

  ${mobile({
    'grid-template-columns': 'repeat(2, 1fr)',
    'grid-template-rows': '2fr',
    'grid-row-gap': '10px',
    })};

`;



const columns = [
  { 
    field: 'collegeName',
    headerName: 'CollegeName',
    overflow:'scroll',
    //flex: 3,
    width:350,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.collegeName}</p>
      )

    }
  },
  {
    field: 'code',
    headerName: 'Code',
    //flex: 1,
    width:120,
    editable: false,
  },
  {
    field: 'ownership',
    headerName: 'Ownership',
    //flex: 1.3,
    width:150,
    editable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    //flex: 1,
    //hide:true,
    width:150,
    editable: false,
  },
  {
    field: 'programs',
    headerName: 'Programs',
    //flex: 1.5,
    width:200,
    editable: false,
    renderCell: (params)=>{
      var type = []
      if(params.row.programs.includes('undergraduate')){
        type.push("UG")
      }
      if(params.row.programs.includes('postgraduate')){
        type.push("PG")
      }
      if(params.row.programs.includes('diploma')){
        type.push("DIP")
      }
      if(params.row.programs.includes('phd')){
        type.push("PHD")
      }
      return (
        <div style={{display:'flex'}}>{type.map(d=>(
          <p key={d} style={{
            margin:2,
            color:d==='UG'
            ?'blue':d==='PG'?'green'
            :d==='DIP'
            ?'orange'
            :'red'
          }}>{d}</p>
        ))}</div>
      )

    }
  },
  {
    field: 'type',
    headerName: 'Type',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'city',
    headerName: 'City',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'district',
    headerName: 'District',
    width:200,
    //flex: 1.5,
    editable: true,
    renderCell: (params)=>{
      return (
      <p style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.district}</p>
      )

    }
    
  },
  {
    field: 'state',
    headerName: 'State',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'decipline',
    headerName: 'Decipline',
    width:300,
    //flex: 1.5,
    editable: true,
    renderCell: (params)=>{
      return (
        <div style={{display:'flex',overflow:'scroll'}}>{params.row.decipline.map(d=>(
          <p key={d} style={{
            margin:2,
          }}>{d}</p>
        ))}</div>
      )

    }
    

  },
  {
    field: 'isWomen',
    headerName: 'Women',
    width:100,
    //flex: 1,
    editable: false,
  },
  {
    field: 'estd',
    headerName: 'ESTD',
    type:'number',
    width:100,
    //flex: 1,
    editable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    width:300,
    //flex: 1,
    editable: false,
  },

];

const schoolColumn = [
  { 
    field: 'schoolName',
    headerName: 'SchoolName',
    overflow:'scroll',
    //flex: 3,
    width:350,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.schoolName}</p>
      )

    }
  },
  {
    field: 'code',
    headerName: 'Code',
    //flex: 1,
    width:120,
    editable: false,
  },
  {
    field: 'ownership',
    headerName: 'Ownership',
    //flex: 1.3,
    width:150,
    editable: false,
  },
  {
    field: 'board',
    headerName: 'Board',
    //flex: 1,
    //hide:true,
    width:150,
    editable: false,
  },
  {
    field: 'stage',
    headerName: 'Stage',
    //flex: 1.5,
    width:200,
    editable: false,
  },
  {
    field: 'city',
    headerName: 'City',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'state',
    headerName: 'State',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'estd',
    headerName: 'ESTD',
    type:'number',
    width:100,
    //flex: 1,
    editable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    width:300,
    //flex: 1,
    editable: false,
  },

];

const examColumn = [
  { 
    field: 'examName',
    headerName: 'ExamName',
    overflow:'scroll',
    //flex: 3,
    width:350,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.collegeName}</p>
      )

    }
  },
  {
    field: 'short',
    headerName: 'Short',
    //flex: 1,
    width:120,
    editable: false,
  },
  {
    field: 'level',
    headerName: 'Level',
    //flex: 1.3,
    width:150,
    editable: false,
  },
  {
    field: 'year',
    headerName: 'Year',
    //flex: 1,
    //hide:true,
    width:150,
    editable: false,
  },
  {
    field: 'cunductedBy',
    headerName: 'CunnductedBy',
    //flex: 1.5,
    width:200,
    editable: false,
  },
  {
    field: 'mode',
    headerName: 'Mode',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'ageLimit',
    headerName: 'Age Limit',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'slots',
    headerName: 'Slots',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'marks',
    headerName: 'Marks',
    width:300,
    //flex: 1.5,
    editable: true,
    

  },
  {
    field: 'time',
    headerName: 'Time',
    width:100,
    //flex: 1,
    editable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    width:300,
    //flex: 1,
    editable: false,
  },

];

 

const setRows = (colleges,val) => {
  var tempRows = [];
   if(colleges.isSuccess && colleges.data.responseMessage === "SUCCESS" ){
     if(typeof(colleges.data.responseData) === "object"){
    for(const element of colleges.data.responseData){
      if(element.basicDetails){
        tempRows.push({
        id:element._id,
        collegeName:element.basicDetails.name,
        code:element.basicDetails.code,
        estd:element.basicDetails.estd,
        ownership:element.ownership,
        category:element.category,
        programs:element.programTypes,
        type:element.type,
        isWomen:element.isWomen===true?"YES":"NO",
        city:element.locationDetails.city,
        district:element.locationDetails.district,
        state:element.locationDetails.state,
        decipline:element.discipline
      })
    }
    

    }
  }
  }
  return tempRows;
}

const filterOptions = []







const Colleges = (props) => {
  const [value,setValue] = useState(null)
  const [uri,changeUri] = useState('all-college');
  const [isFilterOpen,setFilter] = useState(false);
  const [options, setOptions] = React.useState([]);
  const colleges = useGetCollegesQuery(uri,{refetchOnMountOrArgChange:true});
  const [callCity,setCity] = useState(true);
  const [callState,setState] = useState(true);
  const [selectedValue, setSelectedValue] = useState('none');
  const cities = useGetCityNamesQuery('',{skip:callCity});
  const states = useGetStateNamesQuery('',{skip:callState});
  const [useDelete,deletedData] = useDeleteCollegeMutation()

  

 const rows = useMemo(()=>{
   if(colleges.isSuccess){
   return setRows(colleges,selectedValue);
   }
 },[colleges,selectedValue]);

 const requestSearch = (searchedVal) => {
  var quary = searchedVal.target.value.length > 0?searchedVal.target.value:'all-college';
  changeUri(quary)
};

useEffect(()=>{
  if(deletedData.isSuccess){
    toast.error(`${deletedData.data.responseData}`,{position:"top-center",autoClose:1000})
    colleges.refetch();
  }
  if(deletedData.isError){
    toast.error(deletedData.error.data.responseData,{position:"top-center",autoClose:1000})
    }
},[deletedData])

  // useEffect(()=>{
  //   if(colleges.isSuccess && !fetched && colleges.data.responseMessage === "SUCCESS"){
  //     for(const element of colleges.data.responseData){
  //       if(element.basicDetails){
  //       rows.push({
  //         id:element._id,
  //         collegeName:element.basicDetails.name,
  //         code:element.basicDetails.code,
  //         estd:element.basicDetails.estd,
  //         ownership:element.ownership,
  //         category:element.category,
  //         programs:element.programTypes,
  //         type:element.type,
  //         isWomen:element.isWomen===true?"YES":"NO"
  //       })
  //     }
  //     }
  //     console.log(rows)
  //     canFetch(true);
  //     if(!isCalled){
  //       changeCalled(true);
  //     }
      
  //   }
  // },[colleges,rows,fetched,isCalled])
  const DeleteItem = async(item) => {
    await useDelete(item);
  };
  const actions = [
    { 
      field: 'action',
      headerName: 'ACTION',
      editable: false,
      sortable: false,
      width:200,
      justifyContent:'center',
      alignContent:'center',
      //flex: 2.2,
      renderCell: (params)=>{
        return (
        <div style={{width:'100%',display:'felx',flexDirection:'row',overflow:'scroll',textAlign:'center'}}>
          <Tooltip title='View'>
            <Link to={`/colleges/view/${params.row.id}`}>
          <IconButton size="small" style={{color:'green',marginRight:'10px'}} >{<Visibility/>}</IconButton>
          </Link>
          </Tooltip>
          <Tooltip title='Edit'>
          <Link to={`/colleges/edit/${params.row.id}`}>
          <IconButton size="small" style={{color:'blue',marginRight:'10px'}} >{<Edit/>}</IconButton>
          </Link>
          </Tooltip>
          <Tooltip title='Delete'>
          <IconButton size="small" style={{color:'red'}} onClick={()=>DeleteItem(params.row.id)} >{<Delete/>}</IconButton>
          </Tooltip>
  
        </div>)
  
      }
    },
  ]

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setValue(null)
    if(event.target.value === 'none'){
      changeUri('all-college')
    }else if (event.target.value === 'india'){
      changeUri('top/india/india/null')
    }else{
      changeUri('all-college')
    }
  };
  const handleSelect = (value) => {
    setValue(value)
    if(value !== null){
      changeUri(`top/${selectedValue}/${value}/null`)
    }else{
      changeUri('all-college')
    }
      
  };

  


  useEffect(()=>{
    if(isFilterOpen){
      selectedValue === 'city'
        ?setCity(false)
        :setState(false);
        if(selectedValue === 'city'?cities.isSuccess:states.isSuccess){
          var data = []
          for(const element of selectedValue === 'city'?cities.data.responseData??[]:states.data.responseData??[] ){
            if(!data.includes(element)){
              data.push(element)
            }   
          }
          //data.city = selectedValue === 'city'?cities.data.responseData??[]:states.data.responseData??[];

          setOptions(data??[])
        }
      
    }
  },[isFilterOpen,selectedValue,cities,states])

  
 

 
 


  //const user = useSelector(store=>store.user.user.responseData)
  
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll'}}>
      <QuickAccess  sx={{m:2,pr:2,pl:2,display:'flex',alignContent:'center'}} >

        <Box sx={{ flexGrow: 1,display:'flex' ,alignItems:'center'}}>
          <Tooltip title="Admin Permission Level">
            <Link to='/colleges/add' style={{textDecoration:'none'}}>
            <Button variant="contained">Add New</Button>
            </Link>
          </Tooltip>
        </Box>

        

        <Box sx={{ flexGrow: 0,display:'flex' ,alignItems:'center'}}>
        <Tooltip title="Bug Report" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained" >{<BugReport />}</Button>
              </Badge>
          </Tooltip>
          
          <Tooltip title="Email" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained">{<Email />}</Button>
              </Badge>
          </Tooltip>
        </Box>

      </QuickAccess>
      {/* <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        /> */}

        <Card className='filterCard'>
          <Box>
            <Typography>Filter:</Typography>
           
          </Box>
          <div id='topCollegeFilter'>
            <FormLabel style={{marginRight:'10px',display:'flex'}}>Top Colleges</FormLabel>
              <RadioGroup
                row
                value={selectedValue}
                onChange={handleChange}>
                <FormControlLabel value="none" control={<Radio />} label="None" />
                <FormControlLabel value="india" control={<Radio />} label="India" />
                <FormControlLabel value="state" control={<Radio />} label="State" />
                <FormControlLabel value="city" control={<Radio />} label="District" />
              </RadioGroup>

           {(selectedValue === 'city' || selectedValue === 'state') && 
              <Autocomplete
      id="filterData"
      sx={{ width: 300 }}
      open={isFilterOpen}
      
      onOpen={() => {
        setFilter(true);
      }}
      onClose={() => {
        setFilter(false);
      }}
      value={value}
      onChange={(event, value) => handleSelect(value)} 
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={options.sort()}
      groupBy={(option) => option[0]}
      loading={selectedValue === 'city'?cities.isLoading:states.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={selectedValue === 'city'?'Setect District':'Select State'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {selectedValue === 'city'?cities.isLoading:states.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />}
          </div>
      
          {/* <Autocomplete
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        defaultValue={[top100Films[13]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="filterSelectedOptions"
            placeholder="Favorites"
          />
          )}
      /> */}

        </Card>

          <TextField
          style={{margin:'10px',marginBottom:0,display:'flex'}}
          
            label="Search College"
            onChange={(searchVal) => requestSearch(searchVal)}
            InputProps={{
              type: 'search',
            }}
          />
      <div style={{width:'100%',padding:'10px',paddingTop:0}}>
      {
        <DataGrid
        autoHeight
        style={{}}
        rows={rows??[]}
        columns={columns.concat(actions)}
        pageSize={20}
        loading={colleges.isLoading}
        rowsPerPageOptions={[20]}
        disableSelectionOnClick
      />}
      </div>
    </div>
      </Container>
  )
}

export default Colleges