import React from 'react';
import styled from 'styled-components';
import px2vw from '../../utils/px2v';
import {mobile,tablet} from '../../utils/responsive'


const Container = styled.div`
    display:flex;
    justify-content:center
`;

const LogoFullSize = styled.h1`
    color:${props=>props.color};
    ${mobile({
        display:'none',
    })};
    ${tablet({
        'font-size':'3rem',
    })};
`;

const LogoSmallSize = styled.h1`
    color:${props=>props.color};
    display:none;
    ${mobile({
        display:'block',
        'font-size':'3rem',

    })}
`;



const Logo = (arg) => {
  return <Container>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#6F2FA1'}>L</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#102160'}>e</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#356FBF'}>a</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#40A64A'}>r</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#F4BD04'}>n</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#EC2400'}>P</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#6E2F9F'}>l</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#102060'}>u</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#356FBF'}>s</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#40A64B'}>P</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#F4BD00'}>l</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#EB2400'}>u</LogoFullSize>
      <LogoFullSize color={arg.defaultColor?arg.defaultColor:'#6E2F9F'}>s</LogoFullSize>


      <LogoSmallSize color={arg.defaultColor?arg.defaultColor:'#6F2FA1'}>L</LogoSmallSize>
      <LogoSmallSize color={arg.defaultColor?arg.defaultColor:'#EC2400'}>P</LogoSmallSize>
      <LogoSmallSize color={arg.defaultColor?arg.defaultColor:'#40A64B'}>P</LogoSmallSize>
  </Container>;
};

export default Logo;
