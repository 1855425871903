import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";
import AuthSlice from "./slices/auth_slice";
import uiSlice, { uiSliceAction } from "./slices/ui_slice";
import {userApi} from './service/users'
import { FetchDataApi } from "./service/fetchData";

const store = configureStore({
    reducer:{
        uiSlice:uiSlice.reducer,
        [AuthSlice.name]:AuthSlice.reducer,
        [FetchDataApi.reducerPath]:FetchDataApi.reducer,
       //[userApi.reducerPath]:userApi.reducer
    },
    middleware:(getDefaultMiddleware)=>
        getDefaultMiddleware().concat(FetchDataApi.middleware)
});

setupListeners(store.dispatch)



export default store;