import { SquareFoot,Foundation,Female,Stream,Badge,Category,SupervisedUserCircle,Numbers,VerifiedUser,MergeType, ArrowCircleLeft, ArrowCircleRight, StarBorder, Inbox, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Card, Collapse, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Rating } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components';
import { kAppBarColor, kBackgroundDark } from '../../const/const';
import { useGetCollegeQuery, useGetSchoolQuery } from '../../redux_store/service/fetchData';
import { mobile, tablet } from '../../utils/responsive';
import px2vw from '../../utils/px2v'
import {ScrollableListView} from '../../styles/ScrollableListView'
import gsap from 'gsap';
import { DataGrid } from '@mui/x-data-grid';



const Center = styled.div`
  width:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;

    
    .staticData{
      margin-bottom:10px;
      padding:2px;
      padding-left:10px;
      font-size:20px;
      ${tablet({
          'font-size':'2rem'
        })}; 
        ${mobile({
          width:'100%'
        })};

    }
    .header{
      font-size:30px;
      margin-bottom:15px;
    }

    .locationGrid{
      display:flex;
      flex-direction:row;
      width:100%;
      margin-top:10px;
      ${tablet({
        'flex-direction':'column',
      })}; 
      ${mobile({
        'flex-direction':'column',
      })}; 
      .contact{
        border-radius:10px;
        width:50%;
        margin-right:5px;
        padding:10px;
        ${tablet({
        'margin-right':'0px',
        'margin-bottom':'10px',
        width:'100%'

        })}; 
        ${mobile({
          'margin-right':'0px',
          'margin-bottom':'10px',
          width:'100%'
        })};
      }
      .location{
        border-radius:10px;
        width:50%;
        margin-left:5px;
        padding:10px;
        ${tablet({
        'margin-left':'0px',
        width:'100%'
        })}; 
        ${mobile({
          'margin-left':'0px',
          width:'100%'
        })};
      }
    }
    .otherDetails{
      display:flex;
      flex-direction:row;
      width:100%;
      margin-top:10px;
      margin-bottom:10px;

    }

    .headerContainer{
      background-color:#74e2f1;
      width:100%;
      height:${px2vw(400)};
      margin-bottom:10px;
      border-radius:10px;
      padding:10px;
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      ${tablet({
        height:'300px',
        })}; 
        ${mobile({
          height:'210px',
        })};

    }

    
    .name{
      font-size:2rem;
      font-weight:600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;

    }
    .headerItems{
      width:100%;
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      justify-items:baseline;
      align-items:flex-end;
      align-content:flex-end;

        .logo{
          width:200px;
          height:200px;
          background-image:url('https://picsum.photos/200/300');
          background-repeat: no-repeat;
          object-fit:fill;
          background-size: 100% 100%;
          border-radius:10px;
            ${tablet({
              width:'150px',
              height:'150px',
            })}; 
            ${mobile({
              width:'100px',
              height:'100px',
            })};
        }
        .items{
          display:flex;
          flex-direction:column;
          width:100%;
          ${tablet({
              'flex-direction':'column',
            })}; 
            ${mobile({
              'flex-direction':'column',
            })};


            .row1{
              display:flex;
              flex-direction:row;
              width:100%;
              justify-content:space-evenly;

            }
            .row2{
              display:flex;
              flex-direction:row;
              width:100%;
              justify-content:space-evenly;

            }

              
            }

            .itemCard{
              width:${px2vw(120)};
              background-color:#ffffffeb;
              border-radius:10px;
              margin:10px;
              padding:5px;
              text-align:center;
              ${tablet({
                'margin-bottom':'0px',
              })}; 
              ${mobile({
                'margin-bottom':'0px',
              })};


              .icon{
                color:black;
                font-size:3rem;
                ${tablet({
                  'font-size':'3rem',
              
                })}; 
                ${mobile({
                  'font-size':'3rem',
                })};

              }
              

            }
    }
    .accreditationContainer{
      border-radius:20px;
      width:200px;
      height:200px;
      background-color:${kAppBarColor};
      margin:10px;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};

      .grade{
        width:100%;
        height:70%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:600;
        font-size:80px;
        ${tablet({
          'font-size':'60px',
    
        })}; 
        ${mobile({
          'font-size':'40px',

        })};

      }
      .body{
        width:100%;
        height:30%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:500;
        font-size:20px;
        ${tablet({
          'font-size':'15px',
    
        })}; 
        ${mobile({
          'font-size':'10px',

        })};
      }
    }

    .activitiesContainer{
      border-radius:50%;
      width:200px;
      height:200px;
      background-color:#57fa7b;
      margin:10px;
      display:flex;
      align-items:center;
      align-content:center;
      justify-content:center;
      justify-items:center;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};
      .activities{
            display:flex;
            align-self:center;
            text-align:center;
            justify-content:center;
            justify-items:center;
            align-items:center;
            align-content:center;
            font-weight:500;
            font-size:30px;
            ${tablet({
              'font-size':'20px',
        
            })}; 
            ${mobile({
              'font-size':'15px',

            })};
          }
    }

`;

const ItemCard = ({itemName,itemValue,icon})=>{
  var Icons;
  switch(icon){
    case 'area':
      Icons = <SquareFoot className='icon'/>
      break
    case 'women':
      Icons = <Female className='icon'/>
      break
    case 'estd':
      Icons = <Foundation className='icon'/>
      break
      case 'dicipline':
      Icons = <Stream className='icon'/>
      break
      case 'category':
      Icons = <Category className='icon'/>
      break
      case 'code':
      Icons = <Numbers className='icon'/>
      break
      case 'ownership':
      Icons = <VerifiedUser className='icon'/>
      break
      case 'type':
      Icons = <MergeType className='icon'/>
      break
      case 'students':
      Icons = <SupervisedUserCircle className='icon'/>
      break
      case 'faculty':
      Icons = <Badge className='icon'/>
      break
    default :
    return



  }
  if(itemName==='Women'){
    if(itemValue){
      itemValue = 'Yes'
    }else{
      itemValue = 'No'
    }
  }

  return(
    <Card className='itemCard'>
              <p>{itemName}</p>
              {Icons}
              <p>{itemValue}</p>
            </Card>
  )

}



const Activities = ({data})=>{

  return(
    <Card key={data._id} className='activitiesContainer'>
      <div className='activities'>{data}</div>
    </Card>
  )
}


const TopCollegeRanking = ({type,data})=>{
  var color = kAppBarColor;
  if(data===true){
    color = '#69e27d'
  }else color = '#f14c4c'
  

  return(
    <Card key={data._id} className='accreditationContainer' style={{backgroundColor:color}}>
      <div className='grade'>{data===true?"Yes":"No"}</div>
      <div className='body'>{type}</div>
    </Card>
  )
}

const columnNews = [
  { 
    field: 'title',
    headerName: 'Title',
    overflow:'scroll',
    //flex: 3,
    width:500,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'news',
    headerName: 'News',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:800,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },

];
const columnBlogs = [
  { 
    field: 'title',
    headerName: 'Title',
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'news',
    headerName: 'News',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },

];

const columns = [
  { 
    field: 'userName',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'email',
    headerName: 'Email',
    width:350,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'body',
    headerName: 'Review',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },
  {
    field: 'star',
    headerName: 'Rating',
    //flex: 1.3,
    width:250,
    editable: false,
    renderCell:(params)=>{
      return <Rating precision={0.5} value={params.row.star} readOnly />
      
    }
  },
  {
    field: 'created',
    headerName: 'Created On',
    //flex: 1,
    //hide:true,
    width:250,
    editable: false,
  },
  // {
  //   field: 'programs',
  //   headerName: 'Programs',
  //   //flex: 1.5,
  //   width:200,
  //   editable: false,
  //   renderCell: (params)=>{
  //     var type = []
  //     if(params.row.programs.includes('undergraduate')){
  //       type.push("UG")
  //     }
  //     if(params.row.programs.includes('postgraduate')){
  //       type.push("PG")
  //     }
  //     if(params.row.programs.includes('diploma')){
  //       type.push("DIP")
  //     }
  //     if(params.row.programs.includes('doctrate')){
  //       type.push("PHD")
  //     }
  //     return (
  //       <div style={{display:'flex'}}>{type.map(d=>(
  //         <p key={d} style={{
  //           margin:2,
  //           color:d==='UG'
  //           ?'blue':d==='PG'?'green'
  //           :d==='DIP'
  //           ?'orange'
  //           :'red'
  //         }}>{d}</p>
  //       ))}</div>
  //     )

  //   }
  // },
  {
    field: 'edited',
    headerName: 'Modified',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'editDate',
    headerName: 'Modify Date',
    width:250,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'userId',
    headerName: 'UserId',
    width:280,
    //flex: 1.5,
    editable: true,
  },

];

const programmeColumn = [
  { 
    field: 'name',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.name}</p>
      )

    }
  },
  {
    field: 'short',
    headerName: 'Short',
    width:150,
    //flex: 1.5,
    editable: false,
  },
  {
    field: 'startingYear',
    headerName: 'Starting Year',
    //flex: 1,
    //flex: 3,
    type:'number',
    width:150,
    editable: false
  },
  {
    field: 'admissionMode',
    headerName: 'Admission Mode',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
          return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
            {params.row.admissionMode.map(data=>{
              return <div key={data} style={{marginRight:'10px'}}>{data}</div>
          })}</div>
    }
  },
  {
    field: 'eligibility',
    headerName: 'Eligibility',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.eligibility.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'qualification',
    headerName: 'Qualification',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.qualification.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.type} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.type}</p>
      )
    }
  },
  {
    field: 'fee',
    headerName: 'Fee',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:500,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.fee.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  // {
  //   field: 'programs',
  //   headerName: 'Programs',
  //   //flex: 1.5,
  //   width:200,
  //   editable: false,
  //   renderCell: (params)=>{
  //     var type = []
  //     if(params.row.programs.includes('undergraduate')){
  //       type.push("UG")
  //     }
  //     if(params.row.programs.includes('postgraduate')){
  //       type.push("PG")
  //     }
  //     if(params.row.programs.includes('diploma')){
  //       type.push("DIP")
  //     }
  //     if(params.row.programs.includes('doctrate')){
  //       type.push("PHD")
  //     }
  //     return (
  //       <div style={{display:'flex'}}>{type.map(d=>(
  //         <p key={d} style={{
  //           margin:2,
  //           color:d==='UG'
  //           ?'blue':d==='PG'?'green'
  //           :d==='DIP'
  //           ?'orange'
  //           :'red'
  //         }}>{d}</p>
  //       ))}</div>
  //     )

  //   }
  // },
  {
    field: 'id',
    headerName: 'Id',
    width:280,
    //flex: 1.5,
    editable: true,
  },

];
const setProgrammeRows = (colleges,type) => {
  var tempRows = [];
   if(colleges.isSuccess && colleges.data.responseMessage === "SUCCESS" ){
     if(typeof(colleges.data.responseData) === "object"){
    for(const element of colleges.data.responseData.programs.programs){
      if(element.programType === type){
      if(element.program.length){
        for(const program of element.program){
          tempRows.push({
          id:program._id,
          name:program.name,
          short:program.short,
          type:program.type,
          startingYear:program.yearOfStarting,
          qualification:program.qualification,
          fee:program.fee,
          eligibility:program.eligibility,
          admissionMode:program.admissionMode
        })
        }
    }
  }
    }
  }
  }
  return tempRows;
}

const setRows = (colleges) => {
  var tempRows = [];
   if(colleges.isSuccess && colleges.data.responseMessage === "SUCCESS" ){
     if(typeof(colleges.data.responseData) === "object"){
    for(const element of colleges.data.responseData.reviews.reviews){
      if(element.reviewBody){
        tempRows.push({
        id:element._id,
        body:element.reviewBody,
        star:element.reviewStar,
        created:element.createdAt,
        edited:element.isEdited,
        editDate:element.editedDate,
        userName:element.author.name,
        userId:element.author.userId,
        email:element.author.email
      })
    }
    }
  }
  }
  return tempRows;
}


const ExpandableList = ({data,type}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return <>
  <ListItemButton onClick={handleClick} style={{border:'1px solid black',marginBottom:'1px'}}>
    <ListItemText primary={data.title} />
    {open ? <ExpandLess /> : <ExpandMore />}
  </ListItemButton>
  <Collapse in={open} timeout="auto" unmountOnExit style={{border:'0.1px solid grey',backgroundColor:kAppBarColor}}>
  <List component="div" disablePadding>
    <ListItemButton sx={{ pl: 4 }}>
      <ListItemText primary={type==='news'?data.news:data.blog} />
    </ListItemButton>
  </List>
  </Collapse>
</>
}

const SchoolView = (props) => {
  
  const [programmeType,changeType] = useState();
  const location = useLocation();
  const id = location.pathname.substring(14);
  const school = useGetSchoolQuery(id,{refetchOnMountOrArgChange:true});
  console.log(school.data)
  const rows = useMemo(()=>{
    if(school.isSuccess){
      //changeType(school.data.responseData.programs.programs[0].programType);
    return setRows(school);
    }
  },[school]);


//   const programmeRows = useMemo(()=>{
//     return setProgrammeRows(school,programmeType);
//   },[school,programmeType])


  const changeProgramme =(type)=>{
    changeType(type);
  }

  
  return (
    <Container>
      <div  className='main' style={{height:'100%',overflow:'scroll',padding:'10px'}}>
      <Card className='headerContainer'>
        <div className='name' style={{padding:'10px'}}>{school.isSuccess && school.data.responseData.basicDetails.name} </div>
        <div className='headerItems'>
        <div className='logo'/>
        <div className='items'>
          <div className='row1'>
          <ItemCard itemName={'Code'} icon={'code'} itemValue={school.isSuccess && school.data.responseData.basicDetails.code} />
            <ItemCard itemName={'Acre'} icon={'area'} itemValue={school.isSuccess && school.data.responseData.campusArea} />
            <ItemCard itemName={'Estd'} icon={'estd'} itemValue={school.isSuccess && school.data.responseData.basicDetails.estd} />
            <ItemCard itemName={'Board'} icon={'dicipline'} itemValue={school.isSuccess && school.data.responseData.board} />
            <ItemCard itemName={'Stage'} icon={'category'} itemValue={school.isSuccess && school.data.responseData.stage} />
            
            
          </div>
          <div className='row2'>
            <ItemCard itemName={'Ownership'} icon={'ownership'} itemValue={school.isSuccess && school.data.responseData.ownership} />
            <ItemCard itemName={'Type'} icon={'type'} itemValue={school.isSuccess && school.data.responseData.type} />
            <ItemCard itemName={'Students'} icon={'students'} itemValue={school.isSuccess && school.data.responseData.studentStrength} />
            <ItemCard itemName={'Faculty'} icon={'faculty'} itemValue={school.isSuccess && school.data.responseData.facultyStrength} />
            <ItemCard itemName={'Women'} icon={'women'} itemValue={school.isSuccess && school.data.responseData.isWomen} />
          

          </div>
        </div>

        </div>
      </Card>

      

      <Card style={{padding:'10px'}} >
          <h2 className='header'>Description</h2>
              <h4 className='staticData'>{school.isSuccess && school.data.responseData.description}</h4>
      </Card>
      

    
      <h2 className='header' style={{padding:'10px'}}>Top School Ranking</h2>
      <div style={{display:'flex'}}>
        <TopCollegeRanking type={'India'} data={school.isSuccess && school.data.responseData.topSchool}/>
      </div>
      


      <h2 className='header' style={{padding:'10px'}}>User Reviews</h2>
      {(school.isSuccess && !school.data.responseData.reviews.reviews.length)
        ?<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
        <Center>
          <Box>No Reviews</Box>
        </Center>
      </Card>
        :<DataGrid
        autoHeight
        style={{}}
        rows={rows??[]}
        columns={columns}//.concat(actions)}
        pageSize={20}
        rowsPerPageOptions={[20]}
        disableSelectionOnClick
      /> }





      <h2 className='header' style={{padding:'10px'}}>Activities</h2>
      
      {(school.isSuccess && !school.data.responseData.activities.length)
        ?<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
            <Center>
              <Box>No Activities</Box>
            </Center>
          </Card>
        :<ScrollableListView child=
        {school.isSuccess && school.data.responseData.activities.map((item)=>{
          return <Activities data={item}/>
        })}
      />}

    <h2 className='header' style={{padding:'10px'}}>News</h2>
    <List
          sx={{ width: '100%',maxHeight:'500px',overflow:'scroll', bgcolor: 'background.paper',border:'1px solid grey' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              All News ({school.isSuccess && school.data.responseData.news.length})
            </ListSubheader>
          }
        >
          {(school.isSuccess && !school.data.responseData.news.length)
            ?<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
              <Center>
                <Box>No News</Box>
              </Center>
              </Card>
            :<div>
              {school.isSuccess && school.data.responseData.news.map((item)=>{
                  return <ExpandableList data={item} type={'news'} />
                })}
              </div>
          }
     </List>

     <h2 className='header' style={{padding:'10px'}}>Blogs</h2>
    <List
          sx={{ width: '100%',maxHeight:'500px',overflow:'scroll', bgcolor: 'background.paper',border:'1px solid grey' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              All News ({school.isSuccess && school.data.responseData.blog.length})
            </ListSubheader>
          }
        >
          {(school.isSuccess && !school.data.responseData.blog.length)
            ?<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
              <Center>
                <Box>No Blogs</Box>
              </Center>
              </Card>
            :<div>
              {school.isSuccess && school.data.responseData.blog.map((item)=>{
                
                  return <ExpandableList data={item} type={'blog'} />
                })}
              </div>
          }
     </List>

      


        <div className='locationGrid'>
        <Card className='contact'>
          <h2 className='header'>Contact Details:</h2>
              <h4 className='staticData'>Phone: {school.isSuccess && school.data.responseData.contactDetails.email}</h4>
              <h4 className='staticData'>Email: {school.isSuccess && school.data.responseData.contactDetails.phone}</h4>
              <h4 className='staticData'>Website: {school.isSuccess && school.data.responseData.contactDetails.siteUrl}</h4>
        </Card>

        <Card className='location'>
          <h2 className='header'>Location Details:</h2>
              <h4 className='staticData'>Address: {school.isSuccess && school.data.responseData.locationDetails.address}</h4>
              <h4 className='staticData'>Street: {school.isSuccess && school.data.responseData.locationDetails.street}</h4>
              <h4 className='staticData'>City: {school.isSuccess && school.data.responseData.locationDetails.city}</h4>
              <h4 className='staticData'>District: {school.isSuccess && school.data.responseData.locationDetails.district}</h4>
              <h4 className='staticData'>State: {school.isSuccess && school.data.responseData.locationDetails.state}</h4>
        </Card>
        </div>
        </div>
      </Container>
  )
}

export default SchoolView