import { SquareFoot,Foundation,Female,Stream,Badge,Category,SupervisedUserCircle,Numbers,VerifiedUser,MergeType, ArrowCircleLeft, ArrowCircleRight, StarBorder, Inbox, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Card, Collapse, Divider, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Rating,Avatar } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components';
import { kAppBarColor, kBackgroundDark } from '../../../const/const';
import { useGetCollegeQuery, useGetExamQuery, useGetexamQuery, useGetUserQuery } from '../../../redux_store/service/fetchData';
import { mobile, tablet } from '../../../utils/responsive';
import px2vw from '../../../utils/px2v'
import {ScrollableListView} from '../../../styles/ScrollableListView'
import gsap from 'gsap';
import { DataGrid } from '@mui/x-data-grid';
import Exam from '../../pages/exam/Exam';
import { getDate, getTime } from '../../../utils/getDateTIme';
import { Link } from 'react-router-dom';



const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  align-content:center;
  justify-items:center;
  display:flex;
  justify-content:center;
  text-align:center;
`;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;


    .basicDetails{
      display:flex;
      position:relative;
      width:100%;
      height:500px;
      ${tablet({
        flexDirection:'column',
        })};
      ${mobile({
      flexDirection:'column',
      })};

      .profileContainer{
        width:40%;
        height:100%;
        ${tablet({
        flexDirection:'column',
        width:'100%',
        })};
        ${mobile({
        flexDirection:'column',
        width:'100%',
        })};

        .profilePic{
          width:300px;
          height:300px;
          border:10px solid #afafaf;

        }
        
      }
      .adminType{
          position:absolute;
          top:10px;
          right:10px;

        }
      .detailsContainer{
        width:60%;
        height:100%;
        padding-left:100px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        ${tablet({
        flexDirection:'column',
        'align-items':'center',
        'padding-left':'0',
        width:'100%',
        })};
        ${mobile({
        flexDirection:'column',
        'align-items':'center',
        'padding-left':'0',
        width:'100%',
        })};
          .name{
            font-weight:600;
            font-size:2.5rem;
            margin-bottom:20px;
          }
          .otherDetails{
            font-weight:500;
            font-size:1.2rem;
            

          }


      }

    }
    

`;




const Activities = ({data})=>{

  return(
    <Card key={data._id} className='activitiesContainer'>
      <div className='activities'>{data}</div>
    </Card>
  )
}


const TopCollegeRanking = ({type,data})=>{
  var color = kAppBarColor;
  if(data===true){
    color = '#69e27d'
  }else color = '#f14c4c'
  

  return(
    <Card key={data._id} className='accreditationContainer' style={{backgroundColor:color}}>
      <div className='grade'>{data===true?"Yes":"No"}</div>
      <div className='body'>{type}</div>
    </Card>
  )
}


const columns = [
  { 
    field: 'userName',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'email',
    headerName: 'Email',
    width:350,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'body',
    headerName: 'Review',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },
  {
    field: 'star',
    headerName: 'Rating',
    //flex: 1.3,
    width:250,
    editable: false,
    renderCell:(params)=>{
      return <Rating precision={0.5} value={params.row.star} readOnly />
      
    }
  },
  {
    field: 'created',
    headerName: 'Created On',
    //flex: 1,
    //hide:true,
    width:250,
    editable: false,
  },
  {
    field: 'edited',
    headerName: 'Modified',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'editDate',
    headerName: 'Modify Date',
    width:250,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'userId',
    headerName: 'UserId',
    width:280,
    //flex: 1.5,
    editable: true,
  },

];

const programmeColumn = [
  { 
    field: 'name',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.name}</p>
      )

    }
  },
  {
    field: 'short',
    headerName: 'Short',
    width:150,
    //flex: 1.5,
    editable: false,
  },
  {
    field: 'startingYear',
    headerName: 'Starting Year',
    //flex: 1,
    //flex: 3,
    type:'number',
    width:150,
    editable: false
  },
  {
    field: 'admissionMode',
    headerName: 'Admission Mode',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
          return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
            {params.row.admissionMode.map(data=>{
              return <div key={data} style={{marginRight:'10px'}}>{data}</div>
          })}</div>
    }
  },
  {
    field: 'eligibility',
    headerName: 'Eligibility',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.eligibility.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'qualification',
    headerName: 'Qualification',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.qualification.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.type} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.type}</p>
      )
    }
  },
  {
    field: 'fee',
    headerName: 'Fee',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:500,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.fee.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'id',
    headerName: 'Id',
    width:280,
    //flex: 1.5,
    editable: true,
  },

];
const setProgrammeRows = (colleges,type) => {
  var tempRows = [];
   if(colleges.isSuccess && colleges.data.responseMessage === "SUCCESS" ){
     if(typeof(colleges.data.responseData) === "object"){
    for(const element of colleges.data.responseData.programs.programs){
      if(element.programType === type){
      if(element.program.length){
        for(const program of element.program){
          tempRows.push({
          id:program._id,
          name:program.name,
          short:program.short,
          type:program.type,
          startingYear:program.yearOfStarting,
          qualification:program.qualification,
          fee:program.fee,
          eligibility:program.eligibility,
          admissionMode:program.admissionMode
        })
        }
    }
  }
    }
  }
  }
  return tempRows;
}



const ExpandableList = ({data,index}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return <>
  <ListItemButton onClick={handleClick} style={{backgroundColor:kAppBarColor,marginLeft:'5px',marginRight:'5px',borderRadius:'5px'}}>
    <ListItemText primary={index+1+'. '+data.question} />
    {open ? <ExpandLess /> : <ExpandMore />}
  </ListItemButton>
  <Collapse in={open} timeout="auto" unmountOnExit style={{marginLeft:'5px',marginRight:'5px',backgroundColor:'#74c7ee',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}>
  <List component="div" disablePadding>
      <ListItemText primary={'A: '+data.options[0].a} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='a'?'#5df369':null,borderRadius:'10px'}}/>
      <ListItemText primary={'B: '+data.options[0].b} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='b'?'#5df369':null,borderRadius:'10px'}}/>
      <ListItemText primary={'C: '+data.options[0].c} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='c'?'#5df369':null,borderRadius:'10px'}}/>
      <ListItemText primary={'D: '+data.options[0].d} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='d'?'#5df369':null,borderRadius:'10px'}}/>
  </List>
  </Collapse>
  <Divider style={{margin:'10px'}}/>
</>
}


const Profile = (props) => {
  
  const [programmeType,changeType] = useState();
  const location = useLocation();
  const id = location.pathname.substring(props.props==='admin'?24:18);
  const user = useGetUserQuery(id,{refetchOnMountOrArgChange:true});
  console.log(user.data)


//   const programmeRows = useMemo(()=>{
//     return setProgrammeRows(exam,programmeType);
//   },[exam,programmeType])


  const changeProgramme =(type)=>{
    changeType(type);
  }

  
  return (
    <Container>
      <div  className='main' style={{height:'100%',overflow:'scroll',padding:'10px'}}>
        <Card className='basicDetails'>
          <div className='profileContainer'>
          <Center>
            <Avatar className='profilePic' alt="userProfile" src="https://picsum.photos/200/300" />
          </Center>
          </div>
          <Button className='adminType' style={{display:user.isSuccess&&user.data.responseData.isAdmin?'block':'none'}} variant="outlined">Admin: {user.isSuccess&&user.data.responseData.adminType}</Button>

          
          <div className='detailsContainer'>
            <div className="name">{user.isSuccess&&user.data.responseData.fullName}</div>
            <div className="otherDetails">Email: {user.isSuccess&&user.data.responseData.username}</div>
            <div className="otherDetails">City: {user.isSuccess&&user.data.responseData.locationDetails.city}</div>
            <div className="otherDetails">Addrress: {user.isSuccess&&user.data.responseData.locationDetails.address}</div>
            <div className="otherDetails">Street: {user.isSuccess&&user.data.responseData.locationDetails.street}</div>
            <div className="otherDetails">District: {user.isSuccess&&user.data.responseData.locationDetails.district}</div>
            <div className="otherDetails">State: {user.isSuccess&&user.data.responseData.locationDetails.state}</div>
            <div className="otherDetails">Zip: {user.isSuccess&&user.data.responseData.locationDetails.zipCode}</div>

          </div>
          

        </Card>
      

      </div>

      </Container>
  )
}

export default Profile