import React from 'react'
import styled from 'styled-components'
import { kPrimaryColor, kPrimaryLight, kSecondary } from '../const/const';
import px2vw from '../utils/px2v';

const PrimaryButtonComponent = styled.button`
  appearance: none;
  backface-visibility: hidden;
  background-color: ${param=>param.backgroundColor?param.backgroundColor:kSecondary};
  border-radius: ${param=>param.borderRadius?param.borderRadius:10}px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: ${param=>param.fontSize?param.fontSize:'0.8rem'};
  font-weight: ${param=>param.fontWeight?param.fontWeight:600};
  height: ${param=>param.height?param.height:px2vw(30)};
  width: ${param=>param.width?param.width:px2vw(60)};
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  align-items:center;
  align-content:center;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  padding-left:${param=>param.paddingLeft?param.paddingLeft:px2vw(10)};
  padding-right:${param=>param.paddingRight?param.paddingRight:px2vw(10)};
  :hover {
  background-color:${param=>param.hoverColor?param.hoverColor:kPrimaryColor};
  box-shadow: rgba(0, 0, 0, .05) 0 ${px2vw(5)} ${px2vw(30)}, rgba(0, 0, 0, .05) 0 ${px2vw(1)} ${px2vw(4)};
  opacity: 0.8;
  transform: translateY(0);
  transition-duration: .35s;
};
:hover:after {
  opacity: .5;
};
:active {
  box-shadow: rgba(0, 0, 0, .1) 0 ${px2vw(3)} ${px2vw(6)} 0, rgba(0, 0, 0, .1) 0 0 ${px2vw(10)} 0, rgba(0, 0, 0, .1) 0 ${px2vw(1)} ${px2vw(4)} -${px2vw(1)};
  transform: translateY(${px2vw(2)});
  transition-duration: .35s;
};
:active:after {
  opacity: 1;
}
    
`;






export{PrimaryButtonComponent}