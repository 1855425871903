import { MdAdminPanelSettings, MdDashboard, MdEditNote, MdLogout, MdMail, MdQuiz, MdSchool, MdSettings } from "react-icons/md";
import { FaSchool } from "react-icons/fa";
import { BsClipboardData } from "react-icons/bs";
import { MdDocumentScanner } from "react-icons/md";
import { FiUsers } from "react-icons/fi";

const iconSize = '50px';
export const DashboardMenuItemsData = [
    {
        icon:<MdDashboard />,
        title:"Dashboard",
        onclick:'/',
    },
    {
        icon:<MdSchool />,
        title:"Colleges",
        onclick:'/colleges',
    },
    {
        icon:<FaSchool />,
        title:"Schools",
        onclick:'/schools',
    },
    {
        icon:<MdEditNote />,
        title:"Exams",
        onclick:'/exam',
    },
    // {
    //     icon:<MdQuiz />,
    //     title:"Mock Test",
    //     onclick:'/mock-test',
    // },
    {
        icon:<BsClipboardData />,
        title:"Static Datas",
        onclick:'/static-data',
    },
    {
        icon:<MdMail />,
        title:"Mails",
        onclick:'/mail',
    },
    {
        icon:<FiUsers />,
        title:"Manage Users",
        onclick:'/manage-user',
    },
    {
        icon:<MdDocumentScanner />,
        title:"Documentation",
        onclick:'/documentation',
    },
    {
        icon:<MdAdminPanelSettings />,
        title:"Manage Admin Users",
        onclick:'/manage-admin-user',
    },
    {
        icon:<MdSettings />,
        title:"Settings",
        onclick:'/settings',
    },
    {
        icon:<MdLogout />,
        title:"Logout",
        onclick:'logout',
    }
]
