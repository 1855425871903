import { SquareFoot,Foundation,Female,Stream,Badge,Category,SupervisedUserCircle,Numbers,VerifiedUser,MergeType, ArrowCircleLeft, ArrowCircleRight, StarBorder, Inbox, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Card, Collapse, Divider, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Rating } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components';
import { kAppBarColor, kBackgroundDark } from '../../const/const';
import { useGetCollegeQuery, useGetExamQuery, useGetexamQuery } from '../../redux_store/service/fetchData';
import { mobile, tablet } from '../../utils/responsive';
import px2vw from '../../utils/px2v'
import {ScrollableListView} from '../../styles/ScrollableListView'
import gsap from 'gsap';
import { DataGrid } from '@mui/x-data-grid';
import Exam from '../pages/exam/Exam';
import { getDate, getTime } from '../../utils/getDateTIme';
import { Link } from 'react-router-dom';



const Center = styled.div`
  width:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;

    
    .staticData{
      margin-bottom:10px;
      padding:2px;
      padding-left:10px;
      font-size:20px;
      ${tablet({
          'font-size':'2rem'
        })}; 
        ${mobile({
          width:'100%'
        })};

    }
    .header{
      font-size:30px;
      margin-bottom:15px;
    }

    .locationGrid{
      display:flex;
      flex-direction:row;
      width:100%;
      margin-top:10px;
      ${tablet({
        'flex-direction':'column',
      })}; 
      ${mobile({
        'flex-direction':'column',
      })}; 
      .contact{
        border-radius:10px;
        width:50%;
        margin-right:5px;
        padding:10px;
        ${tablet({
        'margin-right':'0px',
        'margin-bottom':'10px',
        width:'100%'

        })}; 
        ${mobile({
          'margin-right':'0px',
          'margin-bottom':'10px',
          width:'100%'
        })};
      }
      .location{
        border-radius:10px;
        width:50%;
        margin-left:5px;
        padding:10px;
        ${tablet({
        'margin-left':'0px',
        width:'100%'
        })}; 
        ${mobile({
          'margin-left':'0px',
          width:'100%'
        })};
      }
    }
    .otherDetails{
      display:flex;
      flex-direction:row;
      width:100%;
      margin-top:10px;
      margin-bottom:10px;

    }

    .headerContainer{
      background-color:#74e2f1;
      width:100%;
      height:${px2vw(400)};
      margin-bottom:10px;
      border-radius:10px;
      padding:10px;
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      ${tablet({
        height:'300px',
        })}; 
        ${mobile({
          height:'210px',
        })};

    }

    
    .name{
      font-size:2rem;
      font-weight:600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;

    }
    .headerItems{
      width:100%;
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      justify-items:baseline;
      align-items:flex-end;
      align-content:flex-end;

        .logo{
          width:200px;
          height:200px;
          background-image:url('https://picsum.photos/200/300');
          background-repeat: no-repeat;
          object-fit:fill;
          background-size: 100% 100%;
          border-radius:10px;
            ${tablet({
              width:'150px',
              height:'150px',
            })}; 
            ${mobile({
              width:'100px',
              height:'100px',
            })};
        }
        .items{
          display:flex;
          flex-direction:column;
          width:100%;
          ${tablet({
              'flex-direction':'column',
            })}; 
            ${mobile({
              'flex-direction':'column',
            })};


            .row1{
              display:flex;
              flex-direction:row;
              width:100%;
              justify-content:space-evenly;

            }
            .row2{
              display:flex;
              flex-direction:row;
              width:100%;
              justify-content:space-evenly;

            }

              
            }

            .itemCard{
              width:${px2vw(120)};
              background-color:#ffffffeb;
              border-radius:10px;
              margin:10px;
              padding:5px;
              text-align:center;
              ${tablet({
                'margin-bottom':'0px',
              })}; 
              ${mobile({
                'margin-bottom':'0px',
              })};


              .icon{
                color:black;
                font-size:3rem;
                ${tablet({
                  'font-size':'3rem',
              
                })}; 
                ${mobile({
                  'font-size':'3rem',
                })};

              }
              

            }
    }
    .accreditationContainer{
      border-radius:20px;
      width:200px;
      height:200px;
      background-color:${kAppBarColor};
      margin:10px;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};

      .grade{
        width:100%;
        height:70%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:600;
        font-size:80px;
        ${tablet({
          'font-size':'60px',
    
        })}; 
        ${mobile({
          'font-size':'40px',

        })};

      }
      .body{
        width:100%;
        height:30%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:500;
        font-size:20px;
        ${tablet({
          'font-size':'15px',
    
        })}; 
        ${mobile({
          'font-size':'10px',

        })};
      }
    }

    .activitiesContainer{
      border-radius:50%;
      width:200px;
      height:200px;
      background-color:#57fa7b;
      margin:10px;
      display:flex;
      align-items:center;
      align-content:center;
      justify-content:center;
      justify-items:center;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};
      .activities{
            display:flex;
            align-self:center;
            text-align:center;
            justify-content:center;
            justify-items:center;
            align-items:center;
            align-content:center;
            font-weight:500;
            font-size:30px;
            ${tablet({
              'font-size':'20px',
        
            })}; 
            ${mobile({
              'font-size':'15px',

            })};
          }
    }

`;

const ItemCard = ({itemName,itemValue,icon})=>{
  var Icons;
  switch(icon){
    case 'area':
      Icons = <SquareFoot className='icon'/>
      break
    case 'women':
      Icons = <Female className='icon'/>
      break
    case 'estd':
      Icons = <Foundation className='icon'/>
      break
      case 'dicipline':
      Icons = <Stream className='icon'/>
      break
      case 'category':
      Icons = <Category className='icon'/>
      break
      case 'code':
      Icons = <Numbers className='icon'/>
      break
      case 'ownership':
      Icons = <VerifiedUser className='icon'/>
      break
      case 'type':
      Icons = <MergeType className='icon'/>
      break
      case 'students':
      Icons = <SupervisedUserCircle className='icon'/>
      break
      case 'faculty':
      Icons = <Badge className='icon'/>
      break
    default :
    return



  }
  if(itemName==='Women'){
    if(itemValue){
      itemValue = 'Yes'
    }else{
      itemValue = 'No'
    }
  }

  return(
    <Card className='itemCard'>
              <p>{itemName}</p>
              {Icons}
              <p>{itemValue}</p>
            </Card>
  )

}



const Activities = ({data})=>{

  return(
    <Card key={data._id} className='activitiesContainer'>
      <div className='activities'>{data}</div>
    </Card>
  )
}


const TopCollegeRanking = ({type,data})=>{
  var color = kAppBarColor;
  if(data===true){
    color = '#69e27d'
  }else color = '#f14c4c'
  

  return(
    <Card key={data._id} className='accreditationContainer' style={{backgroundColor:color}}>
      <div className='grade'>{data===true?"Yes":"No"}</div>
      <div className='body'>{type}</div>
    </Card>
  )
}

const columnNews = [
  { 
    field: 'title',
    headerName: 'Title',
    overflow:'scroll',
    //flex: 3,
    width:500,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'news',
    headerName: 'News',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:800,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },

];
const columnBlogs = [
  { 
    field: 'title',
    headerName: 'Title',
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'news',
    headerName: 'News',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },

];

const columns = [
  { 
    field: 'userName',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.userName}</p>
      )

    }
  },
  {
    field: 'email',
    headerName: 'Email',
    width:350,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'body',
    headerName: 'Review',
    //flex: 1,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.body}</p>
      )
    }
  },
  {
    field: 'star',
    headerName: 'Rating',
    //flex: 1.3,
    width:250,
    editable: false,
    renderCell:(params)=>{
      return <Rating precision={0.5} value={params.row.star} readOnly />
      
    }
  },
  {
    field: 'created',
    headerName: 'Created On',
    //flex: 1,
    //hide:true,
    width:250,
    editable: false,
  },
  // {
  //   field: 'programs',
  //   headerName: 'Programs',
  //   //flex: 1.5,
  //   width:200,
  //   editable: false,
  //   renderCell: (params)=>{
  //     var type = []
  //     if(params.row.programs.includes('undergraduate')){
  //       type.push("UG")
  //     }
  //     if(params.row.programs.includes('postgraduate')){
  //       type.push("PG")
  //     }
  //     if(params.row.programs.includes('diploma')){
  //       type.push("DIP")
  //     }
  //     if(params.row.programs.includes('doctrate')){
  //       type.push("PHD")
  //     }
  //     return (
  //       <div style={{display:'flex'}}>{type.map(d=>(
  //         <p key={d} style={{
  //           margin:2,
  //           color:d==='UG'
  //           ?'blue':d==='PG'?'green'
  //           :d==='DIP'
  //           ?'orange'
  //           :'red'
  //         }}>{d}</p>
  //       ))}</div>
  //     )

  //   }
  // },
  {
    field: 'edited',
    headerName: 'Modified',
    width:150,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'editDate',
    headerName: 'Modify Date',
    width:250,
    //flex: 1.5,
    editable: true,
  },
  {
    field: 'userId',
    headerName: 'UserId',
    width:280,
    //flex: 1.5,
    editable: true,
  },

];

const programmeColumn = [
  { 
    field: 'name',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.name}</p>
      )

    }
  },
  {
    field: 'short',
    headerName: 'Short',
    width:150,
    //flex: 1.5,
    editable: false,
  },
  {
    field: 'startingYear',
    headerName: 'Starting Year',
    //flex: 1,
    //flex: 3,
    type:'number',
    width:150,
    editable: false
  },
  {
    field: 'admissionMode',
    headerName: 'Admission Mode',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
          return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
            {params.row.admissionMode.map(data=>{
              return <div key={data} style={{marginRight:'10px'}}>{data}</div>
          })}</div>
    }
  },
  {
    field: 'eligibility',
    headerName: 'Eligibility',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.eligibility.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'qualification',
    headerName: 'Qualification',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.qualification.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.type} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.type}</p>
      )
    }
  },
  {
    field: 'fee',
    headerName: 'Fee',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:500,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.fee.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  // {
  //   field: 'programs',
  //   headerName: 'Programs',
  //   //flex: 1.5,
  //   width:200,
  //   editable: false,
  //   renderCell: (params)=>{
  //     var type = []
  //     if(params.row.programs.includes('undergraduate')){
  //       type.push("UG")
  //     }
  //     if(params.row.programs.includes('postgraduate')){
  //       type.push("PG")
  //     }
  //     if(params.row.programs.includes('diploma')){
  //       type.push("DIP")
  //     }
  //     if(params.row.programs.includes('doctrate')){
  //       type.push("PHD")
  //     }
  //     return (
  //       <div style={{display:'flex'}}>{type.map(d=>(
  //         <p key={d} style={{
  //           margin:2,
  //           color:d==='UG'
  //           ?'blue':d==='PG'?'green'
  //           :d==='DIP'
  //           ?'orange'
  //           :'red'
  //         }}>{d}</p>
  //       ))}</div>
  //     )

  //   }
  // },
  {
    field: 'id',
    headerName: 'Id',
    width:280,
    //flex: 1.5,
    editable: true,
  },

];
const setProgrammeRows = (colleges,type) => {
  var tempRows = [];
   if(colleges.isSuccess && colleges.data.responseMessage === "SUCCESS" ){
     if(typeof(colleges.data.responseData) === "object"){
    for(const element of colleges.data.responseData.programs.programs){
      if(element.programType === type){
      if(element.program.length){
        for(const program of element.program){
          tempRows.push({
          id:program._id,
          name:program.name,
          short:program.short,
          type:program.type,
          startingYear:program.yearOfStarting,
          qualification:program.qualification,
          fee:program.fee,
          eligibility:program.eligibility,
          admissionMode:program.admissionMode
        })
        }
    }
  }
    }
  }
  }
  return tempRows;
}



const ExpandableList = ({data,index}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return <>
  <ListItemButton onClick={handleClick} style={{backgroundColor:kAppBarColor,marginLeft:'5px',marginRight:'5px',borderRadius:'5px'}}>
    <ListItemText primary={index+1+'. '+data.question} />
    {open ? <ExpandLess /> : <ExpandMore />}
  </ListItemButton>
  <Collapse in={open} timeout="auto" unmountOnExit style={{marginLeft:'5px',marginRight:'5px',backgroundColor:'#74c7ee',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}>
  <List component="div" disablePadding>
      <ListItemText primary={'A: '+data.options[0].a} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='a'?'#5df369':null,borderRadius:'10px'}}/>
      <ListItemText primary={'B: '+data.options[0].b} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='b'?'#5df369':null,borderRadius:'10px'}}/>
      <ListItemText primary={'C: '+data.options[0].c} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='c'?'#5df369':null,borderRadius:'10px'}}/>
      <ListItemText primary={'D: '+data.options[0].d} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='d'?'#5df369':null,borderRadius:'10px'}}/>
  </List>
  </Collapse>
  <Divider style={{margin:'10px'}}/>
</>
}


const ExamView = (props) => {
  
  const [programmeType,changeType] = useState();
  const location = useLocation();
  const id = location.pathname.substring(11);
  const exam = useGetExamQuery(id,{refetchOnMountOrArgChange:true});


//   const programmeRows = useMemo(()=>{
//     return setProgrammeRows(exam,programmeType);
//   },[exam,programmeType])


  const changeProgramme =(type)=>{
    changeType(type);
  }

  
  return (
    <Container>
      <div  className='main' style={{height:'100%',overflow:'scroll',padding:'10px'}}>
      <Card className='headerContainer'>
        <div className='name' style={{padding:'10px'}}>{exam.isSuccess && (exam.data.responseData.name+`(${exam.data.responseData.short})`)} </div>
        <div className='headerItems'>
        <div className='logo'/>
        <div className='items'>
          <div className='row1'>
            <ItemCard itemName={'Streams'} icon={'code'} itemValue={exam.isSuccess && exam.data.responseData.totalStreams} />
            <ItemCard itemName={'Level'} icon={'code'} itemValue={exam.isSuccess && exam.data.responseData.level} />
            <ItemCard itemName={'Total Marks'} icon={'area'} itemValue={exam.isSuccess && exam.data.responseData.totalMarks} />
            <ItemCard itemName={'Total Time'} icon={'estd'} itemValue={exam.isSuccess && exam.data.responseData.totalTime} />
            
            
          </div>
          <div className='row2'>
            <ItemCard itemName={'Year'} icon={'type'} itemValue={exam.isSuccess && exam.data.responseData.year} />
            <ItemCard itemName={'Top Exam'} icon={'type'} itemValue={exam.isSuccess && exam.data.responseData.topExam?'Yes':'No'} />
            <ItemCard itemName={'Age Limit'} icon={'students'} itemValue={exam.isSuccess && exam.data.responseData.ageLimit} />
            <ItemCard itemName={'Slots'} icon={'faculty'} itemValue={exam.isSuccess && exam.data.responseData.totalSlots} />
          

          </div>
        </div>

        </div>
      </Card>

      <Card className='contact' style={{padding:'10px',marginBlock:'10px'}}>
          <h2 className='header'>Other Details:</h2>
              <h4 className='staticData'>Cunducted By: {exam.isSuccess && exam.data.responseData.conductedBy}</h4>
              <h4 className='staticData'>Admission Level: {exam.isSuccess && exam.data.responseData.admissionLevel}</h4>
              <h4 className='staticData'>Eligibility: {exam.isSuccess && exam.data.responseData.eligibity}</h4>
              <h4 className='staticData'>Admission Date: {exam.isSuccess && getDate(exam.data.responseData.admissionDate)}</h4>
        </Card>

      

      <Card style={{padding:'10px',marginBlock:'10px'}} >
          <h2 className='header'>Modes</h2>
            <List component="nav">
                {exam.isSuccess && exam.data.responseData.mode.map((item)=>{
                    return <ListItemText primary={item} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}}/>
                            
                    
                })}
            </List>
      </Card>

      <div className='locationGrid'>
      {(exam.isSuccess && exam.data.responseData.mode.includes('online')) &&
        <Card style={{padding:'10px',marginBlock:'10px'}} className='contact' >
        <h2 className='header'>Online Mode Cities{` (${exam.data.responseData.onlineModeCities.length})`}</h2>
          <List component="nav" style={{maxHeight:'500px',overflow:'scroll'}}>
              {exam.isSuccess && exam.data.responseData.onlineModeCities.map((item)=>{
                  return <ListItemText primary={item.city} secondary={item.zip} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}}  />
              })}
          </List>
    </Card>
      }

    {(exam.isSuccess && exam.data.responseData.mode.includes('offline')) &&
        <Card style={{padding:'10px',marginBlock:'10px'}} className='location' >
        <h2 className='header'>Offline Mode Cities{` (${exam.data.responseData.offlineModeCities.length})`}</h2>
          <List component="nav" style={{maxHeight:'500px',overflow:'scroll'}}>
              {exam.isSuccess && exam.data.responseData.offlineModeCities.map((item)=>{
                  return <ListItemText primary={item.city} secondary={item.zip} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} />
              })}
          </List>
    </Card>
      }
      </div>


      <div className='locationGrid'>
        {(exam.isSuccess && exam.data.responseData.slotData)&&
            <Card style={{padding:'10px',marginBlock:'10px'}} className='contact' >
        <h2 className='header'>Slots{` (${exam.data.responseData.totalSlots})`}</h2>
          <List component="nav" style={{maxHeight:'500px',overflow:'scroll'}}>
              {exam.isSuccess && exam.data.responseData.slotData.map((item)=>{
                  return <ListItemText primary={'Slot '+item.slotNumber} secondary={
                    <List component="nav">
                        <ListItemText secondary={'Start Date Time: '+getDate(item.startDate) + ' at '+ getTime(item.startDate)}/>
                        <ListItemText secondary={'End Date Time: '+getDate(item.endDate) + ' at '+ getTime(item.endDate)}/>
                    </List>
                  } style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}}  />
              })}
          </List>
        </Card>}
        
        {(exam.isSuccess && exam.data.responseData.streamData)&&
            <Card style={{padding:'10px',marginBlock:'10px'}} className='location' >
        <h2 className='header'>Streams{` (${exam.data.responseData.totalStreams})`}</h2>
          <List component="nav" style={{maxHeight:'500px',overflow:'scroll'}}>
              {exam.isSuccess && exam.data.responseData.streamData.map((item)=>{
                  return <ListItemText primary={'Stream '+item.streamNumber} secondary={
                    <>
                        <List component="nav" style={{backgroundColor:'#54ee40',padding:'10px',borderRadius:'10px',marginBottom:'10px'}}>
                            <ListItemText primary={'Eligibility'}/>
                            {item.eligibity.map(sub=>{
                                return <ListItemText secondaryTypographyProps={{color:'black'}} secondary={sub.subject+` (${sub.marks})`}/>
                            })}
                        </List>
                        <List component="nav" style={{backgroundColor:'#408bee',padding:'10px',borderRadius:'10px'}}>
                            <ListItemText primary={'Subjects'}/>
                            {item.subjects.map(sub=>{
                                return <List component="nav" style={{backgroundColor:'#7eadeb',padding:'10px',borderRadius:'10px',marginBottom:'10px'}}>
                                            <ListItemText secondaryTypographyProps={{color:'black'}} secondary={sub.subject}/>
                                            <ListItemText secondaryTypographyProps={{color:'black'}} secondary={`Questions: ${sub.noOfQuestion}`}/>
                                            <ListItemText secondaryTypographyProps={{color:'black'}} secondary={`Marks: ${sub.marksPerSubject}`}/>
                                        </List>
                            })}
                        </List>
                    </>
                  } style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} />
              })}
          </List>
        </Card>}
      </div>

      
      

    
      {/* <h2 className='header' style={{padding:'10px'}}>Top exam Ranking</h2>
      <div style={{display:'flex'}}>
        <TopCollegeRanking type={'India'} data={exam.isSuccess && exam.data.responseData.topexam}/>
      </div> */}


      <Card style={{padding:'10px',marginBlock:'10px'}} >
          <h2 className='header'>Result Data</h2>
            <List component="nav">
                {exam.isSuccess && exam.data.responseData.resultData.map((item)=>{
                    return <ListItemText primary={'Slot '+item.slotNumber} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} secondary={
                        <>
                            <ListItemText secondary={'Result Date: '+getDate(item.resultDate)}/>
                            <ListItemText secondary={<p>Link: <a rel="noopener noreferrer" target='_blank' href={item.link}>{item.link}</a></p>}/>
                        </>
                        
                    }/>
                            
                    
                })}
            </List>
      </Card>
      


      






     <h2 className='header' style={{padding:'10px'}}>Test Data</h2>
    <List
          sx={{ width: '100%', bgcolor: 'background.paper',border:'1px solid grey' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              All Quiz Data ({exam.isSuccess && exam.data.responseData.testData.testData.length})
            </ListSubheader>
          }
        >
          {(exam.isSuccess && !exam.data.responseData.testData.testData.length)
            ?<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
              <Center>
                <Box>No Test Data</Box>
              </Center>
              </Card>
            :<div>
              {exam.isSuccess && exam.data.responseData.testData.testData.map((test)=>{
                
                  return <List
                        sx={{ width: '100%',maxHeight:'500px',overflow:'scroll', bgcolor: 'background.paper',border:'1px solid grey' }}
                        component="nav"
                        subheader={
                            <ListSubheader component="div">
                            {test.subject+` (${test.questions.length})`}
                            </ListSubheader>
                        }
                        >
                            {test.questions.map((questions,index)=><ExpandableList data={questions} index={index}/>)}

                        </List>
                })}
              </div>
          }
     </List>

      


        
        </div>
      </Container>
  )
}

export default ExamView