import React from 'react'
import styled from 'styled-components'
import { kAppBarColor, kAppBarDark, kBackgroundColor } from '../../../const/const'
import DashboardLayout from '../DashboardLayout'
import { desktop, mobile, tablet } from '../../../utils/responsive';


import {Avatar, Badge, Box, Button, ButtonBase, Card,CardActionArea,CardContent,CircularProgress,Grid,IconButton,Paper,Stack,Tooltip,Typography} from '@mui/material';
import { useSelector } from 'react-redux';
import { AdminPanelSettings, Backpack, BugReport, Email, HistoryEdu, Person, Quiz, School } from '@mui/icons-material';
import { height } from '@mui/system';
import px2vw from '../../../utils/px2v';
import { useGetMainDataCountQuery } from '../../../redux_store/service/fetchData';
import { Link } from 'react-router-dom';


const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{
      .userGrid{
        justify-items:center;
        padding:20px;
        width:100%;
        height:55vh;
        min-height:500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        ${mobile({
        'grid-template-columns': 'repeat(1, 1fr)',
        'grid-template-rows': 'repeat(2, 1fr)',
        'grid-row-gap': '10px',
        })};
        .dataInnerGrid{
          width:100%;
          height:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;

        }


        .userInnerGrid{
          width:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
        }
      }
    }
    
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;


const QuickAccess = styled(Box)`
  background-color:${kAppBarColor};
  border-radius:5px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  padding:10px;
`;

const MainDataGrid = styled(Grid)`
  justify-items:center;
  padding:20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;

  ${mobile({
    'grid-template-columns': 'repeat(2, 1fr)',
    'grid-template-rows': '2fr',
    'grid-row-gap': '10px',
    })};

`






const MainDataWidget = (datas,type) => {
  var data = {icon:null,name:String,count:String,onClick:String};
  datas = datas.data.responseData;


  function getCount(t){
    return datas.map(d=>{
      if(d.type===t){
        return d.count
      }
      return 0
    });
  }


  switch(type){
    case "school":
      data.icon = <Backpack style={{fontSize:'4rem',color:'green'}}/>;
      data.name = "School"
      data.count = datas[0].count
      data.onClick = '/schools'
      break;
    case "college":
      data.icon = <School style={{fontSize:'4rem',color:'blue'}}/>;
      data.name = "College"
      data.count = datas[1].count
      data.onClick = '/colleges'
      break;
    case "exam":
      data.icon = <HistoryEdu style={{fontSize:'4rem',color:'orange'}}/>;
      data.name = "Exam"
      data.count = datas[2].count
      data.onClick = '/exam'
      break;
    case "test":
      data.icon = <Quiz style={{fontSize:'4rem',color:'red'}}/>;
      data.name = "Test"
      data.count = datas[3].count
      // data.onClick = '/mock-test'
      data.onClick = '/exam'
      break;

    case "user":
      data.icon = <Person style={{fontSize:'4rem',color:'purple'}}/>;
      data.name = "Users"
      data.count = datas[5].count
      data.onClick = '/manage-user'
      break;

    case "admin":
      data.icon = <AdminPanelSettings style={{fontSize:'4rem',color:'teal'}}/>;
      data.name = "Admin Users"
      data.count = datas[6].count
      data.onClick = '/manage-admin-user'
      break;

    case "totalData":
      var totalData = datas[0].count+datas[1].count+datas[2].count+datas[3].count;
      var school = (datas[0].count*100)/totalData;
      var college = (datas[1].count*100)/totalData;
      var exam = (datas[2].count*100)/totalData;
      var test = (datas[3].count*100)/totalData;
      data.icon = (
      <Center className='just' style={{marginBottom:'15rem'}}>
        <div style={{position:'absolute',top:'10px',left:'0',right:'15rem'}}>
      <CircularProgress thickness={10} size={'15rem'} variant="determinate" value={school} style={{color:'green',zIndex:'4',position:'inherit'}}  />
      <CircularProgress thickness={10} size={'15rem'} variant="determinate" value={school+college} style={{color:'blue',zIndex:'3',position:'inherit'}}  />
      <CircularProgress thickness={10} size={'15rem'} variant="determinate" value={school+college+exam} style={{color:'orange',zIndex:'2',position:'inherit'}}  />
      <CircularProgress thickness={10} size={'15rem'} variant="determinate" value={100} style={{color:'red',zIndex:'1',position:'inherit'}}  />
      </div>
      </Center>);
      data.name = "Total Data"
      data.count = totalData;
      break;
    default:
      break;
  }



  return (
  <Card className="card" key={data.name} sx={{width:'100%'}}  >
    <Link to={data.onClick} style={{textDecoration:'none',color:'black'}}>
    <CardActionArea sx={{height:'100%' }}>
    <CardContent>
        <Center>
          {data.icon}
        </Center>
        <Center>
          <Typography variant="h6" color="text.secondary">{data.count}</Typography>
        </Center>
        <Center>
          <Typography variant='h5'>{data.name}</Typography>
        </Center>
      </CardContent>
    </CardActionArea>
    </Link>
  </Card>
  )
}




const Dashboard = () => {
  const user = useSelector(store=>store.user.user.responseData)
  const mainDataCount = useGetMainDataCountQuery();
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll'}}>
      <QuickAccess  sx={{m:2,pr:2,pl:2,display:'flex',alignContent:'center'}} >

        <Box sx={{ flexGrow: 1,display:'flex' ,alignItems:'center'}}>
          <Tooltip title="Admin Permission Level">
            <Button variant="outlined">Admin Level: {user.adminType}</Button>
          </Tooltip>
        </Box>

        

        <Box sx={{ flexGrow: 0,display:'flex' ,alignItems:'center'}}>
        <Tooltip title="Bug Report" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained" >{<BugReport />}</Button>
              </Badge>
          </Tooltip>
          
          <Tooltip title="Email" sx={{mr:3}}>
              <Badge color="secondary" badgeContent={100} max={99}>
              <Button variant="contained">{<Email />}</Button>
              </Badge>
          </Tooltip>
        </Box>

      </QuickAccess>
      <MainDataGrid>
        {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'school')}
        {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'college')}
        {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'exam')}
        {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'test')}
      </MainDataGrid>
      <Grid className='userGrid'>
            {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'totalData')}
        <Grid className='userInnerGrid'>
        {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'user')}
        {mainDataCount.isSuccess && MainDataWidget(mainDataCount,'admin')}
        </Grid>
      </Grid>
    </div>
      </Container>
  )
}

export default Dashboard