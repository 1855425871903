import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router';
import Loading from '../components/pages/loading/Loading';
import { checkAuth } from '../redux_store/slices/auth_slice';
import { uiSliceAction } from '../redux_store/slices/ui_slice';
import { useCheckAuthQuery } from '../redux_store/service/users';

const IsAuth = ({child}) => {
  const user = useSelector(state=>state.user);
//   const status = useSelector(state=>state.user.status);
//   const isAuth = useSelector(store=>store.user.isAuthenticated);
  
  const dispatch = useDispatch();
  useEffect(()=>{
       dispatch(checkAuth())
  },[])

//   useEffect(()=>{
//     if(user !== 'none'){
//       console.log(user)
//       if(user.responseMessage === 'SUCCESS'){
//         dispatch(uiSliceAction.alertSet({message:'Logged in',color:'success'}));
//     }
//   }
// },[user,dispatch])

//const data = useCheckAuthQuery();





if(user.isSuccess === false){
    return <Loading></Loading>
  }else{
    return child
  }

}

export default IsAuth;



     
     
  