import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'


//const API = 'http://localhost:3000/'
const API = 'https://api.learnplusplus.com'

export const FetchDataApi = createApi({
    reducerPath:"data",
    baseQuery:fetchBaseQuery({
        baseUrl:API,
        credentials:"include"
                        
    }),
    
    endpoints:(builder)=>({
        getMainDataCount:builder.query({
            query:()=>({
                url:'count/main',
                
            }),      
            
            
        }),
        getColleges:builder.query({
            query:(uri)=>({
                url:uri?`college/${uri}`:'college/all-college',
            }),      
        }),
        getCollege:builder.query({
            query:(uri)=>({
                url:`college/get/${uri}`,
            }),       
        }),
        createCollege:builder.mutation({
            query:(data)=>({
                method:'POST',
                url:`college/add`,
                body:data,
            }),   
        }),
        updateCollege:builder.mutation({
            query:(data)=>({
                method:'PATCH',
                url:`college/${data.id}`,
                body:data.data,
            }),   
        }),
        deleteCollege:builder.mutation({
            query:(uri)=>({
                method:'DELETE',
                url:`college/${uri}`,
            }),     
            
            
        }),
        getTopColleges:builder.query({
            query:(uri)=>({
                url:uri?`college/top/${uri}`:'college/all-college',
            }),      
        }),
        getStateNames:builder.query({
            query:(uri)=>({
                url:'location-state-name/all',
            }),      
        }),
        getCityNames:builder.query({
            query:()=>({
                url:'location-district-name/all',
            }),      
        }),



        getSchools:builder.query({
            query:(uri)=>({
                url:uri?`school/${uri}`:'school/all-school',
            }),      
        }),

        getSchool:builder.query({
            query:(uri)=>({
                url:`school/get/${uri}`,
            }),      
            
            
        }),
        createSchool:builder.mutation({
            query:(data)=>({
                method:'POST',
                url:`school/add`,
                body:data,
            }),   
        }),
        updateSchool:builder.mutation({
            query:(data)=>({
                method:'PATCH',
                url:`school/${data.id}`,
                body:data.data,
            }),   
        }),
        deleteSchool:builder.mutation({
            query:(uri)=>({
                method:'DELETE',
                url:`school/${uri}`,
            }),     
            
            
        }),

        getTopSchools:builder.query({
            query:()=>({
                url:`school/top/all`,
            }),      
        }),

        getExams:builder.query({
            query:(uri)=>({
                url:uri?`exam/${uri}`:'school/all-exam',
            }),      
        }),
        getExam:builder.query({
            query:(uri)=>({
                url:`exam/get/${uri}`,
            }),      
        }),
        createExam:builder.mutation({
            query:(data)=>({
                method:'POST',
                url:`exam/add`,
                body:data,
            }),   
        }),
        updateExam:builder.mutation({
            query:(data)=>({
                method:'PATCH',
                url:`exam/${data.id}`,
                body:data.data,
            }),   
        }),
        deleteExam:builder.mutation({
            query:(uri)=>({
                method:'DELETE',
                url:`exam/${uri}`,
            }),     
            
            
        }),

        getUsers:builder.query({
            query:()=>({
                url:`user/get-user`,
            }),      
        }),
        getUser:builder.query({
            query:(uri)=>({
                url:`user/get/${uri}`,
            }),      
        }),
        getAdmins:builder.query({
            query:()=>({
                url:`user/get-admin`,
            }),      
        }),

        deleteUser:builder.mutation({
            query:(uri)=>({
                method:'DELETE',
                url:`user/${uri}`,
            }),     
        }),


        getTokens:builder.query({
            query:()=>({
                url:`user/tokens/get`,
            }),      
        }),

        generateToken:builder.mutation({
            query:(body)=>({
                method:'POST',
                url:`user/generate/admin-token`,
                body
            }),      
        }),

        

        
        
    })
    

})


export const {
    useGetMainDataCountQuery,
    //College//
    useGetCollegesQuery,
    useGetCollegeQuery,
    useDeleteCollegeMutation,
    useGetTopCollegesQuery,
    useCreateCollegeMutation,
    useUpdateCollegeMutation,
    //College//
    useGetCityNamesQuery,
    useGetStateNamesQuery,
    useGetSchoolsQuery,
    useGetSchoolQuery,
    useCreateSchoolMutation,
    useUpdateSchoolMutation,
    useDeleteSchoolMutation,
    useGetTopSchoolsQuery,

    useGetExamsQuery,
    useGetExamQuery,
    useDeleteExamMutation,
    useCreateExamMutation,
    useUpdateExamMutation,


    useGetUsersQuery,
    useGetUserQuery,
    useGetAdminsQuery,
    useDeleteUserMutation,
    useGetTokensQuery,
    useGenerateTokenMutation,

} = FetchDataApi;