import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    alert:{
        color:'',
        info:[],
        message:'',
    },
}

const uiSlice = createSlice({
    name: 'uiSlice',
    initialState,
    reducers:{

        alertSet(state,action){
            state.alert.color = action.payload.color;
            state.alert.message = action.payload.message;
            if(state.alert.info){
                state.alert.info = action.payload.info;
            }
        },
        alertReset(state){
            state.alert = initialState.alert;
            
        }
    }
});

export const uiSliceAction = uiSlice.actions;

export default uiSlice;