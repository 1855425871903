import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux_store/store';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import IsAuth from './middlewares/IsAuth';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
  <Provider store={store}>
    <IsAuth child={<App/>}>
    </IsAuth>
  </Provider>
  </Router>
);
