import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { kAppBarColor } from '../../../const/const'
import { desktop, mobile, tablet } from '../../../utils/responsive';


import {Badge, Box, Button,Grid,IconButton,TextField,Tooltip} from '@mui/material';
import { BugReport, Delete, Edit, Email, Visibility } from '@mui/icons-material';
import { useDeleteExamMutation, useGetExamsQuery} from '../../../redux_store/service/fetchData';
import { Link } from 'react-router-dom';


import { DataGrid, } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{
      .userGrid{
        justify-items:center;
        padding:20px;
        width:100%;
        height:55vh;
        min-height:500px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        ${mobile({
        'grid-template-columns': 'repeat(1, 1fr)',
        'grid-template-rows': 'repeat(2, 1fr)',
        'grid-row-gap': '10px',
        })};
        .dataInnerGrid{
          width:100%;
          height:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;

        }


        .userInnerGrid{
          width:100%;
          justify-items:center;
          justify-content:center;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
        }
      }

    }
    
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  justify-items:center;
  align-content:center;
  text-align:center;
`;


const QuickAccess = styled(Box)`
  background-color:${kAppBarColor};
  border-radius:5px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.64);
  padding:10px;
`;



 




const Documentation = (props) => {

  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll'}}>
        <Center>
          <h1 style={{marginTop:'200px'}}>Documentation</h1>
          <h4 style={{marginTop:'200px'}}>No Documentation</h4>
        </Center>

        </div>
      </Container>
  )
}

export default Documentation