import { ArrowCircleLeft, ArrowCircleRight, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Card, IconButton } from '@mui/material';
import gsap from 'gsap';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { kPrimaryColor, kPrimaryLight } from '../const/const';
import px2vw from '../utils/px2v';
import { mobile, tablet } from '../utils/responsive';


const MainCard = styled.div`
      .ratingsContainer{
      max-width:100%;
      height:250px;
      border-radius:10px;
      position:relative;
      justify-content:space-evenly;
      justify-items:center;
      align-items:center;
      align-content:center;
      padding:10px;
      ${tablet({
        height:'200px'
    
      })}; 
      ${mobile({
        height:'150px'
      })};

      .arrows{
        font-size:60px;
        color:black;
        
      }
      .arrowLeft{
        padding:20px;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        z-index:1;
      }
      .arrowRight{
        padding:20px;
        position:absolute;
        top:0;
        bottom:0;
        right:0;
        z-index:1;
      }

      .ratingsWraper{
        display:grid;
        top:0;
        bottom:0;
        left:0;
        right:0;
        grid-auto-flow:column;
        align-self:center;
        align-items:center;
        justify-content:start;
        padding-left:20px;
        padding-right:20px;
        margin-left:10px;
        margin-right:10px;
        overflow-x:auto;
        position:absolute;

      }
    }
  
`


export const ScrollableListView = ({child}) => {
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
    
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

    const anim = (e) => {
        gsap.from(e.target, { scale: 1 });
        gsap.to(e.target, { scale: 1.2 });
      };
      const anim2 = (e) => {
        gsap.from(e.target, { scale: 1.2 });
        gsap.to(e.target, { scale: 1 });
      };

      const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
          Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
          scrl.current.offsetWidth
        ) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      };
  return  <MainCard>
  <Card className='ratingsContainer'>
  {scrollX !== 0 &&
   <IconButton 
    className='arrowLeft'
    onClick={() => slide(-500)}
    onMouseEnter={(e)=> {
      anim(e);
    }}
    onMouseLeave={(e) => anim2(e)}
   >
    <ArrowCircleLeft className='arrows'/>
  </IconButton>}
    <div ref={scrl} onScroll={scrollCheck} className='ratingsWraper'>
      {child}
    </div>
  {!scrolEnd && 
    <IconButton 
      className='arrowRight'
      onClick={() => slide(+500)}
      onMouseEnter={(e) => anim(e)}
      onMouseLeave={(e) => anim2(e)}
  >
    <ArrowCircleRight className='arrows'/>
  </IconButton>}
</Card>
</MainCard>;
};
