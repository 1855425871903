import { Delete, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, Link, List, ListItemButton, ListItemText, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { useCreateSchoolMutation, useGetCityNamesQuery, useGetCollegeQuery, useGetSchoolQuery, useGetStateNamesQuery, useUpdateCollegeMutation, useUpdateSchoolMutation } from '../../redux_store/service/fetchData';
import {ScrollableListView} from '../../styles/ScrollableListView'
import { desktop, mobile, tablet } from '../../utils/responsive';
import { kAppBarColor } from '../../const/const';
import { LoadingButton } from '@mui/lab';
import { Navigate, useLocation } from 'react-router';
var validator = require('validator/es/lib/isEmail');
const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{

      .header{
        font-size:1.5rem;
        font-weight:600;
      }
      .subheader{
        margin-top:20px;

      }
      .datas{
        padding:10px;


      }
      .multipleInputs{
        margin-top:10px;
        margin-bottom:10px;
        justify-content:space-between;
        justify-items:stretch;
        display:flex;

      }
      .column{
        display:flex;
        flex-direction:column;
      }

      .accreditationContainer{
      border-radius:20px;
      width:200px;
      height:200px;
      background-color:${kAppBarColor};
      margin:10px;
      position:relative;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};

      .grade{
        width:100%;
        height:70%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:600;
        font-size:80px;
        ${tablet({
          'font-size':'60px',
    
        })}; 
        ${mobile({
          'font-size':'40px',

        })};

      }
      .body{
        width:100%;
        height:30%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:500;
        font-size:20px;
        ${tablet({
          'font-size':'15px',
    
        })}; 
        ${mobile({
          'font-size':'10px',

        })};

      }
    }
    .submitButton{
      margin-top:50px;
      margin-bottom:50px;
      width:100%;
    }
      }
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;







function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}



const ExpandableList = ({data,type,action}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return <>
  <Box style={{display:'flex'}}>
  <ListItemButton onClick={handleClick} style={{border:'1px solid black',marginBottom:'1px'}}>
    <ListItemText primary={data.title} />
    {open ? <ExpandLess /> : <ExpandMore />}
    
  </ListItemButton>
  <IconButton onClick={()=>action(data.title)}  aria-label="delete" style={{color:'red'}}>
        <Delete />
      </IconButton>
      </Box>
  <Collapse in={open} timeout="auto" unmountOnExit style={{border:'0.1px solid grey',backgroundColor:kAppBarColor}}>
  <List component="div" disablePadding>
    <ListItemButton sx={{ pl: 4 }}>
      <ListItemText primary={type==='news'?data.news:data.blog} />
    </ListItemButton>
  </List>
  </Collapse>
</>
}


const SchoolAdd = (props) => {
  console.log(props.props)
  const location = useLocation();
  const id = location.pathname.substring(14);
  const [request,useRequest] = useState(true);
  const editSchool = useGetSchoolQuery(id,{skip:request,refetchOnMountOrArgChange:true});
  const [openBlogForm, setOpenBlogForm] = useState(false);
  const [openNewsForm, setopenNewsForm] = useState(false);
  const [city,setCity] = useState(null)
  const [state,setState] = useState(null)
  const [editMode,setEditMode] = useState(false)
  const [editName,setEditName] = useState(null)
  const [currentItem,setCurrentItem] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  //const cities = useGetCityNamesQuery('',{skip:callCity});
  const cities = useGetCityNamesQuery();
  const states = useGetStateNamesQuery();
  const [addSchool,school] = useCreateSchoolMutation();
  const [updateSchool,updatedSchool] = useUpdateSchoolMutation();
  const SetRequest = ()=>{
    useRequest(false);
  }
  useEffect(()=>{
    if(props.props === 'edit'){
      SetRequest()
    }
  },[]);

  

  const [data, setData] = useState({
    basicDetails: {
      name:'',
      code:'',
      estd:''
    },
    locationDetails: {
      address:'',
      street:'',
      city:'',
      district:'',
      state:'',
      location:{
        type:"Point",
        coordinates:[0,0]
      }
    },
    contactDetails:{
      siteUrl:'',
      email:'',
      phone:''
    },
    board:'',
    ownership:'',
    stage:'',
    type:'',
    campusArea:'',
    facultyStrength:'',
    studentStrength:'',
    description:'',
    activities:[],
    news:[],
    blog:[],
    topSchool:false
  });
  const [blogData,setBlogData] = useState({
    title:'',
    blog:''
  });
  const [newsData,setNewsData] = useState({
    title:'',
    news:''
  });
  const [blogDataError,setBlogDataError] = useState({
    title:false,
    blog:false
  });
  const [newsDataError,setNewsDataError] = useState({
    title:false,
    news:false
  });


  useEffect(()=>{
    if(editSchool.isSuccess){
      var collegeData = editSchool.data.responseData;
      var tempEditData = {...data};
      var {_id,...obj} = collegeData.basicDetails;
      tempEditData.basicDetails = obj;
      var {_id,...obj} = collegeData.locationDetails;
      tempEditData.locationDetails = obj;
      var {_id,...obj} = collegeData.contactDetails;
      tempEditData.contactDetails = obj;
      tempEditData.board = collegeData.board;
      tempEditData.ownership = collegeData.ownership;
      tempEditData.stage = collegeData.stage;
      tempEditData.type = collegeData.type;
      tempEditData.campusArea = collegeData.campusArea;
      tempEditData.facultyStrength = collegeData.facultyStrength;
      tempEditData.studentStrength = collegeData.studentStrength;
      tempEditData.topSchool = collegeData.topSchool;
      tempEditData.description = collegeData.description;
      tempEditData.activities = collegeData.activities;
      var news = [];
      var blog = [];
      collegeData.news.forEach(item=>{
            var {_id,...obj} = item
            news.push(obj)
      });
      tempEditData.news = news;
      collegeData.blog.forEach(item=>{
            var {_id,...obj} = item
            blog.push(obj);
          })
      tempEditData.blog = blog;
      setData(tempEditData);
      setCity(collegeData.locationDetails.district)
      setState(collegeData.locationDetails.state)
    }
  },[editSchool]);

    const [error, setError] = useState({
      errors:{
      name: false,
      code: false,
      estd: false,
      address: false,
      street: false,
      city: false,
      district: false,
      state: false,
      siteUrl: false,
      email: false,
      phone: false,
      board: false,
      ownership: false,
      stage: false,
      type: false,
      campusArea: false,
      facultyStrength: false,
      studentStrength: false,
      description: false,
      activities:false
      }
      
      });

      const onChange = (type) =>(event)=>{
        event = event.target.value;
        console.log(type,event);
        var tempData = {...data};
        var err = {...error};
        switch(type){
          case 'name':
            tempData.basicDetails.name = event;
            err.errors.name = false;
            break;
          case 'code':
            tempData.basicDetails.code = event;
            err.errors.code = false;
            break;
          case 'estd':
            tempData.basicDetails.estd = event;
            err.errors.estd = false;
            break;
          case 'address':
            tempData.locationDetails.address = event;
            err.errors.address = false;
            break;
          case 'street':
            tempData.locationDetails.street = event;
            err.errors.street = false;
            break;
          case 'city':
            tempData.locationDetails.city = event;
            err.errors.city = false;
            break;
          case 'email':
            tempData.contactDetails.email = event;
            err.errors.email = false;
            break;
          case 'phone':
            tempData.contactDetails.phone = event;
            err.errors.phone = false;
            break;
          case 'siteUrl':
            tempData.contactDetails.siteUrl = event;
            err.errors.siteUrl = false;
            break;
          case 'board':
            tempData.board = event;
            err.errors.board = false;
            break;
          case 'ownership':
            tempData.ownership = event;
            err.errors.ownership = false;
            break;
          case 'stage':
            tempData.stage = event;
            err.errors.stage = false;
            break;
          case 'type':
            tempData.type = event;
            err.errors.type = false;
            break;
          case 'campusArea':
            tempData.campusArea = event;
            err.errors.campusArea = false;
            break;
          case 'facultyStrength':
            tempData.facultyStrength = event;
            err.errors.facultyStrength = false;
            break;
          case 'studentStrength':
            tempData.studentStrength = event;
            err.errors.studentStrength = false;
            break;
          case 'dis':
            tempData.studentStrength = event;
            err.errors.studentStrength = false;
            break;
          case 'description':
            tempData.description = event;
            err.errors.description = false;
            break;
          default:
            break;
        }
        setError(err)
        setData(tempData)
      }

      const onChangeActivities =(value)=>{
        setData({...data,activities:value})
      }

      const Submit=()=>{
        console.log('asdasd')
      }

      const OnSubmit = ()=>{
        
        var err = {...error};
        var isError = false;
        if(data.basicDetails.name.length === 0){
          err.errors.name = true;
          isError = true;
        }if(data.basicDetails.estd.length === 0){
          err.errors.estd = true;
          isError = true;
        }if(data.basicDetails.code.length === 0){
          err.errors.code = true;
          isError = true;
        }if(data.locationDetails.address.length === 0){
          err.errors.address = true;
          isError = true;
        }if(data.locationDetails.street.length === 0){
          err.errors.street = true;
          isError = true;
        }if(data.locationDetails.city.length === 0){
          err.errors.city = true;
          isError = true;
        }if(data.locationDetails.district.length === 0){
          err.errors.district = true;
          isError = true;
        }if(data.locationDetails.state.length === 0){
          err.errors.state = true;
          isError = true;
        }if(data.contactDetails.email.length === 0){
          err.errors.email = true;
          isError = true;
        }if(!validator.default(data.contactDetails.email)){
          err.errors.email = true;
          isError = true;
        }if(data.contactDetails.phone.length === 0){
          err.errors.phone = true;
          isError = true;
        }if(data.contactDetails.siteUrl.length === 0){
          err.errors.siteUrl = true;
          isError = true;
        }if(data.board.length === 0){
          err.errors.board = true;
          isError = true;
        }if(data.ownership.length === 0){
          err.errors.ownership = true;
          isError = true;
        }if(data.stage.length === 0){
          err.errors.stage = true;
          isError = true;
        }if(data.type.length === 0){
          err.errors.type = true;
          isError = true;
        }if(data.campusArea.length === 0){
          err.errors.campusArea = true;
          isError = true;
        }if(data.facultyStrength.length === 0){
          err.errors.facultyStrength = true;
          isError = true;
        }if(data.studentStrength.length === 0){
          err.errors.studentStrength = true;
          isError = true;
        }if(data.description.length === 0){
          err.errors.description = true;
          isError = true;
        }
        if(isError === true){
          setError(err);
          return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        }
        console.log(data)
        if(props.props === 'edit'){
          console.log(data)
          var editData = {data:data,id:id}
          updateSchool(editData)
        }else{
          addSchool(data);
        }
        
      }
      const handleSelect = (type) => (item,value) => {
        var temp;
        var err = {...error};
        switch(type){
          case 'district':
            temp = data.locationDetails;
            temp.district = value
            setCity(value);
            err.errors.district = false;
            setError(err);
            setData({...data,locationDetails:temp})
            break
          case 'state':
            temp = data.locationDetails;
            temp.state = value
            setState(value)
            err.errors.state = false;
            setError(err);
            setData({...data,locationDetails:temp})
            break
          case 'discipline':
            break
          case 'ownership':
            break
          case 'category':
            break
          case 'type':
            break
          default:
            break;
        } 
      };

      useEffect(()=>{
            if(cities.isSuccess){
              var data = []
              for(const element of cities.data.responseData??[]){
                if(!data.includes(element)){
                  data.push(element)
                }   
              }
              setCityOptions(data??[])
            }
            if(states.isSuccess){
              var data2 = []
              for(const element of states.data.responseData??[]){
                if(!data2.includes(element)){
                  data2.push(element)
                }   
              }
              setStateOptions(data2??[])
            }
      },[states,cities]);

      const handleBlogFormOpen = () => {
        setOpenBlogForm(true);
      };
      const handleNewsFormOpen = () => {
        setopenNewsForm(true);
      };
      const handleBlogFormClose = () => {
        setOpenBlogForm(false);
      };
      const handleNewsFormClose = () => {
        setopenNewsForm(false);
      };

      const handleAddBlog=() => {
          let err = {...blogDataError};
          if(blogData.title.length===0){
            err.title = true;
          }else err.title = false;
          if(blogData.blog.length===0){
            err.blog = true;
          }else err.blog = false;
          setBlogDataError(err)
          if(err.blog||err.title)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        let tempBlog = data.blog;
        if(tempBlog.some(item=>item.title.toString() === blogData.title.toString() )){
              toast.error(`Blog with name ${blogData.title} already added`,{position:"top-center",autoClose:1000})
          }else{
            tempBlog = tempBlog.concat(blogData)
          }
            
        setData({ ...data, blog: tempBlog });
        setBlogData({title:'',blog:''})
        setOpenBlogForm(false);
      }
      const handleDeleteNews = (name) =>{
        let tempNews = data.news;
        tempNews = tempNews.filter(d=>d.title.toLowerCase() !== name.toLowerCase());
        setData({ ...data, news: tempNews });
      }

      const handleAddNews=() => {
          let err = {...newsDataError};
          if(newsData.news.length === 0){
            err.news = true;
          }else err.news = false;
          if(newsData.title.length === 0){
            err.title = true;
          }else err.title = false;
          setNewsDataError(err)
          if(err.news || err.title)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
          
          
        let tempNews = data.news;
        if(tempNews.some(item=>item.title.toString() === newsData.title.toString() )){
              toast.error(`News with title ${newsData.title} already added`,{position:"top-center",autoClose:1000})
          }else{
            tempNews = tempNews.concat(newsData)
          }
            
        setData({ ...data, news: tempNews });
        setNewsData({title:'',news:''})
        setopenNewsForm(false);
      }
      const handleDeleteBlog = (name) =>{
        let tempBlog = data.blog;
        tempBlog = tempBlog.filter(d=>d.title.toLowerCase() !== name.toLowerCase());
        setData({ ...data, blog: tempBlog });
      }
      const blogDataOnChange = (type) => (event) =>{
        setBlogDataError({...blogDataError,[type]:false});
        setBlogData({ ...blogData, [type]: event.target.value });
      }
      const newsDataOnChange = (type) => (event) =>{
        setNewsDataError({...newsDataError,[type]:false});
        setNewsData({ ...newsData, [type]: event.target.value });
      }
      


      const handleSchoolRanking = (type) => (item,value)=>{
        setData({ ...data, topSchool: value });
      } 

     

console.log("runnning")
if(school.isError){
  var e = typeof(school.error.data.responseData) === 'object'?school.error.data.responseData[0].message:school.error.data.responseData
  toast.error(e??'Something Went Wrong',{position:"top-center",autoClose:1000})
}
if(updatedSchool.isError){
  toast.error(updatedSchool.error.data.responseData,{position:"top-center",autoClose:1000})
}

if(school.isSuccess || updatedSchool.isSuccess){
  toast.success('School Added',{position:"top-center",autoClose:1000})
  return <Navigate to={'/schools'} replace={true} state={null} />
}else
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll',padding:'10px'}}>
          <div className="header">{props.props==='edit'?'Edit School':'Add New School'}</div>
          <Box component="form">
          <Card className='datas'>
            <h4 className='subheader'>Basic Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.basicDetails.name}
                error={error.errors.name}
                helperText={error.errors.name?'Field is required':null}
                onChange={onChange('name')}
                label="Name"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.basicDetails.code}
                error={error.errors.code}
                helperText={error.errors.code?'Field is required':null}
                onChange={onChange('code')}
                label="Code"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.basicDetails.estd}
                error={error.errors.estd}
                helperText={error.errors.estd?'Field is required':null}
                onChange={onChange('estd')}
                label="Estd"
              />
            </Box>

            <h4 className='subheader'>Location Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'70%',margin:'10px'}}
                required
                value={data.locationDetails.address}
                error={error.errors.address}
                helperText={error.errors.address?'Field is required':null}
                onChange={onChange('address')}
                label="Address"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.locationDetails.street}
                error={error.errors.street}
                helperText={error.errors.street?'Field is required':null}
                onChange={onChange('street')}
                label="Street"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.locationDetails.city}
                error={error.errors.city}
                helperText={error.errors.city?'Field is required':null}
                onChange={onChange('city')}
                label="City"
              />
              <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                value={city}
                onChange={handleSelect('district')} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={cityOptions.sort()}
                groupBy={(option) => option[0]}
                loading={cities.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    error={error.errors.district}
                    helperText={error.errors.district?'Field is required':null}
                    {...params}
                    label={'District'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {cities.isLoading?<CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                value={state}
                onChange={handleSelect('state')} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    error={error.errors.state}
                    helperText={error.errors.state?'Field is required':null}
                    {...params}
                    label={'State'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {states.isLoading?<CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>


            <h4 className='subheader'>Contact Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.contactDetails.email}
                onChange={onChange('email')}
                error={error.errors.email}
                helperText={error.errors.email?'Enter Valid Email':null}
                label="Email"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.contactDetails.phone}
                onChange={onChange('phone')}
                error={error.errors.phone}
                helperText={error.errors.phone?'Field is required':null}
                label="Contact"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.contactDetails.siteUrl}
                onChange={onChange('siteUrl')}
                error={error.errors.siteUrl}
                helperText={error.errors.siteUrl?'Field is required':null}
                label="Site URL"
              />
            </Box>


            <h4 className='subheader'>Other Details</h4>
            <Box className='column'>
            {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Discipline'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                onChange={onChange('board')}
                value={data.board}
                error={error.errors.board}
                helperText={error.errors.board?'Field is required':null}
                label="Board"
              />
              {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Ownersip'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.ownership}
                onChange={onChange('ownership')}
                error={error.errors.ownership}
                helperText={error.errors.ownership?'Field is required':null}
                label="Ownersip"
              />
              {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Category'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.stage}
                onChange={onChange('stage')}
                error={error.errors.stage}
                helperText={error.errors.stage?'Field is required':null}
                label="Stage"
              />
              {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Type'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.type}
                onChange={onChange('type')}
                error={error.errors.type}
                helperText={error.errors.type?'Field is required':null}
                label="Type"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.campusArea}
                onChange={onChange('campusArea')}
                error={error.errors.campusArea}
                helperText={error.errors.campusArea?'Field is required':null}
                label="Campus Area"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.facultyStrength}
                onChange={onChange('facultyStrength')}
                error={error.errors.facultyStrength}
                helperText={error.errors.facultyStrength?'Field is required':null}
                label="Faculty Strength"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.studentStrength}
                onChange={onChange('studentStrength')}
                error={error.errors.studentStrength}
                helperText={error.errors.studentStrength?'Field is required':null}
                label="Student Strength"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.description}
                onChange={onChange('description')}
                error={error.errors.description}
                helperText={error.errors.description?'Field is required':null}
                label="Description"
              />

              <Autocomplete
                    style={{width:'50%',margin:'10px'}}
                    multiple
                    value={data.activities}
                    options={[]}
                    defaultValue={[]}
                    onChange={(e,value)=>onChangeActivities(value)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={error.errors.activities}
                        helperText={error.errors.activities?'Field is required':null}
                        {...params}
                        label="Activities[ ]"
                      />
                    )}
              />
            </Box>


            <FormControlLabel value={data.topSchool} style={{marginLeft:'10px'}} control={<Checkbox checked={data.topSchool} onChange={handleSchoolRanking()}/>} label="Top School" />



            


              <Box className='multipleInputs' >
                <h4 className='subheader'> Blogs</h4>
                <Button variant='contained' onClick={handleBlogFormOpen}>Add </Button>
              </Box> 
              {data.blog.length
              ?<>
                {data.blog.map((item)=>{
                    return <ExpandableList data={item} type={'blog'} action={(d)=>handleDeleteBlog(d)} />
                })}
              </>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Blog Added</Box>
                </Center>
                </Card>}

                <Dialog open={openBlogForm} onClose={handleBlogFormClose}>
                <DialogTitle>Add Blog</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Blog.
                  </DialogContentText>
                  <TextField
                    error={blogDataError.title}
                    helperText={blogDataError.title?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={blogDataOnChange('title')}
                    label="Title"
                    value={blogData.title}
                    fullWidth
                  />
                  <TextField
                    error={blogDataError.blog}
                    helperText={blogDataError.grade?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={blogDataOnChange('blog')}
                    label="Blog"
                    value={blogData.blog}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleBlogFormClose}>Cancel</Button>
                  <Button onClick={handleAddBlog}>Add</Button>
                </DialogActions>
              </Dialog>



              <Box className='multipleInputs' >
                <h4 className='subheader'> News</h4>
                <Button variant='contained' onClick={handleNewsFormOpen} >Add </Button>
              </Box> 

              {data.news.length
              ?<>
                {data.news.map((item)=>{
                    return <ExpandableList data={item} type={'news'} action={(d)=>handleDeleteNews(d)} />
                })}
               </>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No News Added</Box>
                </Center>
                </Card>}

                <Dialog open={openNewsForm} onClose={handleNewsFormClose}>
                <DialogTitle>Add News</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new News.
                  </DialogContentText>
                  <TextField
                    error={newsDataError.title}
                    helperText={newsDataError.title?'Filed is Required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={newsDataOnChange('title')}
                    label="Title"
                    value={newsData.title}
                    fullWidth
                  />
                  <TextField
                    error={newsDataError.news}
                    helperText={newsDataError.news?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={newsDataOnChange('news')}
                    label="News"
                    type='text'
                    value={newsData.news}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleNewsFormClose}>Cancel</Button>
                  <Button onClick={handleAddNews}>Add</Button>
                </DialogActions>
              </Dialog>

              
            
          </Card>
          <LoadingButton
                className='submitButton'
                size="small"
                loading={props.props==='edit'?updatedSchool.isLoading:school.isLoading}
                loadingIndicator={<CircularProgress size={'2ch'} />}
                variant="contained"
                onClick={OnSubmit}
              >
                {props.props==='edit'?'Update':'Create'}
              </LoadingButton>
          </Box>
        </div>
      </Container>
  )
}

export default SchoolAdd