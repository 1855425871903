export const kPrimaryColor = "#49AFA9";
export const kPrimaryLight = "#317A79";
export const kSecondary = "#317A79";
export const kBackgroundColor = "#97a7a7";
export const kBackgroundDark = "#57D68D";
export const kDarkText = "#000";
export const kLightText = "#fff";
//export const kAppBarColor = "#4DBC9C";
export const kAppBarColor = "#ECF0F1";
export const kAppBarDark = "#BDC3C7";




///SIZES///
export const kNavbarHeight = '50px';
export const kNavbarHeightMobile = '40px';



export const  dicipline = [
        "Architecture",
        "Applied Arts",
        "Architechture",
        "Architechture Planning",
        "Arts and Humanities",
        "Business Administration",
        "Commerce",
        "Design",
        "Education",
        "Engineering and Technology",
        "Finance",
        "Fine Arts",
        "Hotel Mangement",
        "Humanities",
        "Journalism",
        "Law",
        "Library Science",
        "Management",
        "Mass Communication",
        "Media",
        "Medical",
        "Nursing",
        "Paramedical",
        "Performing Arts",
        "Pharmacy",
        "Physiotherapy",
        "Public Policy",
        "Rehabilitation Sciences",
        "Sanskrit",
        "Science",
        "Social Science",
        "Tourism and Travel",
        "Visual Arts",
        "Vocational Education"
    ]