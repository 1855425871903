import React from 'react'
import styled from 'styled-components'
import { kBackgroundColor } from '../../../const/const'


const Container = styled.div`
    background-color:${kBackgroundColor};
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    padding-left:10px;
`



const Verify = () => {
  return (
      <Container>
      <div>Verify</div>
      </Container>
  )
}

export default Verify