import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, List, ListItemButton, ListItemText, ListSubheader, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { useCreateExamMutation, useGetCityNamesQuery, useGetExamQuery, useGetStateNamesQuery, useUpdateExamMutation } from '../../redux_store/service/fetchData';
import { desktop, mobile, tablet } from '../../utils/responsive';
import { kAppBarColor } from '../../const/const';
import { LoadingButton } from '@mui/lab';
import { Navigate, useLocation } from 'react-router';
import { getDate, getTime } from '../../utils/getDateTIme';
const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{

      .header{
        font-size:1.5rem;
        font-weight:600;
      }
      .subheader{
        margin-top:20px;

      }
      .datas{
        padding:10px;


      }
      .multipleInputs{
        margin-top:10px;
        margin-bottom:10px;
        justify-content:space-between;
        justify-items:stretch;
        display:flex;

      }
      .column{
        display:flex;
        flex-direction:column;
      }

      .accreditationContainer{
      border-radius:20px;
      width:200px;
      height:200px;
      background-color:${kAppBarColor};
      margin:10px;
      position:relative;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};

      .grade{
        width:100%;
        height:70%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:600;
        font-size:80px;
        ${tablet({
          'font-size':'60px',
    
        })}; 
        ${mobile({
          'font-size':'40px',

        })};

      }
      .body{
        width:100%;
        height:30%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:500;
        font-size:20px;
        ${tablet({
          'font-size':'15px',
    
        })}; 
        ${mobile({
          'font-size':'10px',

        })};

      }
    }
    .submitButton{
      margin-top:50px;
      margin-bottom:50px;
      width:100%;
    }
      }
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;







function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}



const ExpandableList = ({sub,data,index,action}) => {
    const [open, setOpen] = useState(false);
  
    const handleClick = () => {
      setOpen(!open);
    };
    return <>
    <Box className='multipleInputs' >
        <ListItemButton onClick={handleClick} style={{backgroundColor:kAppBarColor,marginLeft:'5px',marginRight:'5px',borderRadius:'5px'}}>
        <ListItemText primary={index+1+'. '+data.question} />
        {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <IconButton color='error' onClick={()=>action(sub,data.question)}>
            <Delete />
        </IconButton>
    </Box>
    <Collapse in={open} timeout="auto" unmountOnExit style={{marginLeft:'5px',marginRight:'5px',backgroundColor:'#74c7ee',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}>
    <List component="div" disablePadding>
        <ListItemText primary={'A: '+data.options[0].a} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='a'?'#5df369':null,borderRadius:'10px'}}/>
        <ListItemText primary={'B: '+data.options[0].b} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='b'?'#5df369':null,borderRadius:'10px'}}/>
        <ListItemText primary={'C: '+data.options[0].c} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='c'?'#5df369':null,borderRadius:'10px'}}/>
        <ListItemText primary={'D: '+data.options[0].d} style={{padding:'10px',marginLeft:'10px',marginRight:'10px',backgroundColor:data.answer==='d'?'#5df369':null,borderRadius:'10px'}}/>
    </List>
    </Collapse>
    <Divider style={{margin:'10px'}}/>
  </>
  }

const ExamAdd = (props) => {
  const location = useLocation();
  const id = location.pathname.substring(11);
  const [request,useRequest] = useState(true);
  const editExam = useGetExamQuery(id,{skip:request,refetchOnMountOrArgChange:true});
  const [cityForm, setCityForm] = useState(false);
  const [cityType, setCityType] = useState('');
  const [slotDataForm, setSlotDataForm] = useState(false);
  const [streamDataForm, setStreamDataForm] = useState(false);
  const [eligibilityForm, setEligibilityForm] = useState(false);
  const [subjectForm, setSubjectForm] = useState(false);
  const [resultDataForm, setResultDataForm] = useState(false);
  const [subjectType,setSubejctType] = useState('');
  const [testQuestionForm, setTestQuestionForm] = useState(false);
  const [addExam,exam] = useCreateExamMutation();
  const [updateExam,updatedExam] = useUpdateExamMutation();
  const SetRequest = ()=>{
    useRequest(false);
  }
  useEffect(()=>{
    if(props.props === 'edit'){
      SetRequest()
    }
  },[]);

  

  const [data, setData] = useState({
    name:'',
    short:'',
    level:'',
    year:'',
    conductedBy:'',
    admissionLevel:'',
    mode:[],
    onlineModeCities:[],
    offlineModeCities:[],
    ageLimit:'',
    totalSlots:'',
    slotData:[],
    eligibity:[],
    totalStreams:'',
    streamData:[],
    totalMarks:'',
    totalTime:'',
    resultData:[],
    admissionDate:'',
    topExam:false,
    testData:[],
  });
  const [error, setError] = useState({
    errors:{
    name: false,
    short: false,
    level: false,
    year: false,
    conductedBy: false,
    admissionLevel: false,
    mode: false,
    onlineModeCities: false,
    offlineModeCities: false,
    ageLimit: false,
    streamData:false,
    totalSlots: false,
    slotData: false,
    eligibity: false,
    totalStreams: false,
    totalMarks: false,
    totalTime: false,
    resultData: false,
    admissionDate: false,
    testData: false,
    }
    
    });
  const [citiesData,setCitiesData] = useState({
    city:'',
    zip:''
  });
  
  const [citiesDataError,setCitiesDataError] = useState({
    city:false,
    zip:false
  });
  const handleCityFormOpen = (item) => {
    setCityType(item)
    setCitiesData({city:'',zip:''});
    setCityForm(true);
  };
  const handleCityFormClose = () => {
    setCityForm(false);
  };
  const handleAddCityData=() => {
    let err = {...citiesDataError};
    if(citiesData.city.length===0){
        err.city = true;
    }else err.city = false;
    var zip = parseInt(citiesData.zip);
    if(zip.toString().length===6){
        err.zip = false;
    }else err.zip = true;
    setCitiesDataError(err)
    if(err.city||err.zip)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
    let tempBlog = cityType==='online'?data.onlineModeCities:data.offlineModeCities;
    if(tempBlog.some(item=>item.city.toString() === citiesData.city.toString() )){
            toast.error(`Blog with name ${citiesData.city} already added`,{position:"top-center",autoClose:1000})
        }else{
        tempBlog = tempBlog.concat(citiesData)
        }
    if(cityType==='online'){
        setData({ ...data, onlineModeCities: tempBlog });
    }else{
        setData({ ...data, offlineModeCities: tempBlog });
    }
    setCitiesData({city:'',zip:''});
    setCityForm(false);
    }
    const cityDataOnChange = (type) => (event) =>{
        setCitiesDataError({...citiesDataError,[type]:false});
        setCitiesData({ ...citiesData, [type]: event.target.value });
    }



  const [slotData,setSlotData] = useState({
    slotNumber:'',
    startDate:'',
    endDate:''
  });
  const [slotDataError,setSlotDataError] = useState({
    slotNumber:false,
    startDate:false,
    endDate:false
  });
  const handleSlotDataFormOpen = () => {
    setSlotDataForm(true);
  };
  const handleSlotDataFormClose = () => {
    setSlotDataForm(false);
  };
  const handleAddSlotData=() => {
    let err = {...slotDataError};
    if(slotData.startDate.length===0){
        err.startDate = true;
    }else err.startDate = false;
    if(slotData.endDate.length===0){
        err.endDate = true;
    }else err.endDate = false;
    setSlotDataError(err)
    if(err.startDate||err.endDate)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
    var tempSlot = data.slotData;
    var slot = slotData;
    slot.slotNumber = data.slotData.length+1;
    tempSlot = tempSlot.concat(slot)
    const count = data.slotData.length+1;
    setData({ ...data, slotData: tempSlot,totalSlots:count});
    setSlotData({slotNumber:'',startDate:'',endDate:''});
    setSlotDataForm(false);
    }
    const slotDataOnChange = (type) => (event) =>{
        setSlotDataError({...slotDataError,[type]:false});
        setSlotData({ ...slotData, [type]: event.target.value });   
    }



  const [streamData,setStreamData] = useState({
    streamNumber:'',
    eligibity:[],
    subjects:[]
  });
  const [streamDataError,setStreamDataError] = useState({
    streamNumber:false,
    eligibity:false,
    subjects:false
  });
  const handleStreamDataFormOpen = () => {
    setStreamDataForm(true);
  };
  const handleStreamDataFormClose = () => {
    setStreamDataForm(false);
  };
  const handleAddStreamData=() => {
    let err = {...streamDataError};
    if(streamData.eligibity.length===0){
        err.eligibity = true;
    }else err.eligibity = false;
    if(streamData.subjects.length===0){
        err.subjects = true;
    }else err.subjects = false;
    setStreamDataError(err)
    if(err.eligibity||err.subjects)return toast.error('Please Add Stream Data',{position:"top-center",autoClose:1000});
    var tempStream = data.streamData;
    var tempTest = data.testData;
    for(const sub of streamData.subjects){
        if(tempTest.some(item=>item.subject.toString() === sub.subject.toString())){}
        else{
            tempTest.push({subject:sub.subject,questions:[]});
        }
    }
    var stream = streamData;
    stream.streamNumber = data.streamData.length+1;
    tempStream = tempStream.concat(stream)
    const count = data.streamData.length+1;
    setData({ ...data, streamData: tempStream,totalStreams:count,testData:tempTest});
    setStreamData({streamNumber:'',subjects:[],eligibity:[]});
    setStreamDataForm(false);
    }
    const streamDataOnChange = (type) =>{
        setStreamDataError({...streamDataError,[type]:false});
    }



  const [eligibity,setEligibityData] = useState({
    subject:'',
    marks:''
  });
  const [eligibityError,setEligibityError] = useState({
    subject:false,
    marks:false
  });
  const handleEligibilityFormOpen = () => {
    streamDataOnChange('eligibity');
    setEligibilityForm(true);
  };
  const handleEligibilityFormClose = () => {
    setEligibilityForm(false);
  };
  const handleAddEligibility=() => {
    let err = {...eligibityError};
    if(eligibity.subject.length===0){
        err.subject = true;
    }else err.subject = false;
    if(eligibity.marks.length===0){
        err.marks = true;
    }else err.marks = false;
    setEligibityError(err)
    if(err.marks||err.subject)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
    var tempEligibity = streamData.eligibity;
    tempEligibity = tempEligibity.concat(eligibity)
    setStreamData({ ...streamData, eligibity: tempEligibity});
    setEligibityData({marks:'',subject:''});
    setEligibilityForm(false);
    }
    const EligibilityOnChange = (type) => (event) =>{
        setEligibityError({...eligibityError,[type]:false});
        setEligibityData({ ...eligibity, [type]: event.target.value });   
    }



  const [subject,setSubjectData] = useState({
    subject:'',
    noOfQuestion:'',
    marksPerSubject:''

  });
  const [subjectError,setSubjectError] = useState({
    subject:false,
    noOfQuestion:false,
    marksPerSubject:false
  });
  const handleSubjectFormOpen = () => {
    streamDataOnChange('subjects');
    setSubjectForm(true);
  };
  const handleSubjectFormClose = () => {
    setSubjectForm(false);
  };
  const handleAddSubject=() => {
    let err = {...subjectError};
    if(subject.subject.length===0){
        err.subject = true;
    }else err.subject = false;
    if(subject.marksPerSubject.length===0){
        err.marksPerSubject = true;
    }else err.marksPerSubject = false;
    if(subject.noOfQuestion.length===0){
        err.noOfQuestion = true;
    }else err.noOfQuestion = false;
    setSubjectError(err)
    if(err.marksPerSubject||err.subject||err.noOfQuestion)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
    var tempSubject = streamData.subjects;
    tempSubject = tempSubject.concat(subject)
    setStreamData({ ...streamData, subjects: tempSubject});
    setSubjectData({marksPerSubject:'',subject:'',noOfQuestion:''});
    setSubjectForm(false);
    }
    const SubjectOnChange = (type) => (event) =>{
        setSubjectError({...subjectError,[type]:false});
        setSubjectData({ ...subject, [type]: event.target.value });   
    }

    const [resultData,setResultData] = useState({
        slotNumber:null,
        resultDate:'',
        link:'https://'
      });
      const [resultDataError,setResultDataError] = useState({
        slotNumber:false,
        resultDate:false,
        link:false
      });
      const handleResultDataFormOpen = () => {
        setResultDataForm(true);
      };
      const handleResultDataFormClose = () => {
        setResultDataForm(false);
      };
      const handleAddResultData=() => {
        let err = {...resultDataError};
        if(resultData.resultDate.length===0){
            err.resultDate = true;
        }else err.resultDate = false;
        if(resultData.link.length<=8){
            err.link = true;
        }else err.link = false;
        if(resultData.slotNumber.length===0){
            err.slotNumber = true;
        }else err.slotNumber = false;
        setResultDataError(err)
        if(err.resultDate||err.link||err.slotNumber)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        var tempResult = data.resultData;
        var result = resultData;
        result.slotNumber = parseInt(result.slotNumber.split(" ")[1]);
        tempResult = tempResult.concat(result)
        setData({ ...data, resultData: tempResult});
        setResultData({slotNumber:null,resultDate:'',link:'https://'});
        setResultDataForm(false);
        }
        const resultDataOnChange = (type) => (event,value) =>{
            setResultDataError({...resultDataError,[type]:false});
            setResultData({ ...resultData, [type]: type==="slotNumber"?value:event.target.value});   
        }
  

  const [testQuestion,setTestQuestion] = useState({
    question:'',
    options:[{
        a:'',
        b:'',
        c:'',
        d:''
    }],
    answer:''

  });
  const [testQuestionError,setTestQuestionError] = useState({
    question:false,
    options:[{
        a:false,
        b:false,
        c:false,
        d:false
    }],
    answer:''
  });
  const handleTestQuestionFormOpen = (type) => {
    setSubejctType(type);
    setTestQuestionForm(true);
  };
  const handleTestQuestionFormClose = () => {
    setSubejctType('');
    setTestQuestionForm(false);
  };
  const handleAddTestQuestion=() => {
    let err = {...testQuestionError};
    if(testQuestion.question.length===0){
        err.question = true;
    }else err.question = false;
    if(testQuestion.answer.length===0){
        err.answer = true;
    }else err.answer = false;
    if(testQuestion.options[0].a.length===0){
        err.options[0].a = true;
    }else err.options[0].a = false;
    if(testQuestion.options[0].b.length===0){
        err.options[0].b = true;
    }else err.options[0].b = false;
    if(testQuestion.options[0].c.length===0){
        err.options[0].c = true;
    }else err.options[0].c = false;
    if(testQuestion.options[0].d.length===0){
        err.options[0].d = true;
    }else err.options[0].d = false;
    setTestQuestionError(err)
    if(err.question||err.answer||err.options[0].a||err.options[0].b||err.options[0].c||err.options[0].d)return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
    var test = data.testData;
    for(const e of test){
        if(e.subject===subjectType){
            e.questions = e.questions.concat(testQuestion);
        }
    }
    setData({ ...data, testData: test});
    setTestQuestion({question:'',options:[{a:'',b:'',c:'',d:''}],answer:''});
    setTestQuestionForm(false);
    }
    const testQuestionOnChange = (type) => (event,value) =>{
        setTestQuestionError({...testQuestionError,[type]:false});
        setTestQuestion({ ...testQuestion, [type]: type==="answer"?value:event.target.value});   
    }
    const testQuestionOptionsOnChange = (type) => (event) =>{
        var err = {...testQuestionError};
        if(type==='a')err.options[0].a = false;
        if(type==='b')err.options[0].b = false;
        if(type==='c')err.options[0].c = false;
        if(type==='d')err.options[0].d = false;
        var options = {...testQuestion};
        if(type==='a')options.options[0].a = event.target.value
        if(type==='b')options.options[0].b = event.target.value
        if(type==='c')options.options[0].c = event.target.value
        if(type==='d')options.options[0].d = event.target.value
        setTestQuestionError(err);
        setTestQuestion(options);   
    }


    const handleDeleteQuestion=(sub,item)=>{
        setSubejctType(sub);
        var test = data.testData;
        for(const e of test){
            if(e.subject===subjectType){
                e.questions = e.questions.filter(d=>d.question !== item);
            }
        }
        setData({ ...data, testData: test});
    }






  const handleDelete=(type,item)=>{
      var deleteData;
      var deleteOtherData;
      switch(type){
        case "onlineModeCities":
            deleteData = data.onlineModeCities;
            deleteData = deleteData.filter(items=>items.city !== item);
            setData({ ...data, onlineModeCities: deleteData});
            break;
        case "offlineModeCities":
            deleteData = data.offlineModeCities;
            deleteData = deleteData.filter(items=>items.city !== item);
            setData({ ...data, offlineModeCities: deleteData});
            break;
        case "slotData":
            deleteData = data.slotData;
            deleteOtherData = data.resultData;
            deleteData = deleteData.filter(items=>items.slotNumber !== item);
            deleteOtherData = deleteOtherData.filter(items=>items.slotNumber !== item);
            setData({ ...data, slotData: deleteData,resultData: deleteOtherData});
            break;
        case "streamData":
            deleteData = data.streamData;
            deleteOtherData = data.testData;
            var allSubjects = [];
            deleteData = deleteData.filter(items=>items.streamNumber !== item);
            for(const element of deleteData){
                for(const sub of element.subjects){
                    if(allSubjects.some(d=>d===sub.subject)){}
                    else allSubjects.push(sub.subject)
                }
            }
            var newTest = [];
            for(const e of deleteOtherData){
                for(const sub of allSubjects){
                    if(e.subject === sub){
                        newTest.push(e)
                    }
                }
            }
            setData({ ...data, streamData: deleteData,testData:newTest});
            break;
        case "resultData":
            deleteData = data.resultData;
            deleteData = deleteData.filter(items=>items.slotNumber !== item);
            setData({ ...data, resultData: deleteData});
            break;
        
        default:
            break;
      }

  }




  useEffect(()=>{
    if(editExam.isSuccess){
      var examData = editExam.data.responseData;
      var tempEditData = {...data};
      tempEditData.name = examData.name;
      tempEditData.short = examData.short;
      tempEditData.level = examData.level;
      tempEditData.year = examData.year;
      tempEditData.conductedBy = examData.conductedBy;
      tempEditData.admissionLevel = examData.admissionLevel;
      tempEditData.mode = examData.mode;
      var onlineCities = [];
      var offlineCities = [];
      if(examData.mode.includes('online')){
        examData.onlineModeCities.forEach(item=>{
            var {_id,...obj} = item
            onlineCities.push(obj)
        })
      }
      if(examData.mode.includes('offline')){
        examData.offlineModeCities.forEach(item=>{
            var {_id,...obj} = item
            offlineCities.push(obj)
        })
      }
      tempEditData.onlineModeCities = onlineCities;
      tempEditData.offlineModeCities = offlineCities;
      tempEditData.ageLimit = examData.ageLimit;
      tempEditData.totalSlots = examData.totalSlots;
      var slotData = [];

      examData.slotData.forEach(item=>{
          var {_id,...obj}  = item;
          slotData.push(obj);
      })
      tempEditData.slotData = slotData;
      tempEditData.eligibity = examData.eligibity;
      tempEditData.totalStreams = examData.totalStreams;
      var streamData = [];

      examData.streamData.forEach(item=>{
          var {_id,...obj}  = item;
          var streamNumber = obj.streamNumber;
          var subjects = [];
          var eligibity = [];
          obj.subjects.forEach(sub=>{
            var {_id,...obj}  = sub;
              subjects.push(obj)
          })
          obj.eligibity.forEach(eli=>{
            var {_id,...obj}  = eli;
              eligibity.push(obj)
          })
          streamData.push({streamNumber:streamNumber,subjects:subjects,eligibity:eligibity});
      });
      tempEditData.streamData = streamData;
      tempEditData.totalMarks = examData.totalMarks;
      tempEditData.totalTime = examData.totalTime;
      
      var resultData = [];

      examData.resultData.forEach(item=>{
          var {_id,...obj}  = item;
          resultData.push(obj);
      })
      tempEditData.resultData = resultData;
      tempEditData.admissionDate = examData.admissionDate;
      tempEditData.topExam = examData.topExam;

      var testData = [];

      examData.testData.testData.forEach(item=>{
          var {_id,...obj}  = item;
          var subject = obj.subject;
          var questions = [];
          obj.questions.forEach(que=>{
            var {_id,...obj}  = que;
            questions.push(obj)
          })
          testData.push({subject:subject,questions:questions});
          
      })
      tempEditData.testData = testData;
      setData(tempEditData);
    }
  },[editExam]);

    

      const onChange = (type) =>(event,value)=>{
        event = event.target.value;
        var tempData = {...data};
        var err = {...error};
        switch(type){
          case 'name':
            tempData.name = event;
            err.errors.name = false;
            break;
          case 'short':
            tempData.short = event;
            err.errors.short = false;
            break;
          case 'level':
            tempData.level = event;
            err.errors.level = false;
            break;
          case 'year':
            tempData.year = event;
            err.errors.year = false;
            break;
          case 'conductedBy':
            tempData.conductedBy = event;
            err.errors.conductedBy = false;
            break;
          case 'admissionLevel':
            tempData.admissionLevel = event;
            err.errors.admissionLevel = false;
            break;
          case 'ageLimit':
            tempData.ageLimit = event;
            err.errors.ageLimit = false;
            break;
          case 'totalMarks':
            tempData.totalMarks = event;
            err.errors.totalMarks = false;
            break;
          case 'totalTime':
            tempData.totalTime = event;
            err.errors.totalTime = false;
            break;
          case 'eligibity':
            tempData.eligibity = value;
            err.errors.eligibity = false;
            break;
          case 'admissionDate':
            tempData.admissionDate = event;
            err.errors.admissionDate = false;
            break;
          default:
            break;
        }
        setError(err)
        setData(tempData)
      }

      const OnSubmit = ()=>{
        var err = {...error};
        var isError = false;
        if(data.name.length === 0){
          err.errors.name = true;
          isError = true;
        }if(data.short.length === 0){
          err.errors.short = true;
          isError = true;
        }if(data.level.length === 0){
          err.errors.level = true;
          isError = true;
        }if(data.year.length === 0){
          err.errors.year = true;
          isError = true;
        }if(data.conductedBy.length === 0){
          err.errors.conductedBy = true;
          isError = true;
        }if(data.admissionLevel.length === 0){
          err.errors.admissionLevel = true;
          isError = true;
        }if(data.ageLimit.length === 0){
          err.errors.ageLimit = true;
          isError = true;
        }if(data.totalMarks.length === 0){
          err.errors.totalMarks = true;
          isError = true;
        }if(data.totalTime.length === 0){
          err.errors.totalTime = true;
          isError = true;
        }if(data.eligibity.length === 0){
          err.errors.eligibity = true;
          isError = true;
        }if(data.mode.length === 0){
          err.errors.mode = true;
          isError = true;
        }
        if(data.mode.includes('online')){
            if(data.onlineModeCities.length===0){
                err.errors.onlineModeCities = true;
                isError = false;
            }
        }
        if(data.mode.includes('offline')){
            if(data.offlineModeCities.length===0){
                err.errors.offlineModeCities = true;
                isError = false;
            }
        }
        if(data.admissionDate.length === 0){
          err.errors.admissionDate = true;
          isError = true;
        }if(data.slotData.length === 0){
          err.errors.slotData = true;
          isError = true;
        }if(data.streamData.length === 0){
          err.errors.streamData = true;
          isError = true;
        }
        if(isError === true){
          setError(err);
          return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        }
        if(props.props === 'edit'){
          var editData = {data:data,id:id}
          updateExam(editData)
        }else{
          addExam(data);
        }
        
      }

      

    const handleMode = (type) => (item,value)=>{
        var mode = data.mode;
        if(value){
            mode.push(type)
          }else{
            mode = mode.filter(data => data !== type )
          }
        setData({ ...data, mode: mode });
      } 
      const handleExamRanking = (item,value)=>{
        setData({ ...data, topExam: value });
      } 

     

useEffect(()=>{
    if(exam.isError){
        var e = typeof(exam.error.data.responseData) === 'object'?exam.error.data.responseData[0].message:exam.error.data.responseData
        toast.error(e??'Something Went Wrong',{position:"top-center",autoClose:1000})
      }
},[exam.isError,exam.error])

if(updatedExam.isError){
  toast.error(updatedExam.error.data.responseData,{position:"top-center",autoClose:1000})
}

if(exam.isSuccess || updatedExam.isSuccess){
  toast.success(props.props==='edit'?'Exam Edited Successfully':'Exam Added Successfully',{position:"top-center",autoClose:1000})
  return <Navigate to={'/exam'} replace={true} state={null} />
}else
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll',padding:'10px'}}>
          <div className="header">{props.props==='edit'?'Edit Exam':'Add New Exam'}</div>
          <Box component="form">
          <Card className='datas'>
            <h4 className='subheader'>Basic Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.name}
                error={error.errors.name}
                helperText={error.errors.name?'Field is required':null}
                onChange={onChange('name')}
                label="Name"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.short}
                error={error.errors.short}
                helperText={error.errors.short?'Field is required':null}
                onChange={onChange('short')}
                label="Short"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.level}
                error={error.errors.level}
                helperText={error.errors.level?'Field is required':null}
                onChange={onChange('level')}
                label="Level"
              />

              <TextField
                style={{width:'70%',margin:'10px'}}
                required
                value={data.year}
                error={error.errors.year}
                helperText={error.errors.year?'Field is required':null}
                onChange={onChange('year')}
                label="Year"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.conductedBy}
                error={error.errors.conductedBy}
                helperText={error.errors.conductedBy?'Field is required':null}
                onChange={onChange('conductedBy')}
                label="Conducted By"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.admissionLevel}
                error={error.errors.admissionLevel}
                helperText={error.errors.admissionLevel?'Field is required':null}
                onChange={onChange('admissionLevel')}
                label="Admission Level"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.ageLimit}
                error={error.errors.ageLimit}
                helperText={error.errors.ageLimit?'Field is required':null}
                onChange={onChange('ageLimit')}
                label="Age Limit"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.totalMarks}
                error={error.errors.totalMarks}
                helperText={error.errors.totalMarks?'Field is required':null}
                onChange={onChange('totalMarks')}
                label="Total Marks"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.totalTime}
                error={error.errors.totalTime}
                helperText={error.errors.totalMarks?'Field is required':null}
                onChange={onChange('totalTime')}
                label="Total Time"
              />
              <Autocomplete
                    style={{width:'50%',margin:'10px'}}
                    multiple
                    value={data.eligibity}
                    options={[]}
                    defaultValue={[]}
                    onChange={onChange('eligibity')}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={error.errors.eligibity}
                        helperText={error.errors.eligibity?'Field is required':null}
                        {...params}
                        required
                        label="Eligibility[ ]"
                      />
                    )}
              />

            <FormControlLabel value={data.topExam} style={{marginLeft:'10px'}} control={<Checkbox checked={data.topExam} onChange={handleExamRanking}/>} label="Top School" />

            </Box>
            </Card>

            <Card className='datas' style={{marginTop:'10px'}}>
                <h4 className='subheader'>Mode</h4>
                <FormControlLabel value={data.mode.includes('online')} style={{marginLeft:'10px'}} control={<Checkbox checked={data.mode.includes('online')} onChange={handleMode('online')}/>} label="Online" />
                <FormControlLabel value={data.mode.includes('offline')} style={{marginLeft:'10px'}} control={<Checkbox checked={data.mode.includes('offline')} onChange={handleMode('offline')}/>} label="Offline" />
            </Card>
            

            


            
            
            {data.mode.map(mode=>
                <Card className='datas' style={{marginTop:'10px'}}>
              <Box className='multipleInputs' >
                <h4 className='subheader'> {capitalize(mode)} Mode City</h4>
                <Button variant='contained' onClick={()=>handleCityFormOpen(mode)}>Add </Button>
              </Box> 
              {(mode==='online'?data.onlineModeCities.length:data.offlineModeCities.length)
              ?<List component="div" style={{maxHeight:'500px',overflow:'scroll'}}>
                {mode==='online'?data.onlineModeCities.map((item)=>{
                  return <Box className='multipleInputs' >
                            <ListItemText component="span" secondaryTypographyProps={{component:'span'}} key={item.zip} primary={item.city} secondary={item.zip} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}}  />
                            <IconButton color='error' onClick={()=>handleDelete('onlineModeCities',item.city)}>
                                <Delete />
                            </IconButton>
                        </Box>
              }):data.offlineModeCities.map((item)=>{
                return <Box className='multipleInputs' >
                            <ListItemText component="span" secondaryTypographyProps={{component:'span'}} key={item.zip} primary={item.city} secondary={item.zip} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}}  />
                            <IconButton color='error' onClick={()=>handleDelete('offlineModeCities',item.city)}>
                                <Delete />
                            </IconButton>
                        </Box>
            })}
          </List>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No {capitalize(mode)} Mode City Added</Box>
                </Center>
                </Card>}

                <Dialog open={cityForm} onClose={handleCityFormClose}>
                <DialogTitle>Add {capitalize(mode)} Mode City</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new City.
                  </DialogContentText>
                  <TextField
                    error={citiesDataError.city}
                    helperText={citiesDataError.city?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={cityDataOnChange('city')}
                    label="City"
                    value={citiesData.city}
                    fullWidth
                  />
                  <TextField
                    error={citiesDataError.zip}
                    helperText={citiesDataError.zip?'Enter Valid Zip':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={cityDataOnChange('zip')}
                    label="Zip"
                    value={citiesData.zip}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCityFormClose}>Cancel</Button>
                  <Button onClick={handleAddCityData}>Add</Button>
                </DialogActions>
              </Dialog>
              </Card>)}

            <div className='datas' style={{marginTop:'10px'}}>
                <h4 className='subheader'>Addmission Date</h4>
                <TextField
                    style={{width:'50%',margin:'10px'}}
                    required
                    value={data.admissionDate}
                    error={error.errors.admissionDate}
                    helperText={error.errors.admissionDate?'Field is required':null}
                    type='date'
                    onChange={onChange('admissionDate')}
                />
            </div>

            


              <Card className='datas' style={{marginTop:'10px'}}>
              <Box className='multipleInputs' >
                <h4 className='subheader'>Stream Data{` (${data.streamData.length})`}</h4>
                <Button variant='contained' onClick={handleStreamDataFormOpen}>Add </Button>
              </Box> 
              {data.streamData.length
              ?<List component="div" style={{maxHeight:'500px',overflow:'scroll'}}>
                {data.streamData.map((item)=>{
                  return <Box className='multipleInputs' >
                            <ListItemText component="span" secondaryTypographyProps={{component:'span'}} key={item.streamNumber} primary={'Stream '+item.streamNumber} secondary={
                                <>
                                    <List component="div" style={{backgroundColor:'#54ee40',padding:'10px',borderRadius:'10px',marginBottom:'10px'}}>
                                        <ListItemText component="span" secondaryTypographyProps={{component:'span'}} primary={'Eligibility'}/>
                                        {item.eligibity.map(sub=>{
                                            return <ListItemText component="span" secondaryTypographyProps={{component:'span',color:'black'}} key={sub.subject} secondary={sub.subject+` (${sub.marks})`}/>
                                        })}
                                    </List>
                                    <List component="div" style={{backgroundColor:'#408bee',padding:'10px',borderRadius:'10px'}}>
                                        <ListItemText  component="span" primary={'Subjects'}/>
                                        {item.subjects.map(sub=>{
                                            return <List key={sub.subject} component="div" style={{backgroundColor:'#7eadeb',padding:'10px',borderRadius:'10px',marginBottom:'10px'}}>
                                                        <ListItemText component="span" secondaryTypographyProps={{component:'span',color:'black'}} secondary={sub.subject}/>
                                                        <ListItemText component="span" secondaryTypographyProps={{somponent:'span',color:'black'}} secondary={`Questions: ${sub.noOfQuestion}`}/>
                                                        <ListItemText component="span" secondaryTypographyProps={{component:'span',color:'black'}} secondary={`Marks: ${sub.marksPerSubject}`}/>
                                                    </List>
                                        })}
                                    </List>
                                </>
                            } style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} />
                            <IconButton color='error' onClick={()=>handleDelete('streamData',item.streamNumber)}>
                                <Delete />
                            </IconButton>
                        </Box>
              })}

              
                </List>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Streams Added</Box>
                </Center>
                </Card>}

                <Dialog open={streamDataForm} onClose={handleStreamDataFormClose}>
                <DialogTitle>Add Stream Data</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Stream.
                  </DialogContentText>
                  <Card className='datas' style={{marginTop:'10px',padding:'10px'}}>
                  <Box className='multipleInputs' style={{display:'flex',justifyContent:'space-between'}} >
                    <h4 className='subheader'>Eligibility{` (${streamData.eligibity.length})`}</h4>
                    <Button variant='contained' size='small' onClick={handleEligibilityFormOpen}>Add </Button>
                  </Box> 
                    <div className='datas' style={{padding:'10px',maxHeight:'300px',overflow:'scroll'}}>
                    {streamDataError.eligibity && <p>{'Please Add Elibility Data'}</p>}
                    {streamData.eligibity.length
                    ?<List component="div" style={{maxHeight:'500px',overflow:'scroll'}}>
                        {streamData.eligibity.map((item)=>{
                        return <ListItemText 
                                component="span" secondaryTypographyProps={{component:'span'}}
                                key={item.subject+item.marks}
                                primary={'Subject '+item.subject} 
                                secondary={'Marks '+item.marks} 
                                style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} 
                                />
                        })}
                    </List>
                    :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                        <Center>
                            <Box>No Eligibility Added</Box>
                        </Center>
                        </Card>}
                        <Dialog open={eligibilityForm} onClose={handleEligibilityFormOpen}>
                            <DialogTitle>Add Eligibility</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                All the fields are required for adding new Eligibility.
                            </DialogContentText>
                            <TextField
                                error={eligibityError.subject}
                                helperText={eligibityError.subject?'Field is required':null}
                                style={{marginBottom:'10px',}}
                                required
                                onChange={EligibilityOnChange('subject')}
                                label="Subject"
                                value={eligibity.subject}
                                fullWidth
                            />
                            <TextField
                                error={eligibityError.marks}
                                helperText={eligibityError.marks?'Field is required':null}
                                style={{marginBottom:'10px',}}
                                required
                                onChange={EligibilityOnChange('marks')}
                                label="Marks"
                                value={eligibity.marks}
                                fullWidth
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleEligibilityFormClose}>Cancel</Button>
                            <Button onClick={handleAddEligibility}>Add</Button>
                            </DialogActions>
                        </Dialog>
                        </div>
                    
                </Card>
                  <Card className='datas' style={{marginTop:'10px',padding:'10px'}}>
                  <Box className='multipleInputs' style={{display:'flex',justifyContent:'space-between'}}>
                    <h4 className='subheader'>Subjects{` (${streamData.subjects.length})`}</h4>
                    <Button variant='contained' size='small' onClick={handleSubjectFormOpen}>Add </Button>
                  </Box> 
                    <div className='datas' style={{padding:'10px',maxHeight:'300px',overflow:'scroll'}}>
                        {streamDataError.subjects && <p>{'Please Add Subject Data'}</p>}
                    {streamData.subjects.length
                    ?<List component="div" style={{maxHeight:'500px',overflow:'scroll'}}>
                        {streamData.subjects.map((item)=>{
                        return <ListItemText 
                                component="span" secondaryTypographyProps={{component:'span'}}
                                key={item.subject+item.noOfQuestion+item.marksPerSubject}
                                primary={'Subject '+item.subject} 
                                secondary={
                                    <>
                                    <ListItemText component="span" secondaryTypographyProps={{component:'span'}} secondary={'Questions: '+item.noOfQuestion}/>
                                    <ListItemText component="span" secondaryTypographyProps={{component:'span'}} secondary={'Marks: '+item.marksPerSubject}/>
                                    </>
                                } 
                                style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} 
                                />
                        })}
                    </List>
                    :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                        <Center>
                            <Box>No Subject Added</Box>
                        </Center>
                        </Card>}
                        <Dialog open={subjectForm} onClose={handleSubjectFormOpen}>
                            <DialogTitle>Add Subject</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                All the fields are required for adding new Subject.
                            </DialogContentText>
                            <TextField
                                error={subjectError.subject}
                                helperText={subjectError.subject?'Field is required':null}
                                style={{marginBottom:'10px',}}
                                required
                                onChange={SubjectOnChange('subject')}
                                label="Subject"
                                value={subject.subject}
                                fullWidth
                            />
                            <TextField
                                error={subjectError.noOfQuestion}
                                helperText={subjectError.noOfQuestion?'Field is required':null}
                                style={{marginBottom:'10px',}}
                                required
                                onChange={SubjectOnChange('noOfQuestion')}
                                label="Questions"
                                value={subject.noOfQuestion}
                                fullWidth
                            />
                            <TextField
                                error={subjectError.marksPerSubject}
                                helperText={subjectError.marksPerSubject?'Field is required':null}
                                style={{marginBottom:'10px',}}
                                required
                                onChange={SubjectOnChange('marksPerSubject')}
                                label="Marks"
                                value={subject.marksPerSubject}
                                fullWidth
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleSubjectFormClose}>Cancel</Button>
                            <Button onClick={handleAddSubject}>Add</Button>
                            </DialogActions>
                        </Dialog>
                            </div>
                        </Card>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleStreamDataFormClose}>Cancel</Button>
                        <Button onClick={handleAddStreamData}>Add</Button>
                        </DialogActions>
                    </Dialog>
              </Card>


              <Card className='datas' style={{marginTop:'10px'}}>
              <Box className='multipleInputs' >
                <h4 className='subheader'>Slot Data{` (${data.slotData.length})`}</h4>
                <Button variant='contained' onClick={handleSlotDataFormOpen}>Add </Button>
              </Box> 
              {data.slotData.length
              ?<List component="div" style={{maxHeight:'500px',overflow:'scroll'}}>
                {data.slotData.map((item)=>{
                return <Box className='multipleInputs' >
                            <ListItemText  component="span" secondaryTypographyProps={{component:'span'}} key={item.slotNumber} primary={'Slot '+item.slotNumber} secondary={
                                <List component="div">
                                    <ListItemText component="span" secondaryTypographyProps={{component:'span'}} secondary={'Start Date Time: '+getDate(item.startDate) + ' at '+ getTime(item.startDate)}/>
                                    <ListItemText  component="span" secondaryTypographyProps={{component:'span'}} secondary={'End Date Time: '+getDate(item.endDate) + ' at '+ getTime(item.endDate)}/>
                                </List>
                            } style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}}  />
                            <IconButton color='error' onClick={()=>handleDelete('slotData',item.slotNumber)}>
                                <Delete />
                            </IconButton>
                        </Box>
                
                })}
                </List>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Slots Added</Box>
                </Center>
                </Card>}

                <Dialog open={slotDataForm} onClose={handleSlotDataFormClose}>
                <DialogTitle>Add Slot Data</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Slot.
                  </DialogContentText>
                  <div className='datas' style={{marginTop:'10px',padding:'10px'}}>
                    <h4 className='subheader'>Start Date</h4>
                    <TextField
                        fullWidth
                        required
                        value={slotData.startDate}
                        error={slotDataError.startDate}
                        helperText={slotDataError.startDate?'Field is required':null}
                        type='datetime-local'
                        onChange={slotDataOnChange('startDate')}
                    />
                  </div>
                  <div className='datas' style={{marginTop:'10px',padding:'10px'}}>
                    <h4 className='subheader'>End Date</h4>
                    <TextField
                        fullWidth
                        required
                        value={slotData.endDate}
                        error={slotDataError.endDate}
                        helperText={slotDataError.endDate?'Field is required':null}
                        type='datetime-local'
                        onChange={slotDataOnChange('endDate')}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleSlotDataFormClose}>Cancel</Button>
                  <Button onClick={handleAddSlotData}>Add</Button>
                </DialogActions>
              </Dialog>
              </Card>




                  <Card className='datas' style={{marginTop:'10px'}}>
              <Box className='multipleInputs' >
                <h4 className='subheader'>Result Data{` (${data.resultData.length})`}</h4>
                <Button disabled={data.resultData.length>=data.slotData.length} variant='contained' onClick={handleResultDataFormOpen}>Add </Button>
              </Box> 
              {data.resultData.length
              ?<List component="div" style={{maxHeight:'500px',overflow:'scroll'}}>
                {data.resultData.map((item)=>{
                return <Box className='multipleInputs' >
                            <ListItemText component="span" secondaryTypographyProps={{component:'span'}}  primary={'Slot '+item.slotNumber} style={{margin:'10px',backgroundColor:kAppBarColor,padding:'10px',borderRadius:'10px'}} secondary={
                                <>
                                    <ListItemText component="span" secondaryTypographyProps={{component:'span'}}  secondary={'Result Date: '+getDate(item.resultDate)}/>
                                    <ListItemText component="span" secondaryTypographyProps={{component:'span'}}  secondary={<p>Link: <a rel="noopener noreferrer" target='_blank' href={item.link}>{item.link}</a></p>}/>
                                </>
                                
                            }/>
                            <IconButton color='error' onClick={()=>handleDelete('resultData',item.slotNumber)}>
                                <Delete />
                            </IconButton>
                        </Box>
                
                })}
                </List>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Result Data Added</Box>
                </Center>
                </Card>}

                <Dialog open={resultDataForm} onClose={handleResultDataFormClose}>
                <DialogTitle>Add Result Data</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Result.
                  </DialogContentText>
                  <Autocomplete
                        fullWidth
                        style={{marginBottom:'10px',}}
                        value={resultData.slotNumber??null}
                        onChange={resultDataOnChange('slotNumber')}
                        getOptionLabel={(option) => option} 
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionDisabled={(option) =>
                            data.resultData.some(data=>data.slotNumber===option.split(" ")[1])
                          }
                        filterOptions={(option,param)=>{
                        var filtered = [];
                        for(const element of option){
                            filtered.push("Slot "+element)
                        }
                            return filtered;
                        }}
                        options={data.slotData.map(item=>item.slotNumber)}
                        renderInput={(params) => (
                        <TextField
                            required
                            error={resultDataError.slotNumber}
                            helperText={resultDataError.slotNumber?'Field is required':null}
                            {...params}
                            label={'Select Stream'}
                            InputProps={{
                            ...params.InputProps,
                            }}
                        />
                        )}
                    />
                  <div className='datas' >
                    <h4 className='subheader'>Result Date</h4>
                    <TextField
                        style={{marginBottom:'10px',}}
                        fullWidth
                        required
                        value={resultData.resultDate}
                        error={resultDataError.resultDate}
                        helperText={resultDataError.resultDate?'Field is required':null}
                        type='datetime-local'
                        onChange={resultDataOnChange('resultDate')}
                    />
                  </div>
                    <TextField
                        fullWidth
                        style={{marginBottom:'10px',}}
                        required
                        value={resultData.link}
                        error={resultDataError.link}
                        helperText={resultDataError.link?'Field is required':null}
                        label='Link'
                        onChange={resultDataOnChange('link')}
                    />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleResultDataFormClose}>Cancel</Button>
                  <Button onClick={handleAddResultData}>Add</Button>
                </DialogActions>
              </Dialog>
              </Card>



              <Card className='datas' style={{marginTop:'10px'}}>
                <h4 className='subheader'>Test Data{` (${data.testData.length})`}</h4>
              {data.testData.length
              ?<List component="div" >
                {data.testData.map((test)=>{
                
                return <List
                      sx={{ width: '100%',maxHeight:'500px',overflow:'scroll', bgcolor: 'background.paper',border:'1px solid grey' }}
                      component="nav"
                      subheader={
                          <ListSubheader component="div">
                              <Box className='multipleInputs' >
                                {test.subject+` (${test.questions.length})`}
                                <Button size="small" variant='contained' onClick={()=>handleTestQuestionFormOpen(test.subject)}>Add Questions</Button>
                            </Box> 
                          </ListSubheader>
                      }
                      >
                          {test.questions.map((questions,index)=><ExpandableList sub={test.subject} data={questions} index={index} action={(sub,item)=>handleDeleteQuestion(sub,item)}/>)}

                      </List>
              })}
                </List>
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Test Data Added</Box>
                </Center>
                </Card>}

                <Dialog open={testQuestionForm} onClose={handleTestQuestionFormClose}>
                <DialogTitle>Add Question for {subjectType}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Result.
                  </DialogContentText>
                    <TextField
                        fullWidth
                        style={{marginBottom:'10px',}}
                        required
                        value={testQuestion.question}
                        error={testQuestionError.question}
                        helperText={testQuestionError.question?'Field is required':null}
                        label='Question'
                        onChange={testQuestionOnChange('question')}
                    />
                    <TextField
                        fullWidth
                        style={{marginBottom:'10px',}}
                        required
                        value={testQuestion.options[0].a}
                        error={testQuestionError.options[0].a}
                        helperText={testQuestionError.options[0].a?'Field is required':null}
                        label='Option A'
                        onChange={testQuestionOptionsOnChange('a')}
                    />
                    <TextField
                        fullWidth
                        style={{marginBottom:'10px',}}
                        required
                        value={testQuestion.options[0].b}
                        error={testQuestionError.options[0].b}
                        helperText={testQuestionError.options[0].b?'Field is required':null}
                        label='Option B'
                        onChange={testQuestionOptionsOnChange('b')}
                    />
                    <TextField
                        fullWidth
                        style={{marginBottom:'10px',}}
                        required
                        value={testQuestion.options[0].c}
                        error={testQuestionError.options[0].c}
                        helperText={testQuestionError.options[0].c?'Field is required':null}
                        label='Option C'
                        onChange={testQuestionOptionsOnChange('c')}
                    />
                    <TextField
                        fullWidth
                        style={{marginBottom:'10px',}}
                        required
                        value={testQuestion.options[0].d}
                        error={testQuestionError.options[0].d}
                        helperText={testQuestionError.options[0].d?'Field is required':null}
                        label='Option D'
                        onChange={testQuestionOptionsOnChange('d')}
                    />
                    <Autocomplete
                        fullWidth
                        style={{marginBottom:'10px',}}
                        value={testQuestion.answer}
                        onChange={testQuestionOnChange('answer')}
                        getOptionLabel={(option) => option} 
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        options={["a","b","c","d"]}
                        renderInput={(params) => (
                        <TextField
                            required
                            error={testQuestionError.answer}
                            helperText={testQuestionError.answer?'Field is required':null}
                            {...params}
                            label={'Select Correct Annswer'}
                            InputProps={{
                            ...params.InputProps,
                            }}
                        />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleTestQuestionFormClose}>Cancel</Button>
                  <Button onClick={handleAddTestQuestion}>Add</Button>
                </DialogActions>
              </Dialog>
              </Card>

              
            
          
          <LoadingButton
                className='submitButton'
                size="small"
                loading={props.props==='edit'?updatedExam.isLoading:exam.isLoading}
                loadingIndicator={<CircularProgress size={'2ch'} />}
                variant="contained"
                onClick={OnSubmit}
              >
                {props.props==='edit'?'Update':'Create'}
              </LoadingButton>
          </Box>
        </div>
      </Container>
  )
}

export default ExamAdd