import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { kAppBarColor, kAppBarDark, kPrimaryColor } from '../../../const/const'
import { AuthSliceActions, checkAuth, fetchUserData, getError, getStatus, getUser, loginUser } from '../../../redux_store/slices/auth_slice';
import { uiSliceAction } from '../../../redux_store/slices/ui_slice';
import { mobile, tablet } from '../../../utils/responsive';
import ReactLoading from "react-loading";
import Logo from '../../header/Logo';
import { messages } from '../../pages_data/message.data';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
var validator = require('validator');



const Container = styled.div`
    background-color:${kAppBarColor};
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100vw;
    height:100vh;
    min-height:600px;
    position:absolute;
    z-index:3;
    
    
`;
const Graphic = styled.img`
  height:300px;
  width:300px;
  box-shadow: 0 0 2px 0.4px #000000e2;
  border-radius:100%;
  
`;
const BoldText = styled.p`
  font-weight:500;
  font-size:40px;
  color:black;
  ${mobile({
    'font-size':'30px'
      
    })};
    ${tablet({
    'font-size':'35px'
      
    })};
`;

const Loading = ({type}) => {
  return (
      <Container style={{backgroundColor:type?'#ffffff3b':kAppBarColor}}>
        <Graphic src={window.location.origin+`/logo.png`}></Graphic>
        <ReactLoading type="bubbles" color="#0000FF"
        height={150} width={80}></ReactLoading>
       
      </Container>
  )
}

export default Loading