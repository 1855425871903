import { Delete, Edit } from '@mui/icons-material';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, Link, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { useCreateCollegeMutation, useGetCityNamesQuery, useGetCollegeQuery, useGetStateNamesQuery, useUpdateCollegeMutation } from '../../redux_store/service/fetchData';
import {ScrollableListView} from '../../styles/ScrollableListView'
import { desktop, mobile, tablet } from '../../utils/responsive';
import { kAppBarColor } from '../../const/const';
import { LoadingButton } from '@mui/lab';
import { Navigate, useLocation } from 'react-router';
var validator = require('validator/es/lib/isEmail');
const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    .main{

      .header{
        font-size:1.5rem;
        font-weight:600;
      }
      .subheader{
        margin-top:20px;

      }
      .datas{
        padding:10px;


      }
      .multipleInputs{
        margin-top:10px;
        margin-bottom:10px;
        justify-content:space-between;
        justify-items:stretch;
        display:flex;

      }
      .column{
        display:flex;
        flex-direction:column;
      }

      .accreditationContainer{
      border-radius:20px;
      width:200px;
      height:200px;
      background-color:${kAppBarColor};
      margin:10px;
      position:relative;
      ${tablet({
        width:'150px',
        height:'150px'
    
      })}; 
      ${mobile({
        width:'100px',
        height:'100px'
      })};

      .grade{
        width:100%;
        height:70%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:600;
        font-size:80px;
        ${tablet({
          'font-size':'60px',
    
        })}; 
        ${mobile({
          'font-size':'40px',

        })};

      }
      .body{
        width:100%;
        height:30%;
        display:flex;
        align-self:center;
        text-align:center;
        justify-content:center;
        justify-items:center;
        align-items:center;
        align-content:center;
        font-weight:500;
        font-size:20px;
        ${tablet({
          'font-size':'15px',
    
        })}; 
        ${mobile({
          'font-size':'10px',

        })};

      }
    }
    .submitButton{
      margin-top:50px;
      margin-bottom:50px;
      width:100%;
    }
      }
`;

const Center = styled.div`
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  text-align:center;
`;



const programmeColumn = [
  { 
    field: 'name',
    headerName: 'Name',
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.id} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.name}</p>
      )

    }
  },
  {
    field: 'short',
    headerName: 'Short',
    width:150,
    //flex: 1.5,
    editable: false,
  },
  {
    field: 'programme',
    headerName: 'Programme',
    width:150,
    //flex: 1.5,
    editable: false,
  },
  {
    field: 'yearOfStarting',
    headerName: 'Starting Year',
    //flex: 1,
    //flex: 3,
    type:'number',
    width:150,
    editable: false
  },
  {
    field: 'admissionMode',
    headerName: 'Admission Mode',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
          return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
            {params.row.admissionMode.map(data=>{
              return <div key={data} style={{marginRight:'10px'}}>{data}</div>
          })}</div>
    }
  },
  {
    field: 'eligibility',
    headerName: 'Eligibility',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.eligibility.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'qualification',
    headerName: 'Qualification',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:400,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.qualification.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:300,
    editable: false,
    renderCell: (params)=>{
      return (
      <p key={params.row.type} style={{display:'flex',flexDirection:'column',overflow:'scroll'}}>{params.row.type}</p>
      )
    }
  },
  {
    field: 'fee',
    headerName: 'Fee',
    //flex: 1.3,
    overflow:'scroll',
    //flex: 3,
    width:500,
    editable: false,
    renderCell: (params)=>{
      return <div  style={{display:'flex',flexDirection:'row',overflow:'scroll'}}>
        {params.row.fee.map(data=>{
          return <div key={data} style={{marginRight:'10px'}}>{data}</div>
      })}</div>
    }
  },
  

];





function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}



const Accreditation = ({data,action})=>{

  return(
    <Card key={data.body} className='accreditationContainer'>
      <IconButton onClick={()=>action(data.body)}  aria-label="delete" style={{position:'absolute',top:'5px',right:'5px',color:'red'}}>
        <Delete />
      </IconButton>
      <div className='grade'>{data.grade}</div>
      <div className='body'>{data.body}</div>
    </Card>
  )
}

const RatingCard = ({data,action})=>{
  var color = kAppBarColor;
  if(data.rank >= 4){
    color = '#69e27d'
  }else if(data.rank >= 2.5){
    color = '#e47d1d'
  }else{
    color = '#f14c4c'
  }

  return(
    <Card key={data.body} className='accreditationContainer' style={{backgroundColor:color}}>
      <IconButton onClick={()=>action(data.body)} aria-label="delete" style={{position:'absolute',top:'5px',right:'5px',color:'black'}}>
        <Delete />
      </IconButton>
      <div className='grade'>{data.rank}</div>
      <div className='body'>{data.body}</div>
    </Card>
  )
}


const CollegeAdd = (props) => {
  console.log(props.props)
  const location = useLocation();
  const id = location.pathname.substring(15);
  const [request,useRequest] = useState(true);
  const editCollege = useGetCollegeQuery(id,{skip:request,refetchOnMountOrArgChange:true});
  const [openProgrammeForm, setOpenProgrammeForm] = useState(false);
  const [openAccreditationForm, setOpenAccreditationForm] = useState(false);
  const [openRankingForm, setOpenRankingForm] = useState(false);
  const [city,setCity] = useState(null)
  const [state,setState] = useState(null)
  const [editMode,setEditMode] = useState(false)
  const [editName,setEditName] = useState(null)
  const [currentItem,setCurrentItem] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  //const cities = useGetCityNamesQuery('',{skip:callCity});
  const cities = useGetCityNamesQuery();
  const states = useGetStateNamesQuery();
  const [addCollege,college] = useCreateCollegeMutation();
  const [updateCollege,updatedCollege] = useUpdateCollegeMutation();
  const SetRequest = ()=>{
    useRequest(false);
  }
  useEffect(()=>{
    if(props.props === 'edit'){
      SetRequest()
    }
  },[]);

  

  const [data, setData] = useState({
    basicDetails: {
      name:'',
      code:'',
      estd:''
    },
    locationDetails: {
      address:'',
      street:'',
      city:'',
      district:'',
      state:'',
      location:{
        type:"Point",
        coordinates:[0,0]
      }
    },
    contactDetails:{
      siteUrl:'',
      email:'',
      phone:''
    },
    discipline:'',
    ownership:'',
    category:'',
    type:'',
    isWomen:false,
    campusArea:'',
    facultyStrength:'',
    studentStrength:'',
    accreditation:[],
    ranking:[],
    programTypes:[],
    programs:{diploma:[],undergraduate:[],postgraduate:[],phd:[]},
    topCollegeRanking:{
      india:false,
      state:false,
      city:false
    }
  });
  const [programmeData,setPorgrammeData] = useState({
    name:'',
    short:'',
    programme:'',
    type:'',
    yearOfStarting:'',
    qualification:[],
    eligibility:[],
    fee:[],
    admissionMode:[]
  });
  const [accreditationData,setAccreditationData] = useState({
    body:'',
    grade:'',
    validUpto:''
  });
  const [rankingData,setRankingData] = useState({
    body:'',
    rank:''
  });
  const [programmeDataError,setPorgrammeDataError] = useState({
    name:false,
    short:false,
    programme:false,
    type:false,
    yearOfStarting:false,
    qualification:false,
    eligibility:false,
    fee:false,
    admissionMode:false
  });
  const [accreditationDataError,setAccreditationDataError] = useState({
    body:false,
    grade:false,
    validUpto:false
  });
  const [rankingDataError,setRankingDataError] = useState({
    body:false,
    rank:false
  });


  useEffect(()=>{
    if(editCollege.isSuccess){
      var collegeData = editCollege.data.responseData;
      var tempEditData = {...data};
      var {_id,...obj} = collegeData.basicDetails;
      tempEditData.basicDetails = obj;
      var {_id,...obj} = collegeData.locationDetails;
      tempEditData.locationDetails = obj;
      var {_id,...obj} = collegeData.contactDetails;
      tempEditData.contactDetails = obj;
      tempEditData.discipline = collegeData.discipline;
      tempEditData.ownership = collegeData.ownership;
      tempEditData.category = collegeData.category;
      tempEditData.type = collegeData.type;
      tempEditData.campusArea = collegeData.campusArea;
      tempEditData.facultyStrength = collegeData.facultyStrength;
      tempEditData.studentStrength = collegeData.studentStrength;
      tempEditData.isWomen = collegeData.isWomen;
      tempEditData.topCollegeRanking = collegeData.topCollegeRanking;
      tempEditData.programTypes = collegeData.programTypes;
      var dip = [];
      var ug = [];
      var pg = [];
      var dr = [];
      var acc = [];
      var rnk = [];
      for(const element of collegeData.programs.programs){
        if(element.programType==='diploma'){
          element.program.forEach(item=>{
            var {_id,...obj} = item
            dip.push(obj)
          })
          tempEditData.programs.diploma = dip;
        }
        if(element.programType==='undergraduate'){
          element.program.forEach(item=>{
            var {_id,...obj} = item
            ug.push(obj)
          })
          tempEditData.programs.undergraduate = ug;
        }
        if(element.programType==='postgraduate'){
          element.program.forEach(item=>{
            var {_id,...obj} = item
            pg.push(obj)
          })
          tempEditData.programs.postgraduate = pg;
        }
        if(element.programType==='phd'){
          element.program.forEach(item=>{
            var {_id,...obj} = item
            dr.push(obj)
          })
          tempEditData.programs.phd = dr;
        }
        
      }
      collegeData.accreditation.forEach(item=>{
            var {_id,...obj} = item
            acc.push(obj)
      });
      tempEditData.accreditation = acc;
      collegeData.ranking.forEach(item=>{
            var {_id,...obj} = item
            rnk.push(obj);
          })
      tempEditData.ranking = rnk;
      //tempEditData.accreditation = collegeData.accreditation;
      //tempEditData.ranking = collegeData.ranking;
      setData(tempEditData);
      setCity(collegeData.locationDetails.district)
      setState(collegeData.locationDetails.state)
    }
  },[editCollege]);

    const [error, setError] = useState({
      errors:{
      name: false,
      code: false,
      estd: false,
      address: false,
      street: false,
      city: false,
      district: false,
      state: false,
      siteUrl: false,
      email: false,
      phone: false,
      discipline: false,
      ownership: false,
      category: false,
      type: false,
      campusArea: false,
      facultyStrength: false,
      studentStrength: false,
      programTypes: false,
      }
      
      });

      const onChange = (type) =>(event)=>{
        event = event.target.value;
        console.log(type,event);
        var tempData = {...data};
        var err = {...error};
        switch(type){
          case 'name':
            tempData.basicDetails.name = event;
            err.errors.name = false;
            break;
          case 'code':
            tempData.basicDetails.code = event;
            err.errors.code = false;
            break;
          case 'estd':
            tempData.basicDetails.estd = event;
            err.errors.estd = false;
            break;
          case 'address':
            tempData.locationDetails.address = event;
            err.errors.address = false;
            break;
          case 'street':
            tempData.locationDetails.street = event;
            err.errors.street = false;
            break;
          case 'city':
            tempData.locationDetails.city = event;
            err.errors.city = false;
            break;
          case 'email':
            tempData.contactDetails.email = event;
            err.errors.email = false;
            break;
          case 'phone':
            tempData.contactDetails.phone = event;
            err.errors.phone = false;
            break;
          case 'siteUrl':
            tempData.contactDetails.siteUrl = event;
            err.errors.siteUrl = false;
            break;
          case 'discipline':
            tempData.discipline = event;
            err.errors.discipline = false;
            break;
          case 'ownership':
            tempData.ownership = event;
            err.errors.ownership = false;
            break;
          case 'category':
            tempData.category = event;
            err.errors.category = false;
            break;
          case 'type':
            tempData.type = event;
            err.errors.type = false;
            break;
          case 'campusArea':
            tempData.campusArea = event;
            err.errors.campusArea = false;
            break;
          case 'facultyStrength':
            tempData.facultyStrength = event;
            err.errors.facultyStrength = false;
            break;
          case 'studentStrength':
            tempData.studentStrength = event;
            err.errors.studentStrength = false;
            break;
          default:
            break;
        }
        setError(err)
        setData(tempData)
      }

      const OnSubmit = (e)=>{
        e.preventDefault();
        var err = {...error};
        var isError = false;
        if(data.basicDetails.name.length === 0){
          err.errors.name = true;
          isError = true;
        }if(data.basicDetails.estd.length === 0){
          err.errors.estd = true;
          isError = true;
        }if(data.basicDetails.code.length === 0){
          err.errors.code = true;
          isError = true;
        }if(data.locationDetails.address.length === 0){
          err.errors.address = true;
          isError = true;
        }if(data.locationDetails.street.length === 0){
          err.errors.street = true;
          isError = true;
        }if(data.locationDetails.city.length === 0){
          err.errors.city = true;
          isError = true;
        }if(data.locationDetails.district.length === 0){
          err.errors.district = true;
          isError = true;
        }if(data.locationDetails.state.length === 0){
          err.errors.state = true;
          isError = true;
        }if(data.contactDetails.email.length === 0){
          err.errors.email = true;
          isError = true;
        }if(!validator.default(data.contactDetails.email)){
          err.errors.email = true;
          isError = true;
        }if(data.contactDetails.phone.length === 0){
          err.errors.phone = true;
          isError = true;
        }if(data.contactDetails.siteUrl.length === 0){
          err.errors.siteUrl = true;
          isError = true;
        }if(data.discipline.length === 0){
          err.errors.discipline = true;
          isError = true;
        }if(data.ownership.length === 0){
          err.errors.ownership = true;
          isError = true;
        }if(data.category.length === 0){
          err.errors.category = true;
          isError = true;
        }if(data.type.length === 0){
          err.errors.type = true;
          isError = true;
        }if(data.campusArea.length === 0){
          err.errors.campusArea = true;
          isError = true;
        }if(data.facultyStrength.length === 0){
          err.errors.facultyStrength = true;
          isError = true;
        }if(data.studentStrength.length === 0){
          err.errors.studentStrength = true;
          isError = true;
        }if(data.programTypes.length === 0){
          err.errors.programTypes = true;
          isError = true;
        }
        if(isError === true){
          toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
           setError(err);
        }
        if(props.props === 'edit'){
          console.log(data)
          var editData = {data:data,id:id}
          updateCollege(editData)
        }else{
          addCollege(data);
        }
        
      }

      const handleSelect = (type) => (item,value) => {
        var temp;
        var err = {...error};
        switch(type){
          case 'district':
            temp = data.locationDetails;
            temp.district = value
            setCity(value);
            err.errors.district = false;
            setError(err);
            setData({...data,locationDetails:temp})
            break
          case 'state':
            temp = data.locationDetails;
            temp.state = value
            setState(value)
            err.errors.state = false;
            setError(err);
            setData({...data,locationDetails:temp})
            break
          case 'discipline':
            break
          case 'ownership':
            break
          case 'category':
            break
          case 'type':
            break
          default:
            break;
        } 
      };

      useEffect(()=>{
            if(cities.isSuccess){
              var data = []
              for(const element of cities.data.responseData??[]){
                if(!data.includes(element)){
                  data.push(element)
                }   
              }
              setCityOptions(data??[])
            }
            if(states.isSuccess){
              var data2 = []
              for(const element of states.data.responseData??[]){
                if(!data2.includes(element)){
                  data2.push(element)
                }   
              }
              setStateOptions(data2??[])
            }
      },[states,cities]);


      const handleProgramme =(type)=> (event,value) => {
        var err = {...error};
        err.errors.programTypes = false;
        var programs = data.programTypes;
        if(value){
          programs.push(type)
        }else{
          programs = programs.filter(data => data !== type )
        }
        setData({ ...data, programTypes: programs });
        setError(err)
      };


      const handleProgrammeFormOpen =(type,item) => () => {
        type==='add'?setEditMode(false):setEditMode(true)
        if(type==='add'){
          setPorgrammeData({name:'',short:'',programme:'',type:'',yearOfStarting:'',qualification:[],eligibility:[],fee:[],admissionMode:[]})
        }
        setCurrentItem(item)
        setOpenProgrammeForm(true);
      };
      const handleAccredationFormOpen = () => {
        setOpenAccreditationForm(true);
      };
      const handleRankingFormOpen = () => {
        setOpenRankingForm(true);
      };

      
    
      const handleProgrammeFormClose = () => {
        setOpenProgrammeForm(false);
      };
      const handleAccredationFormClose = () => {
        setOpenAccreditationForm(false);
      };
      const handleRankingFormClose = () => {
        setOpenRankingForm(false);
      };

      const handleAddProgramme=() => {
        console.log(programmeData)
        delete programmeData.accreditationProgram;
        if(Object.values(programmeData).some(x=>x.length === 0)){
          let err = {...programmeDataError};
          Object.values(programmeData).some((x,index)=>{
            if(x.length === 0){
              //setPorgrammeDataError(...programmeDataError[Object.keys(programmeData)[index]]=false)
              err[Object.keys(programmeData)[index]] = true
            }else{
              //setPorgrammeDataError(...programmeDataError[Object.keys(programmeData)[index]]=true)
              err[Object.keys(programmeData)[index]] = false
            }
          })
          setPorgrammeDataError(err)

          return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        }
        let tempPrograms = data.programs;
        switch(currentItem){
          case "diploma":
            if(editMode===true){
              tempPrograms.diploma = tempPrograms.diploma.filter(item=>item.name.toString() !== editName.toString());
            }
            if(tempPrograms.diploma.some(item=>item.name.toString() === programmeData.name.toString() )){
              toast.error(`Item with name ${programmeData.name} already added`,{position:"top-center",autoClose:1000})
            }else{
            tempPrograms.diploma = tempPrograms.diploma.concat(programmeData)
            }
            break;
          case "undergraduate":
            if(editMode===true){
              tempPrograms.undergraduate = tempPrograms.undergraduate.filter(item=>item.name.toString() !== editName.toString());
            }
            if(tempPrograms.undergraduate.some(item=>item.name.toString() === programmeData.name.toString() )){
              toast.error(`Item with name ${programmeData.name} already added`,{position:"top-center",autoClose:1000})
            }else{
            tempPrograms.undergraduate =  tempPrograms.undergraduate.concat(programmeData)
            }
            break;
          case "postgraduate":
            if(editMode===true){
              tempPrograms.postgraduate = tempPrograms.postgraduate.filter(item=>item.name.toString() !== editName.toString());
            }
            if(tempPrograms.postgraduate.some(item=>item.name.toString() === programmeData.name.toString() )){
              toast.error(`Item with name ${programmeData.name} already added`,{position:"top-center",autoClose:1000})
            }else{
            tempPrograms.postgraduate =  tempPrograms.postgraduate.concat(programmeData)
            }
            break;
          case "phd":
            if(editMode===true){
              tempPrograms.phd = tempPrograms.phd.filter(item=>item.name.toString() !== editName.toString());
            }
            if(tempPrograms.phd.some(item=>item.name.toString() === programmeData.name.toString() )){
              toast.error(`Item with name ${programmeData.name} already added`,{position:"top-center",autoClose:1000})
            }else{
            tempPrograms.phd = tempPrograms.phd.concat(programmeData)
            }
            break;
            default:
              break

        }
        setData({ ...data, programs: tempPrograms });
        setPorgrammeData({name:'',short:'',programme:'',type:'',yearOfStarting:'',qualification:[],eligibility:[],fee:[],admissionMode:[]})
        setOpenProgrammeForm(false);
        console.log(data.programs)
        
      }


      const handleAddAccreditation=() => {
        if(Object.values(accreditationData).some(x=>x.length === 0)){
          let err = {...accreditationDataError};
          Object.values(accreditationData).some((x,index)=>{
            if(x.length === 0){
              err[Object.keys(accreditationData)[index]] = true
            }else{
              err[Object.keys(accreditationData)[index]] = false
            }
          })
          setAccreditationDataError(err)
          return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        }
        let tempAccreditation = data.accreditation;
        if(tempAccreditation.some(item=>item.body.toString() === accreditationData.body.toString() )){
              toast.error(`Accreditation with name ${accreditationData.body} already added`,{position:"top-center",autoClose:1000})
          }else{
              tempAccreditation = tempAccreditation.concat(accreditationData)
          }
            
        setData({ ...data, accreditation: tempAccreditation });
        setAccreditationData({grade:'',body:'',validUpto:''})
        setOpenAccreditationForm(false);
      }
      const handleDeleteAccreditation = (name) =>{
        let tempAccreditation = data.accreditation;
        tempAccreditation = tempAccreditation.filter(d=>d.body.toLowerCase() !== name.toLowerCase());
        setData({ ...data, accreditation: tempAccreditation });
      }

      const handleAddRanking=() => {
        if(Object.values(rankingData).some(x=>x.length === 0)){
          let err = {...rankingDataError};
          Object.values(rankingData).some((x,index)=>{
            if(x.length === 0){
              err[Object.keys(rankingData)[index]] = true
            }else{
              err[Object.keys(rankingData)[index]] = false
            }
          })
          setRankingDataError(err)
          return toast.error('Required filed should not be empty',{position:"top-center",autoClose:1000});
        }
        if( rankingData.rank > 5 || rankingData.rank < 1){
          setRankingDataError({...rankingDataError,rank:true})
          return toast.error('Plese Enter Valid Rank 1-5',{position:"top-center",autoClose:1000});
        }
        let tempRanking = data.ranking;
        if(tempRanking.some(item=>item.body.toString() === rankingData.body.toString() )){
              toast.error(`Ranking with name ${rankingData.body} already added`,{position:"top-center",autoClose:1000})
          }else{
            tempRanking = tempRanking.concat(rankingData)
          }
            
        setData({ ...data, ranking: tempRanking });
        setRankingData({rank:'',body:''})
        setOpenRankingForm(false);
      }
      const handleDeleteRanking = (name) =>{
        let tempAccreditation = data.ranking;
        tempAccreditation = tempAccreditation.filter(d=>d.body.toLowerCase() !== name.toLowerCase());
        setData({ ...data, ranking: tempAccreditation });
      }
      //programme from auto pick input on change
      const programmeDataOnChannge = (type,value) =>{
        setPorgrammeDataError({...programmeDataError,[type]:false});
        setPorgrammeData({ ...programmeData, [type]: value });

      }
      //programme from text input on change
      const programmeDataOnChange = (type) => (event) =>{
        setPorgrammeDataError({...programmeDataError,[type]:false});
        setPorgrammeData({ ...programmeData, [type]: event.target.value });
      }
      const accreditationDataOnChange = (type) => (event) =>{
        setAccreditationDataError({...accreditationDataError,[type]:false});
        setAccreditationData({ ...accreditationData, [type]: event.target.value });
      }
      const rankingDataOnChange = (type) => (event) =>{
        setRankingDataError({...rankingDataError,[type]:false});
        setRankingData({ ...rankingData, [type]: event.target.value });
      }
      const DeleteItem=(type,name)=>{
        type = type.split('_')[0];
        console.log(type,name)
        let tempPrograms = data.programs;
        switch(type){
          case "diploma":
            tempPrograms.diploma = tempPrograms.diploma.filter(item=>item.name !== name);
            break;
          case "undergraduate":
            tempPrograms.undergraduate =  tempPrograms.undergraduate.filter(item=>item.name !== name);
            break;
          case "postgraduate":
            tempPrograms.postgraduate =  tempPrograms.postgraduate.filter(item=>item.name !== name);
            break;
          case "phd":
            tempPrograms.phd = tempPrograms.phd.filter(item=>item.name !== name);
            break;
            default:
              break

        }
        setData({ ...data, programs: tempPrograms });
        setPorgrammeData({name:'',short:'',programme:'',type:'',yearOfStarting:'',qualification:[],eligibility:[],fee:[],admissionMode:[]})

      }
      const EditItem = (type,name) =>{
        type = type.split('_')[0];
        console.log(type,name)
        setEditName(name);
        console.log(data.programs[type].filter(item=>item.name.toString()===name.toString())[0])
        setPorgrammeData(data.programs[type].filter(item=>item.name.toString()===name.toString())[0])
        setPorgrammeDataError({...Object.keys(programmeDataError).some(x=>x=true)})
        handleProgrammeFormOpen('edit',type)();
        //setOpenProgrammeForm(true);
      }


      const handleTopCollegeRanking = (type) => (item,value)=>{
        let temp = data.topCollegeRanking;
        switch(type){
          case "india":
            temp.india = value;
            break;
          case "state":
            temp.state =  value;
            break;
          case "city":
            temp.city =  value;
            break;
            default:
              break
        }
        setData({ ...data, topCollegeRanking: temp });
      } 

      const actions = [
        { 
          field: 'action',
          headerName: 'ACTION',
          editable: false,
          sortable: false,
          width:200,
          justifyContent:'center',
          alignContent:'center',
          //flex: 2.2,
          renderCell: (params)=>{
            return (
            <div style={{width:'100%',display:'felx',flexDirection:'row',overflow:'scroll',textAlign:'center'}}>
              <Tooltip title='Edit'>
              <IconButton size="small" style={{color:'blue',marginRight:'10px'}} onClick={()=>EditItem(params.id,params.row.name)}>{<Edit/>}</IconButton>
              </Tooltip>
              <Tooltip title='Delete'>
              <IconButton size="small" style={{color:'red'}} onClick={()=>DeleteItem(params.id,params.row.name)} >{<Delete/>}</IconButton>
              </Tooltip>
      
            </div>)
      
          }
        },
      ]

console.log("runnning")
if(college.isError){
  toast.error(college.error.data.responseData,{position:"top-center",autoClose:1000})
}
if(updatedCollege.isError){
  toast.error(updatedCollege.error.data.responseData,{position:"top-center",autoClose:1000})
}

if(college.isSuccess || updatedCollege.isSuccess){
  toast.success('College Added',{position:"top-center",autoClose:1000})
  return <Navigate to={'/colleges'} replace={true} state={null} />
}else
  return (
      <Container>
        <div  className='main' style={{height:'100%',overflow:'scroll',padding:'10px'}}>
          <div className="header">Add New College</div>
          <Box component="form">
          <Card className='datas'>
            <h4 className='subheader'>Basic Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.basicDetails.name}
                error={error.errors.name}
                helperText={error.errors.name?'Field is required':null}
                onChange={onChange('name')}
                label="Name"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.basicDetails.code}
                error={error.errors.code}
                helperText={error.errors.code?'Field is required':null}
                onChange={onChange('code')}
                label="Code"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.basicDetails.estd}
                error={error.errors.estd}
                helperText={error.errors.estd?'Field is required':null}
                onChange={onChange('estd')}
                label="Estd"
              />
            </Box>

            <h4 className='subheader'>Location Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'70%',margin:'10px'}}
                required
                value={data.locationDetails.address}
                error={error.errors.address}
                helperText={error.errors.address?'Field is required':null}
                onChange={onChange('address')}
                label="Address"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.locationDetails.street}
                error={error.errors.street}
                helperText={error.errors.street?'Field is required':null}
                onChange={onChange('street')}
                label="Street"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.locationDetails.city}
                error={error.errors.city}
                helperText={error.errors.city?'Field is required':null}
                onChange={onChange('city')}
                label="City"
              />
              <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                value={city}
                onChange={handleSelect('district')} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={cityOptions.sort()}
                groupBy={(option) => option[0]}
                loading={cities.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    error={error.errors.district}
                    helperText={error.errors.district?'Field is required':null}
                    {...params}
                    label={'District'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {cities.isLoading?<CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                value={state}
                onChange={handleSelect('state')} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    error={error.errors.state}
                    helperText={error.errors.state?'Field is required':null}
                    {...params}
                    label={'State'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {states.isLoading?<CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>


            <h4 className='subheader'>Contact Details</h4>
            <Box className='column'>
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.contactDetails.email}
                onChange={onChange('email')}
                error={error.errors.email}
                helperText={error.errors.email?'Enter Valid Email':null}
                label="Email"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.contactDetails.phone}
                onChange={onChange('phone')}
                error={error.errors.phone}
                helperText={error.errors.phone?'Field is required':null}
                label="Contact"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.contactDetails.siteUrl}
                onChange={onChange('siteUrl')}
                error={error.errors.siteUrl}
                helperText={error.errors.siteUrl?'Field is required':null}
                label="Site URL"
              />
            </Box>


            <h4 className='subheader'>Other Details</h4>
            <Box className='column'>
            {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Discipline'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                onChange={onChange('discipline')}
                value={data.discipline}
                error={error.errors.discipline}
                helperText={error.errors.discipline?'Field is required':null}
                label="Discipline"
              />
              {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Ownersip'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.ownership}
                onChange={onChange('ownership')}
                error={error.errors.ownership}
                helperText={error.errors.ownership?'Field is required':null}
                label="Ownersip"
              />
              {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Category'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.category}
                onChange={onChange('category')}
                error={error.errors.category}
                helperText={error.errors.category?'Field is required':null}
                label="Category"
              />
              {/* <Autocomplete
                style={{width:'50%',margin:'10px'}}
                sx={{ width: 300 }}
                onChange={(event, value) => handleSelect(value)} 
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={stateOptions.sort()}
                groupBy={(option) => option[0]}
                loading={states.isLoading}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={'Type'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              /> */}
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.type}
                onChange={onChange('type')}
                error={error.errors.type}
                helperText={error.errors.type?'Field is required':null}
                label="Type"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.campusArea}
                onChange={onChange('campusArea')}
                error={error.errors.campusArea}
                helperText={error.errors.campusArea?'Field is required':null}
                label="Campus Area"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.facultyStrength}
                onChange={onChange('facultyStrength')}
                error={error.errors.facultyStrength}
                helperText={error.errors.facultyStrength?'Field is required':null}
                label="Faculty Strength"
              />
              <TextField
                style={{width:'50%',margin:'10px'}}
                required
                value={data.studentStrength}
                onChange={onChange('studentStrength')}
                error={error.errors.studentStrength}
                helperText={error.errors.studentStrength?'Field is required':null}
                label="Student Strength"
              />
            </Box>



            <FormControlLabel  style={{marginLeft:'10px'}} control={<Checkbox checked={data.isWomen} onChange={(item,value)=>setData({...data,isWomen:value})}/>} label="Is Women" />

            <h4 className='subheader'>Is Top College</h4>
            <FormControlLabel value={data.topCollegeRanking.india} style={{marginLeft:'10px'}} control={<Checkbox checked={data.topCollegeRanking.india} onChange={handleTopCollegeRanking('india')}/>} label="India" />
            <FormControlLabel value={data.topCollegeRanking.state} style={{marginLeft:'10px'}} control={<Checkbox checked={data.topCollegeRanking.state} onChange={handleTopCollegeRanking('state')}/>} label="State" />
            <FormControlLabel value={data.topCollegeRanking.city} style={{marginLeft:'10px'}} control={<Checkbox checked={data.topCollegeRanking.city} onChange={handleTopCollegeRanking('city')}/>} label="City" />



            

            <h4 className='subheader'>Select Programme</h4>
            <Box sx={{ display: 'flex' }}>
            
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox  checked={data.programTypes.includes('diploma')} onChange={handleProgramme('diploma')} name="diploma" />
                    }
                    label="Diploma"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={data.programTypes.includes('undergraduate')} onChange={handleProgramme('undergraduate')} name="undergraduate" />
                    }
                    label="Undergraduate"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={data.programTypes.includes('postgraduate')} onChange={handleProgramme('postgraduate')} name="postgraduate" />
                    }
                    label="Postgraduate"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={data.programTypes.includes('phd')} onChange={handleProgramme('phd')} name="phd" />
                    }
                    label="Phd"
                  />
                </FormGroup>
                <FormHelperText style={{color:error.errors.programTypes?'red':null}}>{error.errors.programTypes?'Please Select Program Types':'Selected Programme types will be added'}</FormHelperText>
              </FormControl>
            </Box>

            {data.programTypes.map(item=>{
                return <>
                <Box className='multipleInputs' key={item}>
                  <h4 className='subheader'>{capitalize(item)} Programme</h4>
                  <Button variant='contained' onClick={handleProgrammeFormOpen('add',item)}>Add {item}</Button>
                </Box> 
                {!data.programs[item].length
                ?<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                  <Center>
                    <Box>No {capitalize(item)} Programme Added</Box>
                  </Center>
                </Card>
                :<DataGrid
                  autoHeight
                  style={{marginBottom:'10px',marginTop:'10px'}}
                  rows={data.programs[item]??[]}
                  columns={programmeColumn.concat(actions)}//.concat(actions)}
                  getRowId={(row) => item+'_'+row.name}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                /> }
                <Dialog open={openProgrammeForm} onClose={handleProgrammeFormClose}>
                <DialogTitle>{editMode?'Update':'Add'} Programme</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new programme.
                  </DialogContentText>
                  <TextField
                    error={programmeDataError.name}
                    helperText={programmeDataError.name?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={programmeDataOnChange('name')}
                    label="Programme Name"
                    value={programmeData.name}
                    fullWidth
                  />
                  <TextField
                    error={programmeDataError.short}
                    helperText={programmeDataError.short?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={programmeDataOnChange('short')}
                    label="Short"
                    value={programmeData.short}
                    fullWidth
                  />
                  <TextField
                    error={programmeDataError.programme}
                    helperText={programmeDataError.programme?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={programmeDataOnChange('programme')}
                    label="Programme"
                    value={programmeData.programme}
                    fullWidth
                  />
                  <TextField
                    error={programmeDataError.type}
                    helperText={programmeDataError.type?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={programmeDataOnChange('type')}
                    label="Type"
                    value={programmeData.type}
                    fullWidth
                  />
                  <TextField
                    error={programmeDataError.yearOfStarting}
                    helperText={programmeDataError.yearOfStarting?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={programmeDataOnChange('yearOfStarting')}
                    label="Year Of Starting"
                    value={programmeData.yearOfStarting}
                    fullWidth
                  />
                  <Autocomplete
                    style={{marginBottom:'10px',}}
                    multiple
                    value={programmeData.eligibility}
                    options={[]}
                    defaultValue={[]}
                    onChange={(e,value)=>programmeDataOnChannge('eligibility',value)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={programmeDataError.eligibility}
                        helperText={programmeDataError.eligibility?'Field is required':null}
                        {...params}
                        required
                        label="Eligibility[ ]"
                      />
                    )}
                  />
                  <Autocomplete
                    style={{marginBottom:'10px',}}
                    multiple
                    id="tags-filled"
                    value={programmeData.qualification}
                    options={[]}
                    defaultValue={[]}
                    onChange={(e,value)=>programmeDataOnChannge('qualification',value)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={programmeDataError.qualification}
                        helperText={programmeDataError.qualification?'Field is required':null}
                        {...params}
                        required
                        label="Qualification[ ]"
                      />
                    )}
                  />
                  <Autocomplete
                    style={{marginBottom:'10px',}}
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={[]}
                    value={programmeData.fee}
                    onChange={(e,value)=>programmeDataOnChannge('fee',value)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={programmeDataError.fee}
                        helperText={programmeDataError.fee?'Field is required':null}
                        {...params}
                        required
                        label="Fee[ ]"
                      />
                    )}
                  />
                  <Autocomplete
                    style={{marginBottom:'10px',}}
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={[]}
                    value={programmeData.admissionMode}
                    onChange={(e,value)=>programmeDataOnChannge('admissionMode',value)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        error={programmeDataError.admissionMode}
                        helperText={programmeDataError.admissionMode?'Field is required':null}
                        {...params}
                        required
                        label="Addmission Mode[ ]"
                      />
                    )}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleProgrammeFormClose}>Cancel</Button>
                  <Button onClick={handleAddProgramme}>{editMode?'Update':'Add'}</Button>
                </DialogActions>
              </Dialog>
                </>
              })}

              <Box className='multipleInputs' >
                <h4 className='subheader'> Accreditation</h4>
                <Button variant='contained' onClick={handleAccredationFormOpen}>Add </Button>
              </Box> 
              {data.accreditation.length
              ?<ScrollableListView child=
                {data.accreditation.map((item)=>{
                    return <Accreditation key={item.name} data={item} action={(d)=>handleDeleteAccreditation(d)}/>
                })}
              />
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Accreditation Added</Box>
                </Center>
                </Card>}

                <Dialog open={openAccreditationForm} onClose={handleAccredationFormClose}>
                <DialogTitle>Add Accreditation</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Accreditation.
                  </DialogContentText>
                  <TextField
                    error={accreditationDataError.body}
                    helperText={accreditationDataError.body?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={accreditationDataOnChange('body')}
                    label="Body"
                    value={accreditationData.body}
                    fullWidth
                  />
                  <TextField
                    error={accreditationDataError.grade}
                    helperText={accreditationDataError.grade?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={accreditationDataOnChange('grade')}
                    label="Grade"
                    value={accreditationData.grade}
                    fullWidth
                  />
                  <TextField
                    error={accreditationDataError.validUpto}
                    helperText={accreditationDataError.validUpto?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    type='date'
                    onChange={accreditationDataOnChange('validUpto')}
                    value={accreditationData.validUpto}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAccredationFormClose}>Cancel</Button>
                  <Button onClick={handleAddAccreditation}>Add</Button>
                </DialogActions>
              </Dialog>



              <Box className='multipleInputs' >
                <h4 className='subheader'> Ranking</h4>
                <Button variant='contained' onClick={handleRankingFormOpen} >Add </Button>
              </Box> 

              {data.ranking.length
              ?<ScrollableListView child=
                {data.ranking.map((item)=>{
                    return <RatingCard key={item._id} data={item} action={(d)=>handleDeleteRanking(d)}/>
                })}
              />
              :<Card style={{backgroundColor:'#ec7e7e',height:50,padding:'10px'}}>
                <Center>
                <Box>No Ranking Added</Box>
                </Center>
                </Card>}

                <Dialog open={openRankingForm} onClose={handleRankingFormClose}>
                <DialogTitle>Add Ranking</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    All the fields are required for adding new Ranking.
                  </DialogContentText>
                  <TextField
                    error={rankingDataError.body}
                    helperText={rankingDataError.body?'Please Enter Valid 1 to 5 Rank':'Enter Rank 0 to 5'}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={rankingDataOnChange('body')}
                    label="Body"
                    value={rankingData.body}
                    fullWidth
                  />
                  <TextField
                    error={rankingDataError.rank}
                    helperText={rankingDataError.rank?'Field is required':null}
                    style={{marginBottom:'10px',}}
                    required
                    onChange={rankingDataOnChange('rank')}
                    label="Rank"
                    type='text'
                    value={rankingData.rank}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRankingFormClose}>Cancel</Button>
                  <Button onClick={handleAddRanking}>Add</Button>
                </DialogActions>
              </Dialog>

              
            
          </Card>
          <LoadingButton
                className='submitButton'
                size="small"
                loading={props.props==='edit'?updatedCollege.isLoading:college.isLoading}
                loadingIndicator={<CircularProgress size={'2ch'} />}
                variant="contained"
                onClick={OnSubmit}
              >
                {props.props==='edit'?'Update':'Create'}
              </LoadingButton>
          </Box>
        </div>
      </Container>
  )
}

export default CollegeAdd